'use client'

import React, { useEffect, useState, useRef } from 'react';
import { Datepicker } from "flowbite-react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { FunnelIcon, MinusIcon, PlusIcon, ShareIcon } from '@heroicons/react/20/solid'
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import PropTypes from 'prop-types'


import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale for DD/MM/YYYY format
import { requestSemanticGroups, requestSessionMetadata } from "services/chatService";

import { requestFeatureNames, requestPlot } from 'services/plotService';
import MultiSelectDropdownTailwind from 'components/tailwind/MultiSelectDropdownTailwind';
import PlotView from './PlotView';
import { mockFilters, mockFiltersPlot } from 'mock/moceker';
import { DEMO_MODE } from 'services/CONSTANTS';
dayjs.locale('en-gb'); // Set Day.js locale to 'en-gb'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PlotWrapper = props => {
    const [sessionsMetadata, setSessionsMetadata] = React.useState({})
    const [filters, setFilters] = React.useState([])

    const [mobileFiltersOpen, setMobileFiltersOpen] = React.useState(false)

    const today = new Date();
    const todayAnother = new Date();
    const sevenDaysAgo = new Date(todayAnother.setDate(todayAnother.getDate() - 7));
    const [fromValue, setFromValue] = React.useState(sevenDaysAgo);
    const [toValue, setToValue] = React.useState(today);

    const [semanticGroups, setSemanticGroups] = React.useState([]);
    const [selectedDemanticGroups, setSelectedSemanticGroups] = React.useState([]);

    const [features, setFeatures] = React.useState([]);
    const [selectedFeatures, setSelectedFeatures] = React.useState([]);

    const [plotData, setPlotData] = React.useState(null);
    const [xDataSet, setXDataSet] = React.useState([]);

    const [showDemoMessage, setShowDemoMessage] = React.useState(DEMO_MODE() === 'true')



    useEffect(() => {
        fetchMetadata(props.errorMessage, props.successMessage, props.showError)
        fetchSemanticGroups(props.errorMessage, props.successMessage, props.showError)
        fetchFeatures(props.errorMessage, props.successMessage, props.showError)
    }, []); // Empty dependency array ensures this runs only on mount and cleanup on unmount


    const fetchMetadata = (show, hide, showError) => {
        show()
        requestSessionMetadata({})
            .then((data) => {
                let newFilters = []
                let chat = { id: 'plot-primary', name: 'Filters', options: [] }

                Object.entries(data).forEach(([key, value]) => {
                    let option = { id: key, name: key, selection: [] }
                    value.forEach(item => {
                        option.selection.push({ value: item, label: item, checked: false })
                    });
                    chat.options.push(option)
                });

                newFilters.push(chat)
                setFilters(DEMO_MODE() === 'true' ? mockFiltersPlot : newFilters)
                setSessionsMetadata(data)
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }




    const fetchPlotData = (show, hide, showError, whereToLoad) => {
        const request = {
            start_date: convertToDateTime(fromValue),
            end_date: convertToDateTime(toValue),
            x_axis: selectedDemanticGroups,
            y_axis: selectedFeatures
        }
        show()
        requestPlot(request)
            .then((data) => {
                setPlotData(data)
                console.log(data)

                if (data && data.features_to_plot.length > 0) {
                    data.features_to_plot.forEach((element) => {
                        let xArray = []
                        element.feature_values.x.forEach((xelement, index) => {
                            xArray.push({ title: xelement })
                        });
                        setXDataSet((prevDataSet) => [...prevDataSet, xArray])
                    });
                }
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }

    const fetchSemanticGroups = (show, hide, showError) => {
        const request = {
            start_date: convertToDateTime(fromValue),
            end_date: convertToDateTime(toValue),
        }
        show()
        requestSemanticGroups(request)
            .then((data) => {
                setSemanticGroups(data)
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }

    const fetchFeatures = (show, hide, showError) => {
        const request = {
            start_date: convertToDateTime(fromValue),
            end_date: convertToDateTime(toValue),
        }
        show()
        requestFeatureNames(request)
            .then((data) => {
                setFeatures(data)
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }


    function convertToDateTime(dateValue) {
        if (!dateValue) return null;

        // Assuming the new dateValue is a Date object or has a `date` property
        const date = dateValue.date || dateValue;

        if (typeof date.format === 'function') {
            // If date is a Day.js or similar object
            return date.format('YYYY-MM-DD') + ' 00:00:00';
        } else if (date instanceof Date) {
            // If it's a JavaScript Date object
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} 00:00:00`;
        }

        // If date is a string, split and format
        const [day, month, year] = date.split('/');
        const parsedDate = new Date(year, month - 1, day);
        return `${parsedDate.getFullYear()}-${String(parsedDate.getMonth() + 1).padStart(2, '0')}-${String(parsedDate.getDate()).padStart(2, '0')} 00:00:00`;
    }


    const handleSemanticGroupChange = (event) => {

        const {
            target: { value },
        } = event;

        // Handle "Select All" logic separately
        if (value.includes("select-all")) {
            if (selectedDemanticGroups.length === semanticGroups.length) {
                setSelectedSemanticGroups([]); // Deselect all
            } else {
                setSelectedSemanticGroups([...semanticGroups]); // Select all
            }
        } else {
            setSelectedSemanticGroups(
                typeof value === "string" ? value.split(",") : value
            );
        }
    };

    const handleSemanticGroupChangeTailwind = (newSelected) => {
        setSelectedSemanticGroups(newSelected); // Simply update state with the flat array
    };

    const handleSelectedFeaturesChangeTailwind = (newSelected) => {
        setSelectedFeatures(newSelected); // Simply update state with the flat array
    };

    const handleSelectedFeaturesChange = (event) => {
        const {
            target: { value },
        } = event;

        // Handle "Select All" logic separately
        if (value.includes("select-all")) {
            if (selectedFeatures.length === features.length) {
                setSelectedFeatures([]); // Deselect all
            } else {
                setSelectedFeatures([...features]); // Select all
            }
        } else {
            setSelectedFeatures(
                typeof value === "string" ? value.split(",") : value
            );
        }
    };


    const handleRunClickTwo = () => {
        setPlotData(null)
        setXDataSet([])
        fetchPlotData(props.errorMessage, props.successMessage, props.showError, 2)
    }



    const buttonMap = new Map([
        ['plot-primary', { enableHandle: false, onClick: handleRunClickTwo, name: 'Plot' }],
    ]);

    const calendarMap = new Map([
        ['plot-primary', { fromValue: fromValue, toValue: toValue, fromValueSetter: setFromValue, toValueSetter: setToValue }],
    ]);

    const semanticGroupMap = new Map([
        ['plot-primary', { list: semanticGroups, selectedItem: selectedDemanticGroups, onChange: handleSemanticGroupChangeTailwind }],
    ]);

    const featureMap = new Map([
        ['plot-primary', { list: features, selectedItem: selectedFeatures, onChange: handleSelectedFeaturesChangeTailwind }],
    ]);


    return (
        <div className="bg-white">
            {showDemoMessage && (
                <div className="rounded-md bg-blue-50 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                                Demo mode is active. Demo data is collected mostly between October 10th, 2024 and November 1st, 2024.
                            </p>
                            <button
                                type="button"
                                className="ml-3 flex-shrink-0"
                                onClick={() => setShowDemoMessage(false)}
                            >
                                <XMarkIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div>
                {/* Mobile filter dialog */}
                <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-40 lg:hidden">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-black/25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 z-40 flex">
                        <DialogPanel
                            transition
                            className="relative ml-auto flex size-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
                        >
                            <div className="flex items-center justify-between px-4">
                                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                <button
                                    type="button"
                                    onClick={() => setMobileFiltersOpen(false)}
                                    className="-mr-2 flex size-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon aria-hidden="true" className="size-6" />
                                </button>
                            </div>

                            {/* Filters */}
                            <form className="mt-4 border-t border-gray-200">
                                {filters.map((section) => (
                                    <Disclosure key={section.id} as="div" className="border-t border-gray-200 px-4 py-6">
                                        <h3 className="-mx-2 -my-3 flow-root">
                                            <DisclosureButton className="group flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                                <span className="font-medium text-gray-900">{section.name}</span>
                                                <span className="ml-6 flex items-center">
                                                    <PlusIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                                    <MinusIcon aria-hidden="true" className="size-5 [.group:not([data-open])_&]:hidden" />
                                                </span>
                                            </DisclosureButton>
                                        </h3>
                                        <DisclosurePanel className="pt-6">
                                            <div className="space-y-6">
                                                <label htmlFor="from-date" className="block text-sm font-medium text-gray-700 text-right">
                                                    From Date
                                                </label>
                                                <Datepicker
                                                    id="from-date"
                                                    className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                    showClearButton={false}
                                                    value={calendarMap.get(section.id).fromValue}
                                                    onChange={(newValue) => calendarMap.get(section.id).fromValueSetter(newValue)}
                                                />
                                                <label htmlFor="to-date" className="block text-sm font-medium text-gray-700 text-right">
                                                    To Date
                                                </label>
                                                <Datepicker
                                                    id="to-date"
                                                    className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                    showClearButton={false}
                                                    value={calendarMap.get(section.id).toValue}
                                                    onChange={(newValue) => calendarMap.get(section.id).toValueSetter(newValue)}
                                                />

                                                <MultiSelectDropdownTailwind
                                                    title="Semantic Groups"
                                                    list={semanticGroupMap.get(section.id).list}
                                                    selectedItems={semanticGroupMap.get(section.id).selectedItem}
                                                    hint="Select semantic groups"
                                                    onChange={semanticGroupMap.get(section.id).onChange}
                                                />

                                                <MultiSelectDropdownTailwind
                                                    title="Features"
                                                    list={featureMap.get(section.id).list}
                                                    selectedItems={featureMap.get(section.id).selectedItem}
                                                    hint="Select features"
                                                    onChange={featureMap.get(section.id).onChange}
                                                />

                                                {section.options.map((option, optionIdx) => (

                                                    <div>

                                                        <div key={option.id} className="flex items-center text-gray-500">
                                                            {option.name}
                                                        </div>
                                                        {option.selection.map((selectionOption, soIdx) => (
                                                            <div key={selectionOption.value} className="flex items-center">
                                                                <input
                                                                    defaultValue={selectionOption.value}
                                                                    defaultChecked={selectionOption.checked}
                                                                    id={`filter-mobile-${option.id}-${soIdx}`}
                                                                    name={`${option.id}[]`}
                                                                    type="radio"
                                                                    className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                />
                                                                <label
                                                                    htmlFor={`filter-mobile-${option.id}-${soIdx}`}
                                                                    className="ml-3 min-w-0 flex-1 text-gray-500"
                                                                >
                                                                    {selectionOption.label}
                                                                </label>
                                                                <div class="border-t border-gray-300 my-4"></div>
                                                            </div>

                                                        ))
                                                        }

                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    className={`flex items-center justify-center px-5 py-2.5 text-sm font-medium rounded-lg ${buttonMap.get(section.id).enableHandle
                                                        ? 'text-gray-400 bg-gray-300 cursor-not-allowed'
                                                        : 'text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-700'
                                                        }`}
                                                    disabled={buttonMap.get(section.id).enableHandle}
                                                    onClick={buttonMap.get(section.id).enableHandle ? undefined : buttonMap.get(section.id).onClick}
                                                >
                                                    {buttonMap.get(section.id).name}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-5 h-5 ml-2"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </DisclosurePanel>
                                    </Disclosure>
                                ))}
                            </form>
                        </DialogPanel>
                    </div>
                </Dialog>

                <main className="mx-auto w-full px-4 sm:px-6 lg:px-8">
                    <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-6">
                        <h3 className="text-xl font-bold tracking-tight text-gray-900">Plot</h3>

                        <div className="flex items-center">

                            <button
                                type="button"
                                onClick={() => setMobileFiltersOpen(true)}
                                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                            >
                                <span className="sr-only">Filters</span>
                                <FunnelIcon aria-hidden="true" className="size-5" />
                            </button>
                        </div>
                    </div>

                    <section aria-labelledby="products-heading" className="pb-24 pt-6">
                        <h2 id="products-heading" className="sr-only">
                            Products
                        </h2>

                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                            {/* Filters */}
                            <form className="hidden lg:block">
                                {filters.map((section, index) => (
                                    <Disclosure
                                        key={section.id}
                                        as="div"
                                        className="border-b border-gray-200 py-6"
                                        {...(index === 0 && { defaultOpen: true })}
                                    >
                                        <h3 className="-my-3 flow-root">
                                            <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                                <span className="font-medium text-gray-900">{section.name}</span>
                                                <span className="ml-6 flex items-center">
                                                    <PlusIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                                    <MinusIcon aria-hidden="true" className="size-5 [.group:not([data-open])_&]:hidden" />
                                                </span>
                                            </DisclosureButton>
                                        </h3>
                                        <DisclosurePanel className="pt-6 ml-6">
                                            <div className="space-y-4">
                                                <label htmlFor="from-date" className="block text-sm font-medium text-gray-700 text-right">
                                                    From Date
                                                </label>
                                                <Datepicker
                                                    id="from-date"
                                                    className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                    showClearButton={false}
                                                    value={calendarMap.get(section.id).fromValue}
                                                    onChange={(newValue) => calendarMap.get(section.id).fromValueSetter(newValue)}
                                                />
                                                <label htmlFor="to-date" className="block text-sm font-medium text-gray-700 text-right">
                                                    To Date
                                                </label>
                                                <Datepicker
                                                    id="to-date"
                                                    className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                    showClearButton={false}
                                                    value={calendarMap.get(section.id).toValue}
                                                    onChange={(newValue) => calendarMap.get(section.id).toValueSetter(newValue)}
                                                />

                                                <MultiSelectDropdownTailwind
                                                    title="Semantic Groups"
                                                    list={semanticGroupMap.get(section.id).list}
                                                    selectedItems={semanticGroupMap.get(section.id).selectedItem}
                                                    hint="Select semantic groups"
                                                    onChange={semanticGroupMap.get(section.id).onChange}
                                                />

                                                <MultiSelectDropdownTailwind
                                                    title="Features"
                                                    list={featureMap.get(section.id).list}
                                                    selectedItems={featureMap.get(section.id).selectedItem}
                                                    hint="Select features"
                                                    onChange={featureMap.get(section.id).onChange}
                                                />

                                                {section.options.map((option, optionIdx) => (
                                                    <div>

                                                        <div key={option.id} className="flex items-center text-gray-500">
                                                            {option.name}
                                                        </div>
                                                        {option.selection.map((selectionOption, soIdx) => (
                                                            <div key={selectionOption.value} className="flex items-center">
                                                                <input
                                                                    defaultValue={selectionOption.value}
                                                                    defaultChecked={selectionOption.checked}
                                                                    id={`filter-mobile-${option.id}-${soIdx}`}
                                                                    name={`${option.id}[]`}
                                                                    type="radio"
                                                                    className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                />
                                                                <label
                                                                    htmlFor={`filter-mobile-${option.id}-${soIdx}`}
                                                                    className="ml-3 min-w-0 flex-1 text-gray-500"
                                                                >
                                                                    {selectionOption.label}
                                                                </label>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    className={`flex items-center justify-center px-5 py-2.5 text-sm font-medium rounded-lg ${buttonMap.get(section.id).enableHandle
                                                        ? 'text-gray-400 bg-gray-300 cursor-not-allowed'
                                                        : 'text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-700'
                                                        }`}
                                                    disabled={buttonMap.get(section.id).enableHandle}
                                                    onClick={buttonMap.get(section.id).enableHandle ? undefined : buttonMap.get(section.id).onClick}
                                                >
                                                    {buttonMap.get(section.id).name}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-5 h-5 ml-2"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </DisclosurePanel>
                                    </Disclosure>
                                ))}
                            </form>

                            {/* Product grid */}
                            <div className="lg:col-span-3">
                                <PlotView
                                    title="Plot"
                                    plotData={plotData}
                                    xDataSet={xDataSet}
                                    errorMessage={props.errorMessage} successMessage={props.successMessage} showError={props.showError} />
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

PlotWrapper.propTypes = {
    title: PropTypes.string.isRequired
}

export default PlotWrapper
