"use client";
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types'
import Container from '@mui/material/Container';
import dayjs from 'dayjs';
import { Autocomplete, TextField } from '@mui/material';
import { formatFeatureContent, formatFeatureTitles } from 'utils/stringMappings';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { DEMO_MODE } from 'services/CONSTANTS';

dayjs.locale('en-gb');

const ModelView = props => {
    const [value, setValue] = React.useState([]);
    const [example, setExample] = React.useState({
        'userId': 'guuid',
        'modelId': 'guuid', 
        'modelScore': 0.5,
        'modelDesctiption': 'The model is built in order to detect drop of user attention',
        'modelPattern': '',
        'timestamp': '123412355'
    });
    const [threshold, setThreshold] = React.useState(0.5);
    const [description, setDescription] = React.useState("The model is built in order to detect drop of user attention");

    const [showDemoMessage, setShowDemoMessage] = React.useState(DEMO_MODE() === 'true')

    const handleThresholdChange = (event) => {
        setThreshold(event.target.value);
        setExample((prevExample) => ({
            ...prevExample,
            modelScore: parseFloat(event.target.value)
        }));
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        setExample((prevExample) => ({
            ...prevExample,
            modelDesctiption: event.target.value
        }));
    };

    return (
        <div className="min-h-screen bg-gray-50">
            {showDemoMessage && (
                <div className="rounded-md bg-blue-50 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                                Demo mode is active. With demo data, this is only UI preview of the model builder.
                            </p>
                            <button
                                type="button"
                                className="ml-3 flex-shrink-0"
                                onClick={() => setShowDemoMessage(false)}
                            >
                                <XMarkIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Container maxWidth="false" className="py-8">
                <div className="bg-white rounded-lg shadow p-6">
                    <div className="space-y-6">
                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Data necessary for training the model:</h3>
                            <div className="mt-2 flex items-center">
                                <div className="w-3/5 bg-gray-200 rounded-full h-2 mr-3">
                                    <div className="bg-indigo-600 h-2 rounded-full" style={{width: '67%'}}></div>
                                </div>
                                <span className="text-sm text-gray-500">67%</span>
                                {67 === 100 && (
                                    <svg className="w-5 h-5 text-green-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                    </svg>
                                )}
                            </div>
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Data necessary for feature selection:</h3>
                            <div className="mt-2 flex items-center">
                                <div className="w-3/5 bg-gray-200 rounded-full h-2 mr-3">
                                    <div className="bg-indigo-600 h-2 rounded-full" style={{width: '100%'}}></div>
                                </div>
                                <span className="text-sm text-gray-500">100%</span>
                                <svg className="w-5 h-5 text-green-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                        </div>

                        <hr className="border-gray-200" />

                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-10">
                                <div className="relative">
                                    <Autocomplete
                                        multiple
                                        value={value}
                                        onChange={(event, newValue) => {
                                            setValue([...newValue]);
                                            setExample((prevExample) => ({
                                                ...prevExample,
                                                modelPattern: newValue.map(obj => formatFeatureContent(obj.title).replace("'", "").replace("'", "")).join(' ')
                                            }));
                                        }}
                                        options={[...availableFeatures, ...availableOperators].map((el) => ({ title: el.title, type: el.type }))}
                                        groupBy={(option) => option.type}
                                        getOptionLabel={(option) => formatFeatureTitles(option.title)}
                                        getOptionDisabled={(option) =>
                                            (option.type === "operator" && value.length > 0 && value[value.length - 1].type === "operator") ||
                                            (option.type === "feature" && value.length > 0 && value[value.length - 1].type === "feature") ||
                                            (option.type === "operator" && value.length === 0)
                                        }
                                        renderInput={(params) => (
                                            <TextField 
                                                {...params}
                                                className="w-full"
                                                placeholder="Feature + operator"
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <input
                                    type="number"
                                    value={threshold}
                                    onChange={handleThresholdChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Threshold"
                                />
                            </div>

                            <div className="col-span-10">
                                <input
                                    type="text"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Description"
                                />
                            </div>

                            <div className="col-span-12">
                                <div className="bg-gray-50 rounded-lg p-4">
                                    <h4 className="text-sm font-medium text-gray-900 mb-2">Example of the model delivered to a web-hook:</h4>
                                    <pre className="text-xs text-gray-600">{JSON.stringify(example, null, 2)}</pre>
                                </div>
                            </div>

                            <div className="col-span-12">
                                <input
                                    type="text"
                                    value="https://moveo-one-api-web-app.azurewebsites.net/api/analytic/event-sync"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Paste the web-hook endpoint"
                                    readOnly
                                />
                            </div>

                            <div className="col-span-3">
                                <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <span>Build</span>
                                    <svg className="ml-2 -mr-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        {/* Model Cards */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
                            {/* Active Model Card */}
                            <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                                <div className="p-6">
                                    <div className="flex justify-between items-center mb-4">
                                        <span className="text-sm text-gray-500">uuid</span>
                                        <svg className="w-5 h-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                    <h3 className="text-xl font-semibold text-gray-900 mb-2">User engage</h3>
                                    <p className="text-sm text-gray-500 mb-4">Sto and TNtCS and StO or CAS</p>
                                    <p className="text-sm text-gray-500 mb-4">The model is built in order to detect drop of user attention</p>
                                    <p className="text-sm text-gray-600">
                                        hook:<br />
                                        ...ites.net/api/analytic/event-sync-model
                                    </p>
                                </div>
                                <div className="px-6 py-4 bg-gray-50 rounded-b-lg flex justify-between items-center">
                                    <button className="text-sm text-indigo-600 hover:text-indigo-900">more</button>
                                    <div className="relative inline-block w-10 mr-2 align-middle select-none">
                                        <input type="checkbox" name="toggle" id="toggle" className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" defaultChecked />
                                        <label htmlFor="toggle" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                                    </div>
                                </div>
                            </div>

                            {/* Inactive Model Card */}
                            <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                                <div className="p-6">
                                    <div className="flex justify-between items-center mb-4">
                                        <span className="text-sm text-gray-500">uuid</span>
                                        <svg className="w-5 h-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                        </svg>
                                    </div>
                                    <h3 className="text-xl font-semibold text-gray-900 mb-2">User drop</h3>
                                    <p className="text-sm text-gray-500 mb-4">Sto and TNtCS and StO or CAS</p>
                                    <p className="text-sm text-gray-500 mb-4">The model is built in order to detect drop of user attention</p>
                                    <p className="text-sm text-gray-600">
                                        hook:<br />
                                        ...ites.net/api/analytic/event-sync
                                    </p>
                                </div>
                                <div className="px-6 py-4 bg-gray-50 rounded-b-lg flex justify-between items-center">
                                    <button className="text-sm text-indigo-600 hover:text-indigo-900">more</button>
                                    <div className="relative inline-block w-10 mr-2 align-middle select-none opacity-50">
                                        <input type="checkbox" name="toggle2" id="toggle2" className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-not-allowed" disabled />
                                        <label htmlFor="toggle2" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-not-allowed"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

ModelView.propTypes = {
    title: PropTypes.string.isRequired,
    errorMessage: PropTypes.func,
    successMessage: PropTypes.func,
}

export default ModelView

const availableOperators = [
    { title: 'and', type: 'operator' },
    { title: 'or', type: 'operator' },
    { title: 'x', type: 'operator' },
]

const availableFeatures = [
    { title: 'Cognitive_attention_score', type: 'feature' },
    { title: 'FirstInteractionTime', type: 'feature' },
    { title: 'InteractionsPerElementSelectList_item', type: 'feature' },
    { title: 'TimePerCharacter', type: 'feature' },
    { title: 'VisitsPerElementScreen', type: 'feature' },
    { title: 'TotalTime', type: 'feature' },
    { title: 'InteractionsPerElementClickButton_next', type: 'feature' },
    { title: 'Uncertanty_per_screen', type: 'feature' },
    { title: 'Changing_the_option', type: 'feature' },
]
