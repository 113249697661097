import React from 'react';
import Spinner from './tailwind/Spinner';

export const Loader = ({ size = 'md', light = false }) => {
  const sizes = {
    sm: 'h-4 w-4',
    md: 'h-8 w-8',
    lg: 'h-12 w-12'
  };

  return (
    <div className="relative">
      <div className={`${sizes[size]} animate-spin`}>
        <div className="absolute h-full w-full rounded-full border-2 border-solid border-t-transparent border-l-transparent border-r-transparent border-b-indigo-600 animate-spin"></div>
        <div className="absolute h-full w-full rounded-full border-2 border-solid border-t-transparent border-l-transparent border-r-transparent border-b-indigo-400 animate-spin" style={{ animationDelay: '-0.2s' }}></div>
      </div>
    </div>
  );
};

export const LoaderOverlay = ({ message }) => (
  <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 animate-fadeIn">
    <div className="flex flex-col items-center justify-center h-full">
      {/* <Loader size="lg" light /> */}
      <Spinner/>
      {message && (
        <p className="mt-4 text-white text-sm animate-pulse">{message}</p>
      )}
    </div>
  </div>
);

export const SkeletonLoader = () => (
  <div className="animate-pulse">
    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    <div className="space-y-3 mt-4">
      <div className="h-4 bg-gray-200 rounded"></div>
      <div className="h-4 bg-gray-200 rounded w-5/6"></div>
    </div>
  </div>
);

export const ButtonLoader = ({ light = false }) => (
  <div className="flex items-center">
    <div className={`animate-spin rounded-full h-4 w-4 border-2 border-b-transparent ${light ? 'border-white/80' : 'border-indigo-600/80'}`} />
  </div>
); 