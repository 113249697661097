import React, { useEffect } from "react";
// Handle console logs
import "utils/dropConsole";
// Styles
import "fontsource-roboto";
// ROUTER
import { BrowserRouter } from "react-router-dom";
// MUI Theme
import { ThemeProvider } from "@mui/material/styles";
import { light } from "styles/muiTheme";


import './App.css';

import { ProvideAuth } from "navigation/Auth/ProvideAuth";

import LayoutContainer from "Layout/LayoutContainer";
import LoginPage from "pages/LoginPage";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { CoachmarkProvider } from './components/Coachmark/CoachmarkProvider';
import { BASE_URL } from "services/CONSTANTS";

function App() {
  useEffect(() => {
    const baseUrl = BASE_URL.toLowerCase();
    let prefix = '';
    
    if (baseUrl.includes('localhost') || baseUrl.includes('127.0.0.1')) {
      prefix = '(local) ';
    } else if (baseUrl.includes('dev-web')) {
      prefix = '(dev) ';
    }
    
    document.title = prefix + 'Moveo - Content Service';
  }, []);

  let isLoggedIn = sessionStorage.getItem('authToken') && sessionStorage.getItem('authToken') !== '' && sessionStorage.getItem('authToken') !== 'null';
  let mainContent;
  if (isLoggedIn) {
    mainContent = <LayoutContainer />
  } else {
    mainContent = <LoginPage />;
  }


  return (
    <div>
      
        <ThemeProvider theme={light()}>
          <ProvideAuth>
            <CoachmarkProvider>
              <BrowserRouter>
                {mainContent}
              </BrowserRouter>
            </CoachmarkProvider>
          </ProvideAuth>
        </ThemeProvider>
        <ToastContainer />
    </div>

  );
}

export default App;
