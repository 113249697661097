import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { Divider, Grid2, Stack } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LineChart } from '@mui/x-charts/LineChart';

import { formatFeatureTitles } from 'utils/stringMappings';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';



import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale for DD/MM/YYYY format
import { requestSemanticGroups } from 'services/chatService';
import { requestFeatureNames, requestPlot } from 'services/plotService';
import MultiSelectDropdownTailwind from 'components/tailwind/MultiSelectDropdownTailwind';
import {
    ArrowRightCircleIcon
} from '@heroicons/react/24/outline'

dayjs.locale('en-gb'); // Set Day.js locale to 'en-gb'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const PlotView = props => {


    const customTheme = createTheme({
        palette: {
            primary: {
                main: '#6366F1', // Tailwind Indigo-500
            },
        },
    });

    return (
        <ThemeProvider theme={customTheme}>
            <div>
                <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                    <Grid2 container spacing={3}>
                        <Grid2 size={{ xs: 12, md: 12, lg: 12 }}>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%'
                                }}
                            >
                                {props.plotData && props.plotData.features_to_plot.length > 0 &&
                                    <Stack sx={{ mt: 2 }} spacing={5}>
                                        {props.plotData.features_to_plot.map((row, index) => (
                                            <>

                                                {index !== 0 &&
                                                    <Divider />
                                                }
                                                <Typography variant="h4"
                                                    sx={{
                                                        textAlign: 'center', // Center the text inside its own box
                                                    }}>
                                                    {formatFeatureTitles(row.feature_name)}
                                                </Typography>

                                                <Box sx={{ flexGrow: 1, mb: 2 }}>

                                                    <LineChart
                                                        xAxis={[
                                                            {
                                                                scaleType: 'point',
                                                                dataKey: 'title'
                                                            },
                                                        ]}
                                                        yAxis={[
                                                            {
                                                                tickLabelStyle: { display: 'none' }, // Hides the labels on the y-axis
                                                                ticks: { show: false },  // Hides the ticks on the y-axis
                                                            },
                                                        ]}
                                                        margin={{ top: 25, right: 20, bottom: 80, left: 20 }}
                                                        dataset={props.xDataSet[index]}
                                                        series={[{
                                                            data: row.feature_values.y,  // Using row.feature_values.y for y-axis values
                                                            color: '#76b7b2',
                                                        }]}
                                                        height={400}
                                                        bottomAxis={{
                                                            labelStyle: {
                                                                fontSize: 14,
                                                                transform: `translateY(${
                                                                    // Hack that should be added in the lib latter.
                                                                    5 * Math.abs(Math.sin((Math.PI * props.angle) / 180))
                                                                    }px)`
                                                            },
                                                            tickLabelStyle: {
                                                                angle: 90,
                                                                textAnchor: 'start',
                                                                fontSize: 12,
                                                            },
                                                        }}

                                                    />
                                                </Box>
                                                {(index !== props.plotData.features_to_plot.length - 1) &&
                                                    <>
                                                        <br />
                                                        <br />
                                                    </>
                                                }
                                            </>
                                        ))}
                                    </Stack>
                                }

                            </Paper>
                        </Grid2>
                    </Grid2>
                </Container>


            </div>
        </ThemeProvider>
    )

}

PlotView.propTypes = {
    title: PropTypes.string.isRequired,
    errorMessage: PropTypes.func,
    successMessage: PropTypes.func,
}

export default PlotView
