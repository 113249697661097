import React from "react";
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactMarkdown from 'react-markdown';


function ChatComponent(props) {
  const { text, fromMe } = props;
// sx={{ whiteSpace: 'pre-line', ml: 4 }}
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: fromMe ? 'flex-end' : 'flex-start', // Align right if fromMe, otherwise align left
        marginBottom: '10px', // Space between messages
      }}
    >
      {!fromMe ? (
        <Typography
          sx={{
            whiteSpace: 'pre-line',
            color: '#4d4d4d', // Dark grey color for the text
          }}
        >{text}</Typography> // No bubble for non-fromMe messages
      ) : (
        <Typography
          sx={{
            whiteSpace: 'pre-line',
            backgroundColor: '#3d84f7', // Bubble background for fromMe messages
            padding: '10px',           // Padding around the text
            borderRadius: '15px',      // Rounded corners for the bubble
            maxWidth: '80%',           // Ensure it doesn't take the full width
            display: 'inline-block',   // Ensure it behaves like a chat bubble
            color: '#FFF', // Dark grey color for the text
          }}
        >
          <ReactMarkdown>{text}</ReactMarkdown>
        </Typography>
      )}
    </Box>
  );
}

ChatComponent.propTypes = {
  text: PropTypes.string.isRequired,
  fromMe: PropTypes.bool.isRequired
};

export default ChatComponent;
