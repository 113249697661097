import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { 
    CloudArrowUpIcon, 
    BoltIcon, 
    ChartBarIcon,
    ChatBubbleBottomCenterTextIcon 
} from '@heroicons/react/24/solid';

// Integration logos
import segmentLogo from '../../../assets/integration-logos/segment.svg';
import mixpanelLogo from '../../../assets/integration-logos/mixpanel.svg';
import slackLogo from '../../../assets/integration-logos/slack.svg';
import teamsLogo from '../../../assets/integration-logos/teams.svg';
import mongodbLogo from '../../../assets/integration-logos/mongodb.svg';
import postgresLogo from '../../../assets/integration-logos/postgres.svg';
import DialogComponent from 'components/tailwind/DialogComponent';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { DEMO_MODE } from 'services/CONSTANTS';

const IntegrationsView = props => {
    const history = useHistory();
    const [showDemoMessage, setShowDemoMessage] = React.useState(DEMO_MODE() === 'true')
    const [dialogOpen, setDialogOpen] = useState(false);
    const [integrations, setIntegrations] = useState({
        analytics: {
            segment: {
                connected: false,
                apiKey: "seg_123456",
                lastSync: "2024-03-15T10:30:00Z"
            },
            mixpanel: {
                connected: false,
                apiKey: "",
                lastSync: null
            }
        },
        communication: {
            slack: {
                connected: false,
                workspace: "moveo-team",
                channels: ["#alerts", "#general"]
            },
            teams: {
                connected: false,
                tenant: "",
                channels: []
            }
        },
        databases: {
            mongodb: {
                connected: false,
                uri: "mongodb://...",
                databases: ["production", "staging"]
            },
            postgres: {
                connected: false,
                host: "",
                databases: []
            }
        }
    });

    const handleCancel = () => {
        const previousPath = sessionStorage.getItem('lastDashboardPath') || '/home';
        history.push(previousPath);
    };

    const handleSave = async () => {
        try {
            // API call placeholder
            // await updateIntegrations(integrations);
            props.successMessage?.('Integrations updated successfully');
        } catch (error) {
            props.errorMessage?.(error.message);
        }
    };

    const handleConnect = () => {
        setDialogOpen(true);
        // try {
        //     // Simulating API connection
        //     setIntegrations(prev => {
        //         const isCurrentlyConnected = prev[category][integration].connected;
        //         props.successMessage?.(`${integration} ${isCurrentlyConnected ? 'disconnected' : 'connected'} successfully`);
                
        //         return {
        //             ...prev,
        //             [category]: {
        //                 ...prev[category],
        //                 [integration]: {
        //                     ...prev[category][integration],
        //                     connected: !isCurrentlyConnected
        //                 }
        //             }
        //         };
        //     });
        // } catch (error) {
        //     props.errorMessage?.(error.message);
        // }
    };

    const IntegrationCard = ({ name, logo, description, connected, onConnect }) => (
        <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-gray-400">
            <div className="flex-shrink-0">
                <img className="h-10 w-10" src={logo} alt={name} />
            </div>
            <div className="min-w-0 flex-1">
                <div className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">{name}</p>
                    <p className="truncate text-sm text-gray-500">{description}</p>
                </div>
            </div>
            <button
                onClick={onConnect}
                className={`${
                    connected 
                        ? 'bg-red-50 text-red-700 hover:bg-red-100' 
                        : 'bg-green-50 text-green-700 hover:bg-green-100'
                } rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm`}
            >
                {connected ? 'Disconnect' : 'Connect'}
            </button>
        </div>
    );

    return (
        <div>
            {showDemoMessage && (
                <div className="rounded-md bg-blue-50 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                                Demo mode is active. In demo mode, this is only UI preview of the integrations.
                            </p>
                            <button
                                type="button"
                                className="ml-3 flex-shrink-0"
                                onClick={() => setShowDemoMessage(false)}
                            >
                                <XMarkIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button 
                    type="button" 
                    className="text-sm/6 font-semibold text-gray-900" 
                    onClick={handleCancel}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={handleSave}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>

            <div className="space-y-12 max-w-5xl mx-auto">
                {/* Analytics Integrations */}
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="flex items-center gap-x-3">
                        <ChartBarIcon className="h-6 w-6 text-gray-600" />
                        <h2 className="text-base/7 font-semibold text-gray-900">Analytics Integrations</h2>
                    </div>
                    <p className="mt-1 text-sm/6 text-gray-600">
                        Connect with analytics platforms to track user behavior and system performance.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <IntegrationCard
                            name="Segment"
                            logo={segmentLogo}
                            description="Customer Data Platform for collecting and routing analytics data"
                            connected={integrations.analytics.segment.connected}
                            onConnect={() => handleConnect('analytics', 'segment')}
                        />
                        <IntegrationCard
                            name="Mixpanel"
                            logo={mixpanelLogo}
                            description="Product analytics for understanding user behavior"
                            connected={integrations.analytics.mixpanel.connected}
                            onConnect={() => handleConnect('analytics', 'mixpanel')}
                        />
                    </div>
                </div>

                {/* Communication Integrations */}
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="flex items-center gap-x-3">
                        <ChatBubbleBottomCenterTextIcon className="h-6 w-6 text-gray-600" />
                        <h2 className="text-base/7 font-semibold text-gray-900">Communication Integrations</h2>
                    </div>
                    <p className="mt-1 text-sm/6 text-gray-600">
                        Connect with team communication platforms for notifications and alerts.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <IntegrationCard
                            name="Slack"
                            logo={slackLogo}
                            description="Team communication and notifications platform"
                            connected={integrations.communication.slack.connected}
                            onConnect={() => handleConnect('communication', 'slack')}
                        />
                        <IntegrationCard
                            name="Microsoft Teams"
                            logo={teamsLogo}
                            description="Enterprise communication and collaboration platform"
                            connected={integrations.communication.teams.connected}
                            onConnect={handleConnect}
                        />
                    </div>
                </div>

                {/* Database Integrations */}
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="flex items-center gap-x-3">
                        <CloudArrowUpIcon className="h-6 w-6 text-gray-600" />
                        <h2 className="text-base/7 font-semibold text-gray-900">Database Integrations</h2>
                    </div>
                    <p className="mt-1 text-sm/6 text-gray-600">
                        Connect with external databases for data import and export.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <IntegrationCard
                            name="MongoDB"
                            logo={mongodbLogo}
                            description="NoSQL database for flexible data storage"
                            connected={integrations.databases.mongodb.connected}
                            onConnect={() => handleConnect('databases', 'mongodb')}
                        />
                        <IntegrationCard
                            name="PostgreSQL"
                            logo={postgresLogo}
                            description="Powerful, open source object-relational database"
                            connected={integrations.databases.postgres.connected}
                            onConnect={() => handleConnect('databases', 'postgres')}
                        />
                    </div>
                </div>
            </div>
            <DialogComponent
                title='Integration while on demo plan'
                subtitle='Integrations are not supported on the demo plan.'
                buttonText='Ok'
                action={null}
                open={dialogOpen}
                setOpen={setDialogOpen}
            />
        </div>
        
    );
};

IntegrationsView.propTypes = {
    errorMessage: PropTypes.func,
    successMessage: PropTypes.func,
};

export default IntegrationsView; 