'use client'
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function MultiSelectDropdownTailwind({ title, list, selectedItems, hint, onChange }) {
    const [selected, setSelected] = useState(selectedItems);

    const handleSelect = (value) => {
        let newSelected;

        // Handle "Select All" option
        if (value.includes("select-all")) {
            if (selected.length === list.length) {
                newSelected = []; // Deselect all
            } else {
                newSelected = [...list]; // Select all
            }
        } else {
            // Handle individual item selection/deselection
            newSelected = value;
        }

        setSelected(newSelected); // Update local state
        onChange(newSelected); // Pass updated selection to parent
    };

    return (
        <div>
            {title && (
                <label className="block text-sm font-medium text-gray-900">{title}</label>
            )}
            <Listbox value={selected} onChange={handleSelect} multiple>
                <div className="relative mt-2">
                    <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm">
                        <span className="flex items-center">
                            <span className={`ml-3 block truncate ${selected.length === 0 ? 'text-gray-400' : ''}`}>
                                {selected.length === 0 ? hint : selected.join(', ')}
                            </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </span>
                    </ListboxButton>

                    <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {/* Select All Option */}
                        <ListboxOption value="select-all" className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={selected.length === list.length}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    readOnly
                                />
                                <span className="ml-3 block truncate">Select All</span>
                            </div>
                        </ListboxOption>

                        {/* Individual Items */}
                        {list.map((item) => (
                            <ListboxOption
                                key={item}
                                value={item}
                                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white"
                            >
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={selected.includes(item)}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        readOnly
                                    />
                                    <span className="ml-3 block truncate">{item}</span>
                                </div>
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </div>
            </Listbox>
        </div>
    );
}

MultiSelectDropdownTailwind.propTypes = {
    title: PropTypes.string,
    list: PropTypes.array.isRequired,
    selectedItems: PropTypes.array.isRequired,
    hint: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default MultiSelectDropdownTailwind;