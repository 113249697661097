"use client";
import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid2 } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { formatDistanceToNow } from 'date-fns';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useCoachmark, COACHMARKS } from '../../components/Coachmark/CoachmarkProvider';
import { Coachmark } from '../../components/Coachmark/Coachmark';
import { OverlayList } from '../../components/tailwind/OverlayList';
import { useHistory } from "react-router-dom";
import { PAGE_AUTO_DETECT, PAGE_CHAT, PAGE_DASHBOARD_HOME, PAGE_SETTINGS, PAGE_PROFILE, PAGE_INTEGRATIONS, PAGE_PLOT, PAGE_MODEL } from "navigation/CONSTANTS";
import DialogComponent from '../../components/tailwind/DialogComponent';
import { requestAutoDetectDataStatistic, requestAutoDetectLog, requestChatLog, requestEventCount, requestMetadataStatistic, requestSessionAvgTime, requestSessionCount } from "services/homeStatisticService";
import { DEMO_MODE } from "services/CONSTANTS";



dayjs.locale('en-gb');

const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const HomeView = props => {
    const [showOverview, setShowOverview] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const colors = ['#FF5733', '#33FF57', '#3357FF', '#F5A623', '#9B59B6'];
    const defaultColor = '#EE4B2B';
    const [searchTerm, setSearchTerm] = useState('');
    const searchInputRef = useRef(null);
    const { showCoachmark, startSequence } = useCoachmark();
    const history = useHistory();
    const [askLogout, setAskLogout] = useState(false);

    // Define functions first
    const openSlackSupport = () => {
        setSearchTerm('');  // Clear search
        const slackChannelUrl = 'slack://channel?team=T07TM8GS9PC&id=C07TM8GS9PC';
        const slackWebUrl = 'https://slack.com/app_redirect?channel=C07TM8GS9PC';

        window.location.href = slackChannelUrl;
        setTimeout(() => {
            window.location.href = slackWebUrl;
        }, 1000);
    };

    const handleLogout = () => {
        setSearchTerm('');  // Clear search
        setAskLogout(true); // Show logout dialog
    };

    const handleSearchItemClick = (item) => {
        setSearchTerm('');
        switch (item.text.toLowerCase()) {
            case 'home':
                history.push(PAGE_DASHBOARD_HOME);
                break;
            case 'auto detect':
                history.push(PAGE_AUTO_DETECT);
                break;
            case 'chat':
                history.push(PAGE_CHAT);
                break;
            case 'plot':
                history.push(PAGE_PLOT);
                break;
            case 'model':
                history.push(PAGE_MODEL);
                break;
            case 'settings':
                history.push(PAGE_SETTINGS);
                break;
            case 'profile':
                history.push(PAGE_PROFILE);
                break;
            case 'integrations':
                history.push(PAGE_INTEGRATIONS);
                break;
            case 'support':
                openSlackSupport();
                break;
            case 'logout':
                handleLogout();
                break;
            default:
                break;
        }
    };

    // Then define states that use these functions
    const [searchableContent] = useState([
        { id: 1, text: "Home", path: PAGE_DASHBOARD_HOME },
        { id: 2, text: "Auto Detect", path: PAGE_AUTO_DETECT },
        { id: 3, text: "Chat", path: PAGE_CHAT },
        { id: 4, text: "Plot", path: PAGE_PLOT },
        { id: 5, text: "Model", path: PAGE_MODEL },
        { id: 6, text: "Settings", path: PAGE_SETTINGS },
        { id: 7, text: "Profile", path: PAGE_PROFILE },
        { id: 8, text: "Support", action: openSlackSupport },
        { id: 9, text: "Logout", action: handleLogout },
        { id: 10, text: "Integrations", path: PAGE_INTEGRATIONS }
    ]);

    const filteredContent = searchableContent.filter(item =>
        item.text.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Mock data - replace with API calls later
    const [statistics, setStatistics] = useState([]);


    const [currentPieIndex, setCurrentPieIndex] = useState(0);
    const [currentPieIndexSecond, setCurrentPieIndexSecond] = useState(0);

//     'setCurrentPieIndex' is not defined  no-undef
//   Line 1022:68:  'currentPieIndex' is not defined     no-undef
//   Line 1026:68:  'setCurrentPieIndex' is not defined  no-undef
//   Line 1039:60:  'currentPieIndex' is not defined     no-undef
//   Line 1040:77:  'currentPieIndex' is not defined     no-undef
//   Line 1087:64:  'currentPieIndex' is not defined     no-undef
//   Line 1101:60:  'currentPieIndex'

    const [firstPieData, setFirstPieData] = useState([]);
    const [isLoadingFirstPie, setIsLoadingFirstPie] = useState(true);

    // Add to the graphData state
    const [secondPieData, setSecondPieData] = useState([]);
    const [isLoadingSecondPie, setIsLoadingSecondPie] = useState(true);



    const [carouselImages] = useState([
        {
            id: 1,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_0.png',
            description: 'screen_0',
            features: []
        },
        {
            id: 2,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_1.png',
            description: 'screen_1',
            features: []
        },
        {
            id: 3,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_2.png',
            description: 'screen_2',
            features: []
        },
        {
            id: 4,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_3.png',
            description: 'screen_3',
            features: []
        },
        {
            id: 5,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_4.png',
            description: 'screen_4',
            features: []
        },
        {
            id: 6,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_5.png',
            description: 'screen_5',
            features: []
        },
        {
            id: 7,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_6.png',
            description: 'screen_6',
            features: []
        },
        {
            id: 8,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_7.png',
            description: 'screen_7',
            features: []
        },
        {
            id: 9,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_8.png',
            description: 'screen_8',
            features: []
        },
        {
            id: 10,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_9.png',
            description: 'screen_9',
            features: []
        },
        {
            id: 11,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_10.png',
            description: 'screen_10',
            features: []
        },
        {
            id: 12,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_11.png',
            description: 'screen_11',
            features: []
        },
        {
            id: 13,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_12.png',
            description: 'screen_12',
            features: []
        },
        {
            id: 14,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_13.png',
            description: 'screen_13',
            features: []
        },
        {
            id: 15,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_14.png',
            description: 'screen_14',
            features: []
        },
        {
            id: 16,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_15.png',
            description: 'screen_15',
            features: []
        },
        {
            id: 17,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_16.png',
            description: 'screen_16',
            features: []
        },
        {
            id: 18,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_17.png',
            description: 'screen_17',
            features: []
        },
        {
            id: 19,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_18.png',
            description: 'screen_18',
            features: []
        },
        {
            id: 20,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_19.png',
            description: 'screen_19',
            features: []
        },
        {
            id: 21,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_20.png',
            description: 'screen_20',
            features: []
        },
        {
            id: 22,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_21.png',
            description: 'screen_21',
            features: []
        },
        {
            id: 23,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_22.png',
            description: 'screen_22',
            features: []
        },
        {
            id: 24,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_23.png',
            description: 'screen_23',
            features: []
        },
        {
            id: 25,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_24.png',
            description: 'screen_24',
            features: []
        },
        {
            id: 26,
            imageUrl: 'https://moveoonestorage.blob.core.windows.net/moveoone-blob/yuno_screenshot_25.png',
            description: 'screen_25',
            features: []
        }
    ]);

    const [autoDetectData, setAutoDetectData] = useState([]);
    const [detections, setDetections] = useState([]);
    const [allDetections, setAllDetections] = useState([]);

    
    const [showAllDetections, setShowAllDetections] = useState(false);
    const [showAllChats, setShowAllChats] = useState(false);



    const [chatData, setChatData] = useState([]);
    const [chats, setChats] = useState([]);
    const [allChats, setAllChats] = useState([]);

    const handleDetectionClick = (detectionIndex) => {
        handleNewDetectionWithData(autoDetectData[detectionIndex])
    };

    const handleChatClick = (chatIndex) => {
        handleChatWithData(chatData[chatIndex])
    };

    const handlePrevSlide = () => {
        const carousel = document.getElementById('carousel');
        const newSlide = Math.max(currentSlide - 1, 0);
        setCurrentSlide(newSlide);
        carousel.scrollTo({
            left: newSlide * 300,
            behavior: 'smooth'
        });
    };

    const handleNextSlide = () => {
        const carousel = document.getElementById('carousel');
        const newSlide = Math.min(currentSlide + 1, carouselImages.length - 1);
        setCurrentSlide(newSlide);
        carousel.scrollTo({
            left: newSlide * 300,
            behavior: 'smooth'
        });
    };

    const handleDotClick = (index) => {
        const carousel = document.getElementById('carousel');
        setCurrentSlide(index);
        carousel.scrollTo({
            left: index * 300,
            behavior: 'smooth'
        });
    };

    const handleCarouselScroll = (e) => {
        const scrollPosition = e.target.scrollLeft;
        const newSlide = Math.round(scrollPosition / 300);
        if (newSlide !== currentSlide) {
            setCurrentSlide(newSlide);
        }
    };

    const customTheme = createTheme({
        palette: {
            primary: {
                main: '#6366F1',
            },
        },
    });

    const renderHelpButton = () => (
        <div className="absolute top-4 right-8 flex items-center gap-4">
            <button
                onClick={() => startSequence()}
                className="text-sm font-medium text-gray-500 hover:text-indigo-600 transition-colors"
            >
                Help
            </button>
        </div>
    );


    // Add ref for demo button
    const demoButtonRef = useRef(null);

    // Add refs for saved sections
    const savedDetectsRef = useRef(null);
    const savedChatsRef = useRef(null);

    useEffect(() => {
        // Create an array of promises for parallel execution
        const fetchPromises = [
            fetchAutoDetectDataPieChart,
            fetchMetaDataPieChart,
            fetchChatLog,
            fetchAutoDetectLog,
            fetchSessionAvgTime, 
            fetchSessionCount,
            fetchEventCount
        ].map(fetchFn => fetchFn(props.errorMessage, props.successMessage, props.showError));

        // Execute all promises in parallel
        Promise.all(fetchPromises).catch(err => {
            console.error('Error fetching data:', err);
        });

        // Start the tooltip sequence after a brief delay if in demo mode
        if (sessionStorage.getItem('showDemoTooltip') === 'true') {
            sessionStorage.setItem('showDemoTooltip', 'false');
            setTimeout(() => {
                startSequence();
            }, 3000);
        }
    }, []); // Empty dependency array ensures this runs only on mount

    const fetchAutoDetectLog = (show, hide, showError) => {
        show();
        return requestAutoDetectLog({})
            .then((data) => {
                const mappedDetections = data.map((item, index) => ({
                    id: index,
                    startDate: item.request.start_date.split(' ')[0],
                    endDate: item.request.end_date.split(' ')[0],
                    lastUpdated: formatDistanceToNow(new Date(item.lastUpdated), { addSuffix: true }),
                    status: Array.isArray(item.response?.detected)
                        ? `${item.response.detected.reduce((sum, detect) => sum + detect.outliers.y.length, 0)} issues`
                        : 'No issues',
                    filters: []
                }));
                setAutoDetectData(data);
                const topThreeDetections = mappedDetections.slice(0, 3);
                setDetections(topThreeDetections);
                setAllDetections(mappedDetections);
                hide();
            })
            .catch((err) => {
                props.errorMessage(err);
                hide();
                showError(err);
            });
    }

    const fetchChatLog = (show, hide, showError) => {
        show();
        return requestChatLog({})
            .then((data) => {
                console.log(data)
                const mappedChats = data.map((item, index) => ({
                    id: index,
                    startDate: item.request.start_date.split(' ')[0],
                    endDate: item.request.end_date.split(' ')[0],
                    lastActive: formatDistanceToNow(new Date(item.lastUpdated), { addSuffix: true }),
                    status: Array.isArray(item.response?.messages)
                        ? `${item.response.messages.length} messages`
                        : 'No messages',
                }));
                setChatData(data);
                const topThreeChats = mappedChats.slice(0, 3);
                setChats(topThreeChats);
                setAllChats(mappedChats);
                hide();
            })
            .catch((err) => {
                props.errorMessage(err);
                hide();
                showError(err);
            });
    }

    const fetchMetaDataPieChart = (show, hide, showError) => {
        show();
        setIsLoadingFirstPie(true);
        return requestMetadataStatistic()
            .then((data) => {
                const mappedPieData = data.metadata_statistics.map((item, index) => ({
                    class_name: item.class_name,
                    class_values: item.class_values.map((value, i) => ({
                        label: value.label,
                        value: value.count,
                        color: value.color || `hsl(${i * 45}, 70%, 50%)`
                    }))
                }));

                setFirstPieData(mappedPieData)
                setIsLoadingFirstPie(false);
                hide();
            })
            .catch((err) => {
                props.errorMessage(err);
                hide();
                showError(err);
                setIsLoadingFirstPie(false);
            });
    }

    const fetchAutoDetectDataPieChart = (show, hide, showError) => {
        show();
        setIsLoadingSecondPie(true);
        const today = new Date();
        const todayAnother = new Date();
        const sevenDaysAgo = new Date(todayAnother.setDate(todayAnother.getDate() - 7));
        const data = {
            start_date: sevenDaysAgo.toISOString().slice(0,10) + " 00:00:00",
            end_date: today.toISOString().slice(0,10) + " 00:00:00"
        }
        
        return requestAutoDetectDataStatistic(data)
            .then((data) => {
                const mappedPieData = data.outlier_statistics.map((item, index) => ({
                    class_name: item.class_name,
                    class_values: item.class_values.map((value, i) => ({
                        label: value.label,
                        value: value.value,
                        color: value.color || `hsl(${i * 45}, 70%, 50%)`
                    }))
                }));

                setSecondPieData(mappedPieData)
                setIsLoadingSecondPie(false);
                hide();
            })
            .catch((err) => {
                props.errorMessage(err);
                hide();
                showError(err);
                setIsLoadingSecondPie(false);
            });
    }

    const fetchSessionAvgTime = (show, hide, showError) => {
        show();
        return requestSessionAvgTime({})
            .then((data) => {
                const timeframe = data.period === 'Daily' ? 'vs yesterday' :
                                data.period === 'Weekly' ? 'vs last week' :
                                data.period === 'Monthly' ? 'vs last month' :
                                data.period === 'Yearly' ? 'vs last year' :
                                'vs previous period';
                const newStatistic = {
                    title: data.title,
                    value: data.value,
                    change: `${data.change}%`,
                    timeframe: timeframe,
                    period: data.period,
                    lastUpdated: data.lastUpdated.split(' ')[0]
                };
                
                setStatistics(prevStats => {
                    // Only add if not already present
                    const exists = prevStats.some(stat => stat.title === newStatistic.title);
                    if (!exists) {
                        return [...prevStats, newStatistic];
                    }
                    return prevStats;
                });
                hide();
            })
            .catch((err) => {
                props.errorMessage(err);
                hide();
                showError(err);
            });
    }

    const fetchSessionCount = (show, hide, showError) => {
        show();
        return requestSessionCount({})
            .then((data) => {
                const timeframe = data.period === 'Daily' ? 'vs yesterday' :
                                data.period === 'Weekly' ? 'vs last week' :
                                data.period === 'Monthly' ? 'vs last month' :
                                data.period === 'Yearly' ? 'vs last year' :
                                'vs previous period';
                const newStatistic = {
                    title: data.title,
                    value: data.value,
                    change: `${data.change}%`,
                    timeframe: timeframe,
                    period: data.period,
                    lastUpdated: data.lastUpdated.split(' ')[0]
                };
                
                setStatistics(prevStats => {
                    // Only add if not already present
                    const exists = prevStats.some(stat => stat.title === newStatistic.title);
                    if (!exists) {
                        return [...prevStats, newStatistic];
                    }
                    return prevStats;
                });
                hide();
            })
            .catch((err) => {
                props.errorMessage(err);
                hide();
                showError(err);
            });
    }

    const fetchEventCount = (show, hide, showError) => {
        show();
        return requestEventCount({})
            .then((data) => {
                const timeframe = data.period === 'Daily' ? 'vs yesterday' :
                                data.period === 'Weekly' ? 'vs last week' :
                                data.period === 'Monthly' ? 'vs last month' :
                                data.period === 'Yearly' ? 'vs last year' :
                                'vs previous period';
                const newStatistic = {
                    title: data.title,
                    value: data.value,
                    change: `${data.change}%`,
                    timeframe: timeframe,
                    period: data.period,
                    lastUpdated: data.lastUpdated.split(' ')[0]
                };
                
                setStatistics(prevStats => {
                    // Only add if not already present
                    const exists = prevStats.some(stat => stat.title === newStatistic.title);
                    if (!exists) {
                        return [...prevStats, newStatistic];
                    }
                    return prevStats;
                });
                hide();
            })
            .catch((err) => {
                props.errorMessage(err);
                hide();
                showError(err);
            });
    }

    // Render functions for list items
    const renderDetectionItem = (detection) => (
        <div
            key={detection.id}
            onClick={() => handleDetectionClick(detection.id)}
            className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm hover:shadow-md transition-shadow cursor-pointer relative"
        >
            <div className="absolute -top-2 -right-2 rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800 ring-2 ring-white">
                {detection.status}
            </div>

            <div className="flex justify-between items-start mb-4">
                <div className="flex-1">
                    <h3 className="text-lg font-medium text-gray-900">
                        {`${dayjs(detection.startDate).format('MMM Do YY')} - ${dayjs(detection.endDate).format('MMM Do YY')}`}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">Last updated {detection.lastUpdated}</p>
                </div>
            </div>

            <div className="flex flex-wrap gap-2">
                {detection.filters.map((filter, index) => (
                    <span
                        key={index}
                        className={`inline-flex items-center rounded-full px-2.5 py-1 text-xs font-medium ${filter.type === 'env' ? 'bg-blue-50 text-blue-700' :
                            filter.type === 'test' ? 'bg-purple-50 text-purple-700' :
                                'bg-gray-50 text-gray-700'
                            }`}
                    >
                        {filter.value}
                    </span>
                ))}
            </div>

            <div className="mt-4 flex justify-end">
                <button className="text-gray-400 hover:text-gray-500">
                    <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                    </svg>
                </button>
            </div>
        </div>
    );

    const renderChatItem = (chat) => (
        <div
            key={chat.id}
            onClick={() => handleChatClick(chat.id)}
            className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
        >
            <h3 className="text-lg font-medium text-gray-900">
                {`${dayjs(chat.startDate).format('MMM Do YY')} - ${dayjs(chat.endDate).format('MMM Do YY')}`}
            </h3>
            <p className="mt-2 text-sm text-gray-600">Active {chat.lastActive}</p>
            <div className="mt-4 flex items-center justify-between">
                <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${chat.status === 'Completed' ? 'bg-green-100 text-green-800' :
                    chat.status === 'Failed' ? 'bg-red-100 text-red-800' :
                        'bg-blue-100 text-blue-800'
                    }`}>
                    {chat.status}
                </span>
                <button className="text-gray-400 hover:text-gray-500">
                    <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                    </svg>
                </button>
            </div>
        </div>
    );

    const handleNewDetection = () => {
        history.push(PAGE_AUTO_DETECT);
    }

    // Add navigation handlers
    const handleNewDetectionWithData = (detectionData) => {
        history.push({
            pathname: PAGE_AUTO_DETECT,
            state: { 
                fromHome: true,
                detectionData: detectionData // Pass whatever data you need
            }
        });
    }

    const handleChatWithData = (chatData) => {
        history.push({
            pathname: PAGE_CHAT,
            state: { 
                fromHome: true,
                chatData: chatData // Pass whatever data you need
            }
        });
    }

    const handleNewChat = () => {
        history.push(PAGE_CHAT);
    };

    

    // Add this helper function for pie chart calculations
    const createPieChartPath = (value, total, startAngle = 0) => {
        // If there's only one value and it equals the total, create a full circle
        if (value === total) {
            const radius = 80;
            const centerX = 125;
            const centerY = 125;
            
            // Create a full circle path
            return `M ${centerX} ${centerY-radius} 
                    A ${radius} ${radius} 0 1 1 ${centerX-0.1} ${centerY-radius}
                    Z`;
        }

        const percentage = value / total;
        const angle = percentage * 360;
        const radius = 80;
        const centerX = 125;
        const centerY = 125;

        // Convert angle to radians for Math.cos and Math.sin
        const angleRad = (angle * Math.PI) / 180;
        const startAngleRad = (startAngle * Math.PI) / 180;

        // Calculate start and end points
        const x1 = centerX + radius * Math.cos(startAngleRad);
        const y1 = centerY + radius * Math.sin(startAngleRad);
        const x2 = centerX + radius * Math.cos(startAngleRad + angleRad);
        const y2 = centerY + radius * Math.sin(startAngleRad + angleRad);

        // Create the arc flag
        const largeArcFlag = angle > 180 ? 1 : 0;

        // Create the SVG path
        return `M ${centerX} ${centerY} L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`;
    };

    // Add this to your CSS (you can add it in the style tag or your CSS file)
    const pieChartStyles = `
        @keyframes slideIn {
            from {
                opacity: 0;
                transform: translateY(20px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes rotatePie {
            from {
                transform: rotate(-90deg);
                opacity: 0;
            }
            to {
                transform: rotate(0);
                opacity: 1;
            }
        }

        @keyframes scaleIn {
            from {
                transform: scale(0);
                opacity: 0;
            }
            to {
                transform: scale(1);
                opacity: 1;
            }
        }

        @keyframes fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    `;

    return (
        <ThemeProvider theme={customTheme}>
            <style>{pieChartStyles}</style>
            <div className="relative">
                {renderHelpButton()}

                {/* Demo button coachmark */}
                <Coachmark
                    id={COACHMARKS.SHOW_DEMO}
                    targetRef={demoButtonRef}
                    title="Demo app"
                    description="Screens showing the app that data was generated from."
                    position="bottom"
                />

                {/* Search coachmark */}
                <Coachmark
                    id={COACHMARKS.HOME_SEARCH}
                    targetRef={searchInputRef}
                    title="Command center"
                    description="Command center is where you can search across all your data, features, and settings from one place."
                    position="bottom"
                />

                {/* Modal Overlay */}
                {showOverview && (
                    <div className="fixed inset-0 z-50 overflow-y-auto">
                        <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                                <div className="bg-gradient-to-r from-indigo-50 via-purple-50 to-pink-50 p-6">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <h1 className="text-2xl font-bold text-gray-900">SimulationDemo App overview</h1>
                                            <div className="flex items-center mt-2">
                                                <p className="text-sm text-gray-500 mr-4">Swipe through app screenshots to to see how the app that data is taked from looks like</p>
                                                <span className="text-xs text-gray-400">
                                                    {currentSlide + 1} / {carouselImages.length}
                                                </span>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => setShowOverview(false)}
                                            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                                        >
                                            <svg className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>

                                    <div className="mt-6">
                                        <div className="relative">
                                            {/* Carousel Navigation Buttons */}
                                            {currentSlide > 0 && (
                                                <button
                                                    className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-all"
                                                    onClick={handlePrevSlide}
                                                >
                                                    <svg className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                                    </svg>
                                                </button>
                                            )}

                                            {currentSlide < carouselImages.length - 1 && (
                                                <button
                                                    className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white transition-all"
                                                    onClick={handleNextSlide}
                                                >
                                                    <svg className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                    </svg>
                                                </button>
                                            )}

                                            {/* Swipeable Carousel */}
                                            <div
                                                id="carousel"
                                                className="flex snap-x snap-mandatory overflow-x-auto hide-scrollbar scroll-smooth"
                                                style={{
                                                    scrollbarWidth: 'none',
                                                    msOverflowStyle: 'none',
                                                    WebkitOverflowScrolling: 'touch'
                                                }}
                                                onScroll={handleCarouselScroll}
                                            >
                                                {carouselImages.map((image) => (
                                                    <div
                                                        key={image.id}
                                                        className="flex-none w-[300px] snap-center px-2"
                                                    >
                                                        <div className="relative aspect-[9/16] rounded-lg overflow-hidden shadow-md">
                                                            <img
                                                                src={image.imageUrl}
                                                                alt={image.description}
                                                                className="object-cover w-full h-full"
                                                                draggable="false"
                                                            />
                                                            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-4">
                                                                <h3 className="text-white font-semibold mb-2">{image.description}</h3>
                                                                <ul className="text-white/80 text-sm">
                                                                    {image.features.map((feature, idx) => (
                                                                        <li key={idx} className="flex items-center mb-1">
                                                                            <span className="w-1.5 h-1.5 bg-white/80 rounded-full mr-2"></span>
                                                                            {feature}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* Page Dots */}
                                            <div className="flex justify-center mt-4 space-x-1.5">
                                                {carouselImages.map((image, index) => (
                                                    <button
                                                        key={image.id}
                                                        className={`h-1.5 transition-all duration-200 ${index === currentSlide
                                                            ? 'w-6 bg-indigo-600'
                                                            : 'w-1.5 bg-gray-300 hover:bg-gray-400'
                                                            } rounded-full focus:outline-none`}
                                                        onClick={() => handleDotClick(index)}
                                                        aria-label={`Go to slide ${index + 1}`}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                    {!showOverview && (
                        <button
                            ref={demoButtonRef}
                            onClick={() => setShowOverview(true)}
                            className="mb-4 inline-flex items-center px-3 py-1.5 text-sm border border-gray-200 rounded-md text-gray-600 bg-white hover:bg-gray-50 hover:text-gray-900 transition-colors duration-200"
                        >
                            <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Show me how demo app looks like
                        </button>
                    )}
                    <div className="flex justify-center relative">
                        <div className="w-1/2">
                            <div className="relative">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <input
                                    ref={searchInputRef}
                                    type="text"
                                    className="h-12 w-full rounded-lg border border-gray-300 bg-white pl-10 pr-4 text-gray-900 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Enter a command"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                {searchTerm && (
                                    <div className="absolute w-full mt-1 bg-white rounded-lg border border-gray-200 shadow-lg z-50">
                                        {filteredContent.length > 0 ? (
                                            <ul className="divide-y divide-gray-200">
                                                {filteredContent.map((item) => (
                                                    <li key={item.id} className="p-4 hover:bg-gray-50 cursor-pointer" onClick={() => handleSearchItemClick(item)}>
                                                        {item.text}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <div className="p-4 text-center text-gray-500">
                                                No results found
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="h-8"></div>

                    <div className="px-6">
                        <Grid2 container spacing={3} sx={{ mt: 2, mx: 4 }} justifyContent="space-between" alignItems="center">
                            {statistics.map((stat, index) => (
                                <Grid2 xs={12} sm={6} md={4} lg={2.4} key={index}>
                                    <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm text-center mx-4 relative">
                                        <span className="absolute -top-2 right-4 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                                            {stat.period}
                                        </span>
                                        <p className="text-sm font-medium text-gray-600">{stat.title}</p>
                                        <p className="mt-2 text-3xl font-bold text-gray-900">{stat.value}</p>
                                        <div className="mt-2 flex items-center justify-center text-sm">
                                            <span className={`text-${stat.change.startsWith('+') ? 'green' : 'red'}-600 font-medium`}>
                                                {stat.change}
                                            </span>
                                            <span className="ml-2 text-gray-600">{stat.timeframe}</span>
                                        </div>
                                    </div>
                                </Grid2>
                            ))}
                        </Grid2>

                        <div className="mt-12 mb-8">
                            <div className="grid grid-cols-2 gap-6">
                                {/* Issues & Detections Graph */}
                                <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-6">
                                    <div className="flex items-center justify-between mb-6">
                                        <div>
                                            <h2 className="text-lg font-semibold text-gray-900">Metadata Distribution</h2>
                                            <p className="mt-1 text-sm text-gray-500">Current state of metadata</p>
                                        </div>
                                    </div>

                                    <div className="h-[250px] w-full relative">
                                        {isLoadingFirstPie ? (
                                            <div className="h-full flex items-center justify-center">
                                                <p className="text-gray-500 text-lg animate-pulse flex items-center gap-1">
                                                    <span className="animate-[fadeIn_2s_ease-in-out_infinite]">Loading</span>
                                                    <span className="inline-block animate-[bounce_1s_ease-in-out_infinite] origin-bottom">.</span>
                                                    <span className="inline-block animate-[bounce_1s_ease-in-out_infinite_200ms] origin-bottom">.</span>
                                                    <span className="inline-block animate-[bounce_1s_ease-in-out_infinite_400ms] origin-bottom">.</span>
                                                </p>
                                            </div>
                                        ) : firstPieData.length === 0 ? (
                                            <div className="h-full flex items-center justify-center">
                                                <p className="text-gray-500 text-lg">Not enough data to display chart</p>
                                            </div>
                                        ) : (
                                            <>
                                                {/* Add navigation buttons and title */}
                                                {firstPieData.length > 1 && (
                                                    <div className="absolute top-0 left-0 right-0 flex justify-between items-center mb-4">
                                                        <button 
                                                            className="p-2 rounded-full hover:bg-gray-100"
                                                            onClick={() => setCurrentPieIndex(prev => prev === 0 ? firstPieData.length - 1 : prev - 1)}
                                                        >
                                                            <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                                            </svg>
                                                        </button>
                                                        <span className="text-2xl font-semibold text-gray-700 capitalize transition-all duration-500 transform"
                                                              style={{
                                                                  opacity: 1,
                                                                  transform: 'translateY(0)',
                                                                  animation: 'fadeInDown 0.5s ease-out'
                                                              }}>
                                                            {firstPieData[currentPieIndex].class_name}
                                                        </span>
                                                        <button 
                                                            className="p-2 rounded-full hover:bg-gray-100"
                                                            onClick={() => setCurrentPieIndex(prev => prev === firstPieData.length - 1 ? 0 : prev + 1)}
                                                        >
                                                            <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}

                                                <svg
                                                    viewBox="0 0 250 250"
                                                    className="w-full h-full transition-opacity duration-500"
                                                    style={{
                                                        opacity: 1,
                                                        animation: 'fadeIn 0.8s ease-out'
                                                    }}
                                                >
                                                    {firstPieData[currentPieIndex].class_values.reduce((acc, slice, i) => {
                                                        const total = firstPieData[currentPieIndex].class_values.reduce((sum, s) => sum + s.value, 0);
                                                        const startAngle = acc.angle;
                                                        const path = createPieChartPath(slice.value, total, startAngle);

                                                        return {
                                                            angle: startAngle + (slice.value / total) * 360,
                                                            elements: [...acc.elements,
                                                            <path
                                                                key={i}
                                                                d={path}
                                                                fill={slice.color}
                                                                stroke="white"
                                                                strokeWidth="2"
                                                                style={{
                                                                    transformOrigin: 'center',
                                                                    animation: 'rotatePie 1.2s ease-out forwards, fadeIn 0.8s ease-out',
                                                                    animationDelay: `${i * 0.15}s`
                                                                }}
                                                            />
                                                            ]
                                                        };
                                                    }, { angle: 0, elements: [] }).elements}

                                                    {/* Center circle for donut effect */}
                                                    <circle
                                                        cx="125"
                                                        cy="125"
                                                        r="50"
                                                        fill="white"
                                                        style={{
                                                            animation: 'scaleIn 0.8s ease-out forwards',
                                                            animationDelay: '0.5s'
                                                        }}
                                                    />

                                                    {/* Center text */}
                                                    <text
                                                        x="125"
                                                        y="115"
                                                        textAnchor="middle"
                                                        className="text-2xl font-semibold fill-gray-900"
                                                        style={{
                                                            animation: 'fadeIn 0.8s ease-out forwards',
                                                            animationDelay: '0.8s',
                                                            opacity: 0
                                                        }}
                                                    >
                                                        {firstPieData[currentPieIndex].class_values.reduce((sum, slice) => sum + slice.value, 0)}
                                                    </text>
                                                    <text
                                                        x="125"
                                                        y="140"
                                                        textAnchor="middle"
                                                        className="text-sm fill-gray-500"
                                                    >
                                                        Total
                                                    </text>
                                                </svg>

                                                {/* Legend */}
                                                <div className="absolute bottom-0 left-0 right-0 flex justify-center gap-4 flex-wrap">
                                                    {firstPieData[currentPieIndex].class_values.map((slice, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex items-center gap-2"
                                                            style={{
                                                                animation: 'slideIn 0.8s ease-out forwards',
                                                                animationDelay: `${index * 0.15 + 0.8}s`,
                                                                opacity: 0
                                                            }}
                                                        >
                                                            <div
                                                                className="w-3 h-3 rounded-full"
                                                                style={{ backgroundColor: slice.color }}
                                                            />
                                                            <span className="text-sm text-gray-600">
                                                                {slice.label} ({slice.value})
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {/* Chat Sessions Graph */}
                                <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-6">
                                    <div className="flex items-center justify-between mb-6">
                                        <div>
                                            <h2 className="text-lg font-semibold text-gray-900">Auto Detect</h2>
                                            <p className="mt-1 text-sm text-gray-500">Auto detect distribution in last 7 days</p>
                                        </div>
                                    </div>

                                    <div className="h-[250px] w-full relative">
                                        {isLoadingSecondPie ? (
                                            <div className="h-full flex items-center justify-center">
                                                <p className="text-gray-500 text-lg animate-pulse flex items-center gap-1">
                                                    <span className="animate-[fadeIn_2s_ease-in-out_infinite]">Loading</span>
                                                    <span className="inline-block animate-[bounce_1s_ease-in-out_infinite] origin-bottom">.</span>
                                                    <span className="inline-block animate-[bounce_1s_ease-in-out_infinite_200ms] origin-bottom">.</span>
                                                    <span className="inline-block animate-[bounce_1s_ease-in-out_infinite_400ms] origin-bottom">.</span>
                                                </p>
                                            </div>
                                        ) : secondPieData.length === 0 ? (
                                            <div className="h-full flex items-center justify-center">
                                                <p className="text-gray-500 text-lg">Not enough data to display chart</p>
                                            </div>
                                        ) : (
                                            <>
                                                {/* Add navigation buttons and title */}
                                                {secondPieData.length > 1 && (
                                                    <div className="absolute top-0 left-0 right-0 flex justify-between items-center mb-4">
                                                        <button 
                                                            className="p-2 rounded-full hover:bg-gray-100"
                                                            onClick={() => setCurrentPieIndexSecond(prev => prev === 0 ? secondPieData.length - 1 : prev - 1)}
                                                        >
                                                            <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                                            </svg>
                                                        </button>
                                                        <span className="text-2xl font-semibold text-gray-700 capitalize transition-all duration-500 transform"
                                                              style={{
                                                                  opacity: 1,
                                                                  transform: 'translateY(0)',
                                                                  animation: 'fadeInDown 0.5s ease-out'
                                                              }}>
                                                            {secondPieData[currentPieIndexSecond].class_name}
                                                        </span>
                                                        <button 
                                                            className="p-2 rounded-full hover:bg-gray-100"
                                                            onClick={() => setCurrentPieIndexSecond(prev => prev === secondPieData.length - 1 ? 0 : prev + 1)}
                                                        >
                                                            <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}

                                                <svg
                                                    viewBox="0 0 250 250"
                                                    className="w-full h-full transition-opacity duration-500"
                                                    style={{
                                                        opacity: 1,
                                                        animation: 'fadeIn 0.8s ease-out'
                                                    }}
                                                >
                                                    {secondPieData[currentPieIndexSecond].class_values.reduce((acc, slice, i) => {
                                                        const total = secondPieData[currentPieIndexSecond].class_values.reduce((sum, s) => sum + s.value, 0);
                                                        const startAngle = acc.angle;
                                                        const path = createPieChartPath(slice.value, total, startAngle);

                                                        return {
                                                            angle: startAngle + (slice.value / total) * 360,
                                                            elements: [...acc.elements,
                                                            <path
                                                                key={i}
                                                                d={path}
                                                                fill={slice.color}
                                                                stroke="white"
                                                                strokeWidth="2"
                                                                style={{
                                                                    transformOrigin: 'center',
                                                                    animation: 'rotatePie 1.2s ease-out forwards, fadeIn 0.8s ease-out',
                                                                    animationDelay: `${i * 0.15}s`
                                                                }}
                                                            />
                                                            ]
                                                        };
                                                    }, { angle: 0, elements: [] }).elements}

                                                    {/* Center circle for donut effect */}
                                                    <circle
                                                        cx="125"
                                                        cy="125"
                                                        r="50"
                                                        fill="white"
                                                        style={{
                                                            animation: 'scaleIn 0.8s ease-out forwards',
                                                            animationDelay: '0.5s'
                                                        }}
                                                    />

                                                    {/* Center text */}
                                                    <text
                                                        x="125"
                                                        y="115"
                                                        textAnchor="middle"
                                                        className="text-2xl font-semibold fill-gray-900"
                                                        style={{
                                                            animation: 'fadeIn 0.8s ease-out forwards',
                                                            animationDelay: '0.8s',
                                                            opacity: 0
                                                        }}
                                                    >
                                                        {secondPieData[currentPieIndexSecond].class_values.reduce((sum, slice) => sum + slice.value, 0)}
                                                    </text>
                                                    <text
                                                        x="125"
                                                        y="140"
                                                        textAnchor="middle"
                                                        className="text-sm fill-gray-500"
                                                    >
                                                        Total
                                                    </text>
                                                </svg>

                                                {/* Legend */}
                                                <div className="absolute bottom-0 left-0 right-0 flex justify-center gap-4 flex-wrap">
                                                    {secondPieData[currentPieIndexSecond].class_values.map((slice, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex items-center gap-2"
                                                            style={{
                                                                animation: 'slideIn 0.8s ease-out forwards',
                                                                animationDelay: `${index * 0.15 + 0.8}s`,
                                                                opacity: 0
                                                            }}
                                                        >
                                                            <div
                                                                className="w-3 h-3 rounded-full"
                                                                style={{ backgroundColor: slice.color }}
                                                            />
                                                            <span className="text-sm text-gray-600">
                                                                {slice.label} ({slice.value})
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="my-8 border-gray-200" />

                        <div ref={savedDetectsRef} className="mt-8">
                            <div className="flex items-center mb-4">
                                <h2 className="text-lg font-semibold text-gray-900">Recent Auto Detections</h2>
                                <button
                                    onClick={() => setShowAllDetections(true)}
                                    className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-700 flex items-center"
                                >
                                    See all
                                    <span className="ml-2 inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
                                        {allDetections.length}
                                    </span>
                                </button>
                            </div>
                            <Grid2 container spacing={3}>
                                {detections.map((detection, index) => (
                                    <Grid2 xs={12} md={4} key={detection.id}>
                                        <div
                                            onClick={() => handleDetectionClick(detection.id)}
                                            className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm hover:shadow-md transition-shadow cursor-pointer relative h-full flex flex-col"
                                        >
                                            <div className="absolute -top-2 -right-2 rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800 ring-2 ring-white">
                                                {detection.status}
                                            </div>

                                            <div className="mb-6">
                                                <h3 className="text-lg font-medium text-gray-900">
                                                    {`${dayjs(detection.startDate).format('MMM D')}${getDaySuffix(dayjs(detection.startDate).date())} ${dayjs(detection.startDate).format('YY')} - ${dayjs(detection.endDate).format('MMM D')}${getDaySuffix(dayjs(detection.endDate).date())} ${dayjs(detection.endDate).format('YY')}`}
                                                </h3>
                                                <p className="mt-2 text-sm text-gray-500">Last updated {detection.lastUpdated}</p>
                                            </div>

                                            <div className="flex-grow">
                                                <div className="flex flex-wrap gap-2">
                                                    {detection.filters.map((filter, index) => (
                                                        <span
                                                            key={index}
                                                            className={`inline-flex items-center rounded-full px-2.5 py-1 text-xs font-medium ${filter.type === 'env' ? 'bg-blue-50 text-blue-700' :
                                                                filter.type === 'test' ? 'bg-purple-50 text-purple-700' :
                                                                    'bg-gray-50 text-gray-700'
                                                                }`}
                                                        >
                                                            {filter.value}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid2>
                                ))}
                                <Grid2 xs={12} md={4}>
                                    <button
                                        onClick={handleNewDetection}
                                        className="flex h-full w-full items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-6 text-gray-500 hover:border-gray-400 hover:text-gray-600"
                                    >
                                        <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                        </svg>
                                    </button>
                                </Grid2>
                            </Grid2>
                        </div>

                        <hr className="my-8 border-gray-200" />

                        <div ref={savedChatsRef} className="mt-8">
                            <div className="flex items-center mb-4">
                                <h2 className="text-lg font-semibold text-gray-900">Recent Chats</h2>
                                <button
                                    onClick={() => setShowAllChats(true)}
                                    className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-700"
                                >
                                    See all
                                </button>
                            </div>
                            <Grid2 container spacing={3}>
                                {chats.map((chat) => (
                                    <Grid2 xs={12} md={4} key={chat.id}>
                                        <div
                                            onClick={() => handleChatClick(chat.id)}
                                            className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                                        >
                                            <h3 className="text-lg font-medium text-gray-900">
                                            {`${dayjs(chat.startDate).format('MMM D')}${getDaySuffix(dayjs(chat.startDate).date())} ${dayjs(chat.startDate).format('YY')} - ${dayjs(chat.endDate).format('MMM D')}${getDaySuffix(dayjs(chat.endDate).date())} ${dayjs(chat.endDate).format('YY')}`}
                                            </h3>
                                            <p className="mt-2 text-sm text-gray-600">Active {chat.lastActive}</p>
                                            <div className="mt-4 flex items-center justify-between">
                                                <span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                                                    {chat.status}
                                                </span>
                                                <button className="text-gray-400 hover:text-gray-500">
                                                    <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </Grid2>
                                ))}
                                <Grid2 xs={12} md={4}>
                                    <button
                                        onClick={handleNewChat}
                                        className="flex h-full w-full items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-6 text-gray-500 hover:border-gray-400 hover:text-gray-600"
                                    >
                                        <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                        </svg>
                                    </button>
                                </Grid2>
                            </Grid2>
                        </div>
                    </div>

                </Container>

                {/* Add Overlays */}
                <OverlayList
                    title="All Auto Detections"
                    isOpen={showAllDetections}
                    onClose={() => setShowAllDetections(false)}
                    items={allDetections}
                    renderItem={renderDetectionItem}
                    emptyMessage="No detections found"
                />

                <OverlayList
                    title="All Chat Sessions"
                    isOpen={showAllChats}
                    onClose={() => setShowAllChats(false)}
                    items={allChats}
                    renderItem={renderChatItem}
                    emptyMessage="No chat sessions found"
                />

                <DialogComponent
                    title='Logout?'
                    subtitle='You will lose all unsaved work.'
                    buttonText='Logout'
                    cancelText='Cancel'
                    action={() => {
                        let { from } = { from: { pathname: "/" } };
                        history.replace(from);
                        window.location.reload(false);
                    }}
                    open={askLogout}
                    setOpen={setAskLogout}
                />
            </div >
        </ThemeProvider>
    )
}

HomeView.propTypes = {
    title: PropTypes.string.isRequired,
};

export default HomeView
