import axios from 'axios'
import { SYSTEM_ERROR } from "config/CONSTANTS";
import { handleTokenExpiration } from "./tokenExpHandler";
import { FETCH_FEATURES, REQUEST_PLOT } from "./CONSTANTS";


/**
 * Get Content.
 */
export const requestFeatureNames = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };
        try {
            axios
                .post(FETCH_FEATURES(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in plotService > requestFeatureNames, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const requestPlot = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };
        try {
            axios
                .post(REQUEST_PLOT(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in plotService > requestFeatureNames, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};