import * as React from 'react';
import { BASE_URL, DEMO_MODE } from 'services/CONSTANTS';
import { useLocation } from 'react-router-dom';
import { AuthBackground } from '../../../components/auth/AuthBackground';

export default function SignUp(props) {
  const [checked, setChecked] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState('basic');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const promoFromUrl = params.get('promo') || '';

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const firstName = data.get('firstName');
    const lastName = data.get('lastName');
    const userName = data.get('username');
    const email = data.get('email');
    const password = data.get('password');
    const promotions = checked;
    const promoCode = data.get('promoCode');
    
    if (DEMO_MODE() !== 'true') {
      props.signup(userName, password, firstName, lastName, email, promotions, promoCode, selectedPlan);
    }
  };

  return (
    <>
      <div className="relative isolate px-6 pt-8 lg:px-8">
        <AuthBackground />
        <div className="flex flex-col lg:flex-row min-h-screen">
          {/* Pricing Plans - Full width on mobile, 1/3 width on desktop */}
          <div className="w-full lg:w-1/3 bg-gray-50 p-4 sm:p-6 lg:p-8 lg:border-r">
            <h2 className="text-2xl lg:text-3xl font-bold text-gray-900 mb-2">Choose Your Plan</h2>
            <p className="text-sm text-gray-600 mb-6 lg:mb-8">No credit card required during trial</p>
            <div className="space-y-4 lg:space-y-6">
              <div 
                className={`p-4 lg:p-6 rounded-xl cursor-pointer transition-all ${
                  selectedPlan === 'basic' 
                    ? 'bg-white border-2 border-indigo-600 shadow-lg transform scale-105' 
                    : 'bg-white border border-gray-200 hover:shadow'
                }`}
                onClick={() => setSelectedPlan('basic')}
              >
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
                  <h3 className="text-base lg:text-xl font-bold text-gray-900">Basic</h3>
                  <span className="text-lg lg:text-2xl font-bold text-indigo-600">$9.99/mo</span>
                </div>
                <ul className="space-y-2">
                  <li className="flex items-center text-xs sm:text-sm lg:text-base text-gray-700">
                    <svg className="w-4 h-4 lg:w-5 lg:h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Up to 100 movements
                  </li>
                  <li className="flex items-center text-xs sm:text-sm lg:text-base text-gray-700">
                    <svg className="w-4 h-4 lg:w-5 lg:h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Basic analytics
                  </li>
                </ul>
              </div>

              <div 
                className={`p-4 lg:p-6 rounded-xl cursor-pointer transition-all ${
                  selectedPlan === 'pro' 
                    ? 'bg-white border-2 border-indigo-600 shadow-lg transform scale-105' 
                    : 'bg-white border border-gray-200 hover:shadow'
                }`}
                onClick={() => setSelectedPlan('pro')}
              >
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
                  <h3 className="text-base lg:text-xl font-bold text-gray-900">Pro</h3>
                  <span className="text-lg lg:text-2xl font-bold text-indigo-600">$29.99/mo</span>
                </div>
                <ul className="space-y-2">
                  <li className="flex items-center text-xs sm:text-sm lg:text-base text-gray-700">
                    <svg className="w-4 h-4 lg:w-5 lg:h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Up to 1000 movements
                  </li>
                  <li className="flex items-center text-xs sm:text-sm lg:text-base text-gray-700">
                    <svg className="w-4 h-4 lg:w-5 lg:h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Advanced analytics
                  </li>
                  <li className="flex items-center text-xs sm:text-sm lg:text-base text-gray-700">
                    <svg className="w-4 h-4 lg:w-5 lg:h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Priority support
                  </li>
                </ul>
              </div>

              <div 
                className={`p-4 lg:p-6 rounded-xl cursor-pointer transition-all ${
                  selectedPlan === 'enterprise' 
                    ? 'bg-white border-2 border-indigo-600 shadow-lg transform scale-105' 
                    : 'bg-white border border-gray-200 hover:shadow'
                }`}
                onClick={() => setSelectedPlan('enterprise')}
              >
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
                  <h3 className="text-base lg:text-xl font-bold text-gray-900">Enterprise</h3>
                  <span className="text-lg lg:text-2xl font-bold text-indigo-600">$99.99/mo</span>
                </div>
                <ul className="space-y-2">
                  <li className="flex items-center text-xs sm:text-sm lg:text-base text-gray-700">
                    <svg className="w-4 h-4 lg:w-5 lg:h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Unlimited movements
                  </li>
                  <li className="flex items-center text-xs sm:text-sm lg:text-base text-gray-700">
                    <svg className="w-4 h-4 lg:w-5 lg:h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Custom analytics
                  </li>
                  <li className="flex items-center text-xs sm:text-sm lg:text-base text-gray-700">
                    <svg className="w-4 h-4 lg:w-5 lg:h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    24/7 support
                  </li>
                  <li className="flex items-center text-xs sm:text-sm lg:text-base text-gray-700">
                    <svg className="w-4 h-4 lg:w-5 lg:h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Custom integrations
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Sign up form - Full width on mobile, 2/3 width on desktop */}
          <div className="w-full lg:w-2/3 p-8 flex items-center">
            <div className="max-w-md mx-auto">
              <h2 className="text-3xl font-bold text-gray-900 mb-8">Create your account</h2>
              <form onSubmit={handleSubmit} method="POST" className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First name</label>
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last name</label>
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label htmlFor="promoCode" className="block text-sm font-medium text-gray-700">Promo Code</label>
                  <input
                    id="promoCode"
                    name="promoCode"
                    type="text"
                    defaultValue={promoFromUrl}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div className="flex items-center">
                  <input
                    id="promotions"
                    name="promotions"
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor="promotions" className="ml-2 block text-sm text-gray-700">
                    I want to receive updates via email
                  </label>
                </div>

                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign Up
                </button>
              </form>

              <p className="mt-6 text-center text-sm text-gray-500">
                Already have an account?{' '}
                <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Sign In
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}