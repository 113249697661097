import React from 'react';
import IntegrationsView from './IntegrationsView';
import { useToast } from '../../../hooks/useToast';

const IntegrationsContainer = () => {
    const { showSuccessToast, showErrorToast } = useToast();

    return (
        <IntegrationsView
            successMessage={showSuccessToast}
            errorMessage={showErrorToast}
        />
    );
};

export default IntegrationsContainer; 