import React from 'react';
import { RouterConfig } from "navigation/RouterConfig";


import HeaderComponent from '../components/tailwind/HeaderComponent'

function Copyright() {
    return (
        <div className="text-center text-sm text-gray-500">
            &copy; {new Date().getFullYear()}{' '}
            <a
                href="https://www.moveo.one/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:underline"
            >
                Moveo One
            </a>
            . All rights reserved.
        </div>
    );
}

export default function LayoutContainer() {
    return (
        <div className="min-h-screen flex flex-col">
            <HeaderComponent />

            <main className="flex-1 pt-[73px]">
                <div className="w-full px-4 py-6 sm:px-6 lg:px-8">
                    <RouterConfig />
                </div>
            </main>

            <footer className="bg-gray-100 py-4 mt-auto">
                <div className="w-full px-4 sm:px-6 lg:px-8">
                    <Copyright />
                </div>
            </footer>
        </div>
    );
}
