import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { Grid2 } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';

import dayjs from 'dayjs';
import { Divider } from '@mui/material';
import ChatComponent from 'components/ChatComponent';
import CircularProgress from '@mui/material/CircularProgress';

import 'dayjs/locale/en-gb'; // Import the locale for DD/MM/YYYY format
import { postUserMessage, requestChatMessages, requestEndChat, requestSemanticGroups, requestStartChat } from 'services/chatService';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SelectComponentTailwind from 'components/tailwind/SelectComponent';
import EditTextTailwind from 'components/tailwind/EditTextComponent';

import {
    ArrowPathIcon,
    ArrowRightCircleIcon
} from '@heroicons/react/24/outline'

dayjs.locale('en-gb'); // Set Day.js locale to 'en-gb'

const ChatView = props => {

    const customTheme = createTheme({
        palette: {
            primary: {
                main: '#6366F1', // Tailwind Indigo-500
            },
        },
    });


    return (
        <ThemeProvider theme={customTheme}>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Container
                    maxWidth="false"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        height: 'calc(80vh - 6vh)', // Take into account the footer's height
                    }}
                >
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            backgroundColor: '#f5f5f5', // Light grey background
                            borderRadius: '16px',       // Set corner radius

                        }}
                    >

                        {/* Chat Section */}
                        <Box
                            ref={props.chatContainerRef}
                            sx={{
                                flexGrow: 1,             // Ensure the chat box grows and fills the available height
                                overflowY: 'auto',        // Enable scrolling for the chat messages
                                mb: 2,
                                p: 2,
                            }}
                        >


                            {/* Chat messages */}
                            <Box>
                                {props.messages.map((row, index) => (
                                    <ChatComponent text={row.content} fromMe={row.origin_of_message !== 0} />
                                ))}

                                {props.showProgress &&
                                    <Grid2 item xs={12}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center', // Centers the spinner horizontally
                                                width: '100%', // Ensures the Box spans the full width
                                                mt: 2
                                            }}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Box>
                                    </Grid2>
                                }
                            </Box>
                        </Box>

                        {!props.historyLoaded && (
                            <Grid2 size={{ xs: 12, sm: 12 }}>
                                <EditTextTailwind
                                    name='chat'
                                    placeholder='enter your message'
                                    value={props.userMessage}
                                    onChange={props.handleUserMessageChange}
                                    onKeyDown={props.handleKeyPress}
                                    fullWidth={true}
                                    multiline={true}
                                />
                            </Grid2>
                        )}


                    </Paper>
                </Container>
            </div>
        </ThemeProvider>
    )

}

ChatView.propTypes = {
    title: PropTypes.string.isRequired,
    errorMessage: PropTypes.func,
    successMessage: PropTypes.func,
}

export default ChatView
