'use client'
import PropTypes from 'prop-types';

function Spinner({ size = 'md' }) {
    const sizes = {
        sm: 'size-8',
        md: 'size-16',
        lg: 'size-24'
    };

    return (
        <div className="flex flex-col items-center justify-center">
            {/* Main spinner container */}
            <div className={`relative ${sizes[size]}`}>
                {/* Outer rotating ring */}
                <div className={`absolute inset-0 ${sizes[size]} rounded-full border-8 border-indigo-500 opacity-25 animate-pulse`}></div>
                
                {/* Inner spinning ring */}
                <div className={`absolute inset-0 ${sizes[size]} rounded-full border-t-8 border-r-8 border-indigo-200 animate-[spin_1s_linear_infinite]`}></div>
                
                {/* Pulsing center dot */}
                <div className="absolute inset-0 m-auto size-4 rounded-full bg-indigo-500 animate-[ping_1.5s_ease-in-out_infinite]"></div>
                
                {/* Static center dot */}
                <div className="absolute inset-0 m-auto size-2 rounded-full bg-indigo-200"></div>
                
                {/* Orbiting particles */}
                <div className={`absolute inset-0 ${sizes[size]} animate-[spin_3s_linear_infinite]`}>
                    <div className="absolute top-0 left-1/2 size-2 -ml-1 rounded-full bg-indigo-500"></div>
                </div>
                <div className={`absolute inset-0 ${sizes[size]} animate-[spin_2s_linear_infinite]`}>
                    <div className="absolute top-0 left-1/2 size-1.5 -ml-0.75 rounded-full bg-indigo-300"></div>
                </div>
            </div>

            {/* Loading text */}
            <div className="mt-4 text-center">
                <h2 className="text-lg font-semibold text-indigo-600 animate-pulse">Loading</h2>
                <p className="mt-1 text-xs text-indigo-400 opacity-75">Please wait...</p>
            </div>
        </div>
    );
}

Spinner.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

export default Spinner;
