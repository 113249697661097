import React from "react";
import PropTypes from "prop-types";

function EditTextTailwind(props) {
    const { title, prefix, name, placeholder, value, onChange, onKeyDown, fullWidth, multiline, rows } = props;

    return (
        <div>
            {title &&
                <label htmlFor="price" className="block text-sm/6 font-medium text-gray-900">
                    {title}
                </label>
            }
            <div className="relative mt-2 rounded-md shadow-sm">
                {prefix &&
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                }
                {multiline ? (
                    <textarea
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        rows={rows || 3} // Default to 3 rows if `rows` is not provided
                        className={`block ${fullWidth ? "w-full" : ""} rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm resize-none`}
                        onKeyDown={onKeyDown} // Add onKeyDown here
                        onChange={onChange}
                    />
                ) : (
                    <input
                        name={name}
                        type="text"
                        value={value}
                        placeholder={placeholder}
                        className={`block ${fullWidth ? "w-full" : ""} rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm`}
                        onKeyDown={onKeyDown} // Add onKeyDown here
                        onChange={onChange}
                    />
                )}
            </div>
        </div>
    )
}

EditTextTailwind.propTypes = {
    title: PropTypes.string,
    prefix: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    fullWidth: PropTypes.bool, // Added for full-width option
    multiline: PropTypes.bool, // Added for multiline support
    rows: PropTypes.number, // Optional number of rows for multiline
};

EditTextTailwind.defaultProps = {
    fullWidth: false,
    multiline: false,
    rows: 3, // Default to 3 rows for multiline input
};

export default EditTextTailwind;
