'use client'
import PropTypes from 'prop-types';

function SpinnerFullPage({ fullPage = true }) {
    return (
        <div className={`
            flex flex-col items-center justify-center
            ${fullPage ? 'fixed inset-0 bg-gradient-to-r from-indigo-500/90 via-indigo-600/90 to-indigo-500/90 backdrop-blur-sm z-50' : ''}
        `}>
            {/* Main spinner container */}
            <div className="relative">
                {/* Outer rotating ring */}
                <div className="absolute inset-0 size-16 rounded-full border-8 border-indigo-200 opacity-25 animate-pulse"></div>
                
                {/* Inner spinning ring */}
                <div className="absolute inset-0 size-16 rounded-full border-t-8 border-r-8 border-indigo-500 animate-[spin_1s_linear_infinite]"></div>
                
                {/* Pulsing center dot */}
                <div className="absolute inset-0 m-auto size-4 rounded-full bg-white animate-[ping_1.5s_ease-in-out_infinite]"></div>
                
                {/* Static center dot */}
                <div className="absolute inset-0 m-auto size-2 rounded-full bg-indigo-500"></div>
                
                {/* Orbiting particles */}
                <div className="absolute inset-0 size-16 animate-[spin_3s_linear_infinite]">
                    <div className="absolute top-0 left-1/2 size-2 -ml-1 rounded-full bg-white"></div>
                </div>
                <div className="absolute inset-0 size-16 animate-[spin_2s_linear_infinite]">
                    <div className="absolute top-0 left-1/2 size-1.5 -ml-0.75 rounded-full bg-indigo-200"></div>
                </div>
            </div>

            {/* Loading text */}
            <div className="mt-8 text-center">
                <h2 className="text-xl font-semibold text-white animate-pulse">Loading</h2>
                <p className="mt-2 text-sm text-indigo-100 opacity-75">Please wait while we prepare your experience...</p>
            </div>

            {/* Radial gradient background effect */}
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(255,255,255,0.1)_0%,transparent_70%)] animate-pulse"></div>

            {/* Pattern overlay */}
            <div className="absolute inset-0 bg-[linear-gradient(45deg,rgba(255,255,255,0.05)_25%,transparent_25%,transparent_50%,rgba(255,255,255,0.05)_50%,rgba(255,255,255,0.05)_75%,transparent_75%,transparent)] bg-[length:10px_10px]"></div>
        </div>
    );
}

SpinnerFullPage.propTypes = {
    fullPage: PropTypes.bool
};

export default SpinnerFullPage;



