// All user related database operations can be defined here.
import { SYSTEM_ERROR } from 'config/CONSTANTS';
import axios from 'axios'
import { DEMO_MODE, PRE_SIGNIN_URL, USER_LOGIN_URL } from "./CONSTANTS";


/**
 * PreSign in.
 */
export const presignIn = (email, password) => {
    

    const user = {
        username: email,
        password: password
    };

    return new Promise((resolve, reject) => {
        try {
            // do an SDK, DB call or API endpoint axios call here and return the promise.
            axios
                .post(PRE_SIGNIN_URL(), user)
                .then((res) => {
                    
                    console.log(res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in userService > signIn, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

/**
 * Sign in.
 */
export const signIn = (email, password, orgId) => {
    

    const user = {
        username: email,
        password: password,
        org_id: orgId
    };
    
    return new Promise((resolve, reject) => {
        try {
            // do an SDK, DB call or API endpoint axios call here and return the promise.
            axios
                .post(USER_LOGIN_URL(), user)
                .then((res) => {
                    console.log(res);
                    // Setting the token into axios defaults and session storage
                    sessionStorage.setItem('authToken', res.data.token)
                    sessionStorage.setItem('userToken', res.data.user_token)
                    sessionStorage.setItem('showDemoTooltip', DEMO_MODE() === 'true');
                    sessionStorage.setItem('name', email);
                    sessionStorage.setItem('roles', 'admin');
                    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('authToken');
                    axios.defaults.headers.common['X-User-Token'] = sessionStorage.getItem('userToken');
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in userService > signIn, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const signOut = () => {
    // Setting the token into axios defaults and session storage
    sessionStorage.setItem('authToken', null)
    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('authToken');
};


