import { useCallback } from 'react';
import { toast } from 'react-toastify';

export const useToast = () => {
    const showSuccessToast = useCallback((message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }, []);

    const showErrorToast = useCallback((message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }, []);

    return {
        showSuccessToast,
        showErrorToast
    };
}; 