/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to 
 * keep your app working for UI changes and 
 * make it independent of network requirements.
 * 
 * They need to be categorised and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 * 
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */

export const BASE_URL =
  window.__env__?.REACT_APP_BASE_URL ||           // Azure Static Web App runtime variable
  process.env.REACT_APP_BASE_URL ||              // Local .env variable
  'https://web-api.moveo.one';                   // Fallback to production link

export const DEMO_MODE = () => `${process.env.REACT_APP_DEMO_MODE === 'true' ? 'true' : 'false'}`;

export const API_URL = '/api'

// Actual endpoints. Uncomment below section to use actual data.
export const USER_LOGIN_URL = () => `${BASE_URL}/login`;
export const PRE_SIGNIN_URL = () => `${BASE_URL}/prelogin`;
export const AUTO_DETECT_URL = () => `${BASE_URL}${API_URL}/analytic/auto-detect`;
export const START_CHAT_URL = () => `${BASE_URL}${API_URL}/analytic/start-chat`;
export const FETCH_SEMANTIC_GROUP_URL = () => `${BASE_URL}${API_URL}/analytic/fetch-semantic-groups`;
export const FETCH_MESSAGES_URL = () => `${BASE_URL}${API_URL}/analytic/fetch-chat-messages`;
export const POST_MESSAGE_URL = () => `${BASE_URL}${API_URL}/analytic/continue-chat`;
export const END_CHAT_URL = () => `${BASE_URL}${API_URL}/analytic/end-chat`;
export const FETCH_FEATURES = () => `${BASE_URL}${API_URL}/analytic/fetch-feature-names`;
export const REQUEST_PLOT = () => `${BASE_URL}${API_URL}/analytic/plot`;
export const FETCH_SESSION_METADATA = () => `${BASE_URL}${API_URL}/analytic/fetch-session-metadata`;
export const FETCH_AUTO_DETECT_LOG = () => `${BASE_URL}${API_URL}/analytic/fetch-auto-detect-log`;
export const FETCH_CHAT_LOG = () => `${BASE_URL}${API_URL}/analytic/fetch-chat-log`;
export const FETCH_METADATA_STATISTIC = () => `${BASE_URL}${API_URL}/analytic/fetch-metadata-statistics`;
export const FETCH_AUTO_DETECT_DATA_STATISTIC = () => `${BASE_URL}${API_URL}/analytic/fetch-outlier-statistics`;
export const FETCH_SESSION_AVG_TIME = () => `${BASE_URL}${API_URL}/analytic/fetch-session-avg-time`;
export const FETCH_SESSION_COUNT = () => `${BASE_URL}${API_URL}/analytic/fetch-session-count`;
export const FETCH_EVENT_COUNT = () => `${BASE_URL}${API_URL}/analytic/fetch-event-count`;
