import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { PhotoIcon, UserCircleIcon, KeyIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { DEMO_MODE } from 'services/CONSTANTS';

const ProfileView = props => {
    const history = useHistory();
    const [profileData, setProfileData] = useState({
        username: '',
        title: '',
        description: '',
        email: '',
        photo: null,
        settings: {
            darkMode: false,
            emailNotifications: true,
            pushNotifications: 'all',
            language: 'en',
            timezone: 'UTC',
            defaultView: 'grid',
            autoRefresh: true,
            refreshInterval: 5,
            compactMode: false,
            showAnalytics: true,
            defaultDashboard: 'main',
            menuCollapsed: false,
            colorScheme: 'system'
        }
    });

    const fileInputRef = useRef(null);

    const [showDemoMessage, setShowDemoMessage] = React.useState(DEMO_MODE() === 'true')

    const handleCancel = () => {
        // Get the previous path from sessionStorage or default to home
        const previousPath = sessionStorage.getItem('lastDashboardPath') || '/home';
        history.push(previousPath);
    };

    const handleSave = async () => {
        try {
            // API call placeholder
            // await updateProfile(profileData);
            props.successMessage?.('Profile updated successfully');
        } catch (error) {
            props.errorMessage?.(error.message);
        }
    };

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Handle photo upload
            setProfileData(prev => ({...prev, photo: file}));
        }
    };

    const handlePasswordReset = async () => {
        try {
            // API call placeholder
            // await requestPasswordReset(profileData.email);
            props.successMessage?.('Password reset email sent');
        } catch (error) {
            props.errorMessage?.(error.message);
        }
    };

    const handleInputChange = (field, value) => {
        setProfileData(prev => ({...prev, [field]: value}));
    };

    const handleSettingChange = (setting, value) => {
        setProfileData(prev => ({
            ...prev,
            settings: {...prev.settings, [setting]: value}
        }));
    };

    return (
        <div>
            {showDemoMessage && (
                <div className="rounded-md bg-blue-50 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                                Demo mode is active. In demo mode, this is only UI preview of the profile.
                            </p>
                            <button
                                type="button"
                                className="ml-3 flex-shrink-0"
                                onClick={() => setShowDemoMessage(false)}
                            >
                                <XMarkIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm/6 font-semibold text-gray-900" onClick={handleCancel}>
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={handleSave}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>

            <div className="space-y-12 max-w-5xl mx-auto">
                {/* Basic Profile Section */}
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base/7 font-semibold text-gray-900">Profile</h2>
                    <p className="mt-1 text-sm/6 text-gray-600">
                        Manage your profile information and settings.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        {/* Username */}
                        <div className="sm:col-span-4">
                            <label htmlFor="username" className="block text-sm/6 font-medium text-gray-900">
                                Username
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    id="username"
                                    value={profileData.username}
                                    onChange={(e) => handleInputChange('username', e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                            </div>
                        </div>

                        {/* Password Change Section */}
                        <div className="sm:col-span-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h3 className="text-sm/6 font-medium text-gray-900">Password</h3>
                                    <p className="text-sm text-gray-500">Update your password or reset if forgotten</p>
                                </div>
                                <div className="flex space-x-3">
                                    <button
                                        type="button"
                                        onClick={() => handlePasswordReset()}
                                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        <KeyIcon className="h-5 w-5 mr-2" />
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Profile Photo with Upload Area */}
                        <div className="col-span-full">
                            <label htmlFor="cover-photo" className="block text-sm/6 font-medium text-gray-900">
                                Profile Photo
                            </label>
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <div className="text-center">
                                    {profileData.photo ? (
                                        <img 
                                            src={URL.createObjectURL(profileData.photo)} 
                                            alt="Profile" 
                                            className="mx-auto h-32 w-32 rounded-full object-cover"
                                        />
                                    ) : (
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" />
                                    )}
                                    <div className="mt-4 flex text-sm/6 text-gray-600 justify-center">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <span>Upload a file</span>
                                            <input 
                                                id="file-upload" 
                                                name="file-upload" 
                                                type="file" 
                                                className="sr-only"
                                                onChange={handlePhotoChange}
                                                accept="image/*"
                                                ref={fileInputRef}
                                            />
                                        </label>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                    <p className="text-xs/5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                </div>
                            </div>
                        </div>

                        {/* Title and Description */}
                        <div className="sm:col-span-4">
                            <label htmlFor="title" className="block text-sm/6 font-medium text-gray-900">
                                Title
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    id="title"
                                    value={profileData.title}
                                    onChange={(e) => handleInputChange('title', e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="description" className="block text-sm/6 font-medium text-gray-900">
                                Description
                            </label>
                            <div className="mt-2">
                                <textarea
                                    id="description"
                                    rows={3}
                                    value={profileData.description}
                                    onChange={(e) => handleInputChange('description', e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Enhanced Dashboard Settings */}
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base/7 font-semibold text-gray-900">Dashboard Settings</h2>
                    <p className="mt-1 text-sm/6 text-gray-600">
                        Customize your dashboard experience
                    </p>

                    <div className="mt-10 space-y-6">
                        {/* Appearance Settings */}
                        <div>
                            <h3 className="text-sm/6 font-medium text-gray-900 mb-4">Appearance</h3>
                            <div className="space-y-4">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-sm/6 font-medium text-gray-900">Color Scheme</h4>
                                        <p className="text-sm text-gray-500">Choose your preferred color scheme</p>
                                    </div>
                                    <select
                                        value={profileData.settings.colorScheme}
                                        onChange={(e) => handleSettingChange('colorScheme', e.target.value)}
                                        className="rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                                    >
                                        <option value="system">System</option>
                                        <option value="light">Light</option>
                                        <option value="dark">Dark</option>
                                    </select>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-sm/6 font-medium text-gray-900">Compact Mode</h4>
                                        <p className="text-sm text-gray-500">Reduce spacing between elements</p>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleSettingChange('compactMode', !profileData.settings.compactMode)}
                                        className={`${
                                            profileData.settings.compactMode ? 'bg-indigo-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                                    >
                                        <span className={`${
                                            profileData.settings.compactMode ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out mt-1`} />
                                    </button>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-sm/6 font-medium text-gray-900">Menu Collapsed</h4>
                                        <p className="text-sm text-gray-500">Start with collapsed sidebar</p>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleSettingChange('menuCollapsed', !profileData.settings.menuCollapsed)}
                                        className={`${
                                            profileData.settings.menuCollapsed ? 'bg-indigo-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                                    >
                                        <span className={`${
                                            profileData.settings.menuCollapsed ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out mt-1`} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Behavior Settings */}
                        <div>
                            <h3 className="text-sm/6 font-medium text-gray-900 mb-4">Behavior</h3>
                            <div className="space-y-4">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-sm/6 font-medium text-gray-900">Auto Refresh</h4>
                                        <p className="text-sm text-gray-500">Automatically refresh dashboard data</p>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleSettingChange('autoRefresh', !profileData.settings.autoRefresh)}
                                        className={`${
                                            profileData.settings.autoRefresh ? 'bg-indigo-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                                    >
                                        <span className={`${
                                            profileData.settings.autoRefresh ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out mt-1`} />
                                    </button>
                                </div>

                                {profileData.settings.autoRefresh && (
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <h4 className="text-sm/6 font-medium text-gray-900">Refresh Interval</h4>
                                            <p className="text-sm text-gray-500">How often to refresh (minutes)</p>
                                        </div>
                                        <select
                                            value={profileData.settings.refreshInterval}
                                            onChange={(e) => handleSettingChange('refreshInterval', Number(e.target.value))}
                                            className="rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                                        >
                                            <option value={1}>1</option>
                                            <option value={5}>5</option>
                                            <option value={15}>15</option>
                                            <option value={30}>30</option>
                                        </select>
                                    </div>
                                )}

                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-sm/6 font-medium text-gray-900">Show Analytics</h4>
                                        <p className="text-sm text-gray-500">Display analytics widgets</p>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleSettingChange('showAnalytics', !profileData.settings.showAnalytics)}
                                        className={`${
                                            profileData.settings.showAnalytics ? 'bg-indigo-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                                    >
                                        <span className={`${
                                            profileData.settings.showAnalytics ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out mt-1`} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Preferences */}
                        <div>
                            <h3 className="text-sm/6 font-medium text-gray-900 mb-4">Preferences</h3>
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor="defaultView" className="block text-sm/6 font-medium text-gray-900">
                                        Default View
                                    </label>
                                    <select
                                        id="defaultView"
                                        value={profileData.settings.defaultView}
                                        onChange={(e) => handleSettingChange('defaultView', e.target.value)}
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                                    >
                                        <option value="grid">Grid</option>
                                        <option value="list">List</option>
                                        <option value="table">Table</option>
                                    </select>
                                </div>

                                <div>
                                    <label htmlFor="defaultDashboard" className="block text-sm/6 font-medium text-gray-900">
                                        Default Dashboard
                                    </label>
                                    <select
                                        id="defaultDashboard"
                                        value={profileData.settings.defaultDashboard}
                                        onChange={(e) => handleSettingChange('defaultDashboard', e.target.value)}
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                                    >
                                        <option value="main">Main Dashboard</option>
                                        <option value="analytics">Analytics</option>
                                        <option value="performance">Performance</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm/6 font-semibold text-gray-900" onClick={handleCancel}>
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={handleSave}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>
        </div>
    );
};

ProfileView.propTypes = {
    errorMessage: PropTypes.func,
    successMessage: PropTypes.func,
};

export default ProfileView;
