import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

const CoachmarkContext = createContext(null);

// Define coachmarks configuration and their sequence
export const COACHMARKS = {
    SHOW_DEMO: 'show_demo',
    HOME_SEARCH: 'home_search',
    NAV_AUTO_DETECT: 'nav_auto_detect',
    NAV_CHAT: 'nav_chat',
    NAV_PLOT: 'nav_plot',
    SAVED_DETECTS: 'saved_detects',
    SAVED_CHATS: 'saved_chats'
};

// Define the sequence
export const COACHMARK_SEQUENCE = [
    COACHMARKS.SHOW_DEMO,
    COACHMARKS.HOME_SEARCH,
    COACHMARKS.NAV_AUTO_DETECT,
    COACHMARKS.NAV_CHAT,
    COACHMARKS.NAV_PLOT
];

export function CoachmarkProvider({ children }) {
    const [activeCoachmark, setActiveCoachmark] = useState(null);

    // Add keyboard listener for ESC
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Escape' && activeCoachmark) {
                skipCoachmarks();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [activeCoachmark]);

    const showCoachmark = useCallback((coachmarkId) => {
        setActiveCoachmark(coachmarkId);
    }, []);

    const skipCoachmarks = useCallback(() => {
        setActiveCoachmark(null);
    }, []);

    const dismissCoachmark = useCallback((coachmarkId) => {
        const currentIndex = COACHMARK_SEQUENCE.indexOf(coachmarkId);
        if (currentIndex < COACHMARK_SEQUENCE.length - 1) {
            setActiveCoachmark(COACHMARK_SEQUENCE[currentIndex + 1]);
        } else {
            setActiveCoachmark(null);
        }
    }, []);

    const startSequence = useCallback(() => {
        setActiveCoachmark(COACHMARK_SEQUENCE[0]);
    }, []);

    return (
        <CoachmarkContext.Provider value={{
            activeCoachmark,
            showCoachmark,
            dismissCoachmark,
            startSequence,
            skipCoachmarks
        }}>
            {children}
        </CoachmarkContext.Provider>
    );
}

export function useCoachmark() {
    const context = useContext(CoachmarkContext);
    if (!context) {
        throw new Error('useCoachmark must be used within a CoachmarkProvider');
    }
    return context;
} 