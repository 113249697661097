import { useState } from "react";
import { signIn, signOut } from "services/userService";

export function useProvideAuth() {
  const [user, setUser] = useState(null);

  // signin method: It can either return a promise or execute a callback function.
  // You can prefer to keep this in userServices.js
  const signin = (email, password, orgId) => {

    return new Promise((resolve, reject) => {
      try {
        // do db call or API endpoint axios call here and return the promise.
        signIn(email, password, orgId)
          .then((res) => {

            setUser(res);
            resolve(res);
          })
          .catch((err) => {

            setUser({});
            reject(`signin error! ${err}`);
          });
      } catch (error) {
        console.error("signin error!==", error);
        reject("signin error!");
      }
    });
  };
  
  const signout = () => {
    return new Promise((resolve, reject) => {
      try {
        signOut();
        setUser(null);
        resolve(true);
        
      } catch (error) {
        console.error("signout error!==", error);
        reject("signout error!");
      }
    });
  };

  const removeUser = () => {
    return new Promise((resolve, reject) => {
      try {
        setUser(null);
        resolve(true);
        
      } catch (error) {
        console.error("signout error!==", error);
        reject("signout error!");
      }
    });
  };

  return {
    user,
    signin,
    signout,
    removeUser,
  };
}
