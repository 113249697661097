// main router
export const ROOT = "/";
export const LOGIN = "/login";
export const DASHBOARD = "/dashboard";
export const UN_AUTH1 = "/unauthorized1";
export const AUTH_PAGE1 = "/authorized1";

// Home routes
export const PAGE_HOME = "/";  // Base home route
export const PAGE_DASHBOARD_HOME = "/dashboard-home";  // Dashboard home route

// Feature routes
export const PAGE_AUTO_DETECT = "/auto-detect";
export const PAGE_CHAT = "/chat";
export const PAGE_PLOT = "/plot";
export const PAGE_MODEL = "/model";
export const PAGE_SETTINGS = "/settings";
export const PAGE_PROFILE = "/profile";
export const PAGE_INTEGRATIONS = "/integrations";

// login router
export const ROOT_LOGIN = "/";
export const SIGN_UP = "/signup";
export const FORGOT_PASS = "/forgot-pass";