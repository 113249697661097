import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "./ProvideAuth";
import SignIn from "navigation/Auth/components/SignIn";
import DialogComponent from "components/tailwind/DialogComponent";
import SpinnerFullPage from "components/tailwind/SpinnerFullPage";


export const LoginView = (props) => {
  const reSign = props ? props.reSign : false;

  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState(null);

  // Dialog
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState('');

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setLoadingTimer('done');
      }, 5000);
      setLoadingTimer(timer);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  if (reSign) {
    auth.removeUser();
  }

  const handleErrMessage = (message) => {
    setDialogMessage(message);
    setDialogOpen(true);
  }

  let { from } = location.state || { from: { pathname: "/" } };
  let login = (email, password, orgId) => {
    setIsLoading(true);
    setLoadingTimer(null);
    auth.signin(email, password, orgId).then((res) => {
      if (loadingTimer === 'done') {
        history.replace(from);
        window.location.reload(false);
      } else {
        setTimeout(() => {
          history.replace(from);
          window.location.reload(false);
        }, 5000);
      }
    }).catch((err) => {
      handleErrMessage(err);
    });
  };

  return (
    <div>
      {isLoading && (
        <SpinnerFullPage />
      )}
      {!isLoading && (
        <SignIn signin={login} reSign={reSign} handleErrMessage={handleErrMessage} />
      )}
      <DialogComponent
        title='Error'
        subtitle={dialogMessage}
        buttonText='OK'
        action={() => {
          setDialogOpen(false);
          setIsLoading(false);
        }}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </div>
  );
} 
