"use client";
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid2 } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AutoDetectComponent from 'components/AutoDetectComponent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';

dayjs.locale('en-gb');

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AutoDetectView = props => {
    const [currentStep, setCurrentStep] = useState(0);
    const steps = [
        { label: 'Fetching data', duration: 3000 },
        { label: 'Analyzing data', duration: 2000 },
        { label: 'Calculating outliers data', duration: 5000 },
        { label: 'Detecting issues', duration: 8000 },
        { label: 'Preparing report', duration: null }
    ];

    useEffect(() => {
        if (props.showProgress) {
            setCurrentStep(0);
            let timeoutSum = 0;
            
            steps.forEach((step, index) => {
                if (step.duration) {
                    setTimeout(() => {
                        setCurrentStep(index + 1);
                    }, timeoutSum);
                    timeoutSum += step.duration;
                }
            });
        } else {
            setCurrentStep(0);
        }
    }, [props.showProgress]);

    const colors = ['#FF5733', '#33FF57', '#3357FF', '#F5A623', '#9B59B6'];
    const defaultColor = '#EE4B2B';

    const customTheme = createTheme({
        palette: {
            primary: {
                main: '#6366F1',
            },
        },
    });

    return (
        <ThemeProvider theme={customTheme}>
            <div>
                <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                    <Grid2 container spacing={3} justifyContent="center">
                        <Grid2 xs={12}/>

                        {(props.autoDetectData === null && props.autoDetectDataTwo === null && props.showProgress) &&
                            <>
                                <Grid2 item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 2,
                                            width: '100%',
                                            mt: 2
                                        }}
                                    >
                                        {steps.map((step, index) => (
                                            <div key={index} className="flex items-center justify-start gap-3 text-gray-600 w-full">
                                                {index < currentStep ? (
                                                    <CheckCircleIcon className="text-indigo-600" />
                                                ) : index === currentStep ? (
                                                    <CircleIcon className="text-indigo-600 animate-pulse" />
                                                ) : (
                                                    <CircleIcon className="text-gray-300" />
                                                )}
                                                <span className={`${index <= currentStep ? 'text-indigo-600' : 'text-gray-400'}`}>
                                                    {step.label}
                                                </span>
                                            </div>
                                        ))}
                                    </Box>
                                </Grid2>
                            </>
                        }
                        {(props.autoDetectData !== null || props.autoDetectDataTwo != null) &&
                            <Grid2 container spacing={2}>
                                {props.showProgress ? (
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 3 }}>
                                        <div className="flex flex-col items-center gap-4">
                                            {steps.map((step, index) => (
                                                <div key={index} className="flex items-center justify-start gap-3 text-gray-600 w-full">
                                                    {index < currentStep ? (
                                                        <CheckCircleIcon className="text-indigo-600" />
                                                    ) : index === currentStep ? (
                                                        <CircleIcon className="text-indigo-600 animate-pulse" />
                                                    ) : (
                                                        <CircleIcon className="text-gray-300" />
                                                    )}
                                                    <span className={`${index <= currentStep ? 'text-indigo-600' : 'text-gray-400'}`}>
                                                        {step.label}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </Box>
                                ) : (
                                    <>
                                        {props.isSplitView ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    gap: 2,
                                                    width: '100%',
                                                }}
                                            >
                                                <Paper
                                                    elevation={0}
                                                    sx={{
                                                        flex: 1,
                                                        p: 2,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <AutoDetectComponent
                                                        autoDetectData={props.autoDetectData}
                                                        outlierIndexes={props.outlierIndexes}
                                                        xDataSet={props.xDataSet}
                                                    />
                                                </Paper>
                                                <Paper
                                                    elevation={0}
                                                    sx={{
                                                        flex: 1,
                                                        p: 2,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <AutoDetectComponent
                                                        autoDetectData={props.autoDetectDataTwo}
                                                        outlierIndexes={props.outlierIndexesTwo}
                                                        xDataSet={props.xDataSetTwo}
                                                    />
                                                </Paper>
                                            </Box>
                                        ) : (
                                            <Grid2 item xs={12}>
                                                <Paper
                                                    elevation={0}
                                                    sx={{
                                                        p: 2,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <Tabs value={props.tabValue} onChange={props.handleTabChange} aria-label="basic tabs example">
                                                            <Tab
                                                                label="AutoDetect 1st"
                                                                {...a11yProps(0)}
                                                                disabled={props.autoDetectData === null}
                                                            />
                                                            <Tab
                                                                label="AutoDetect 2nd"
                                                                {...a11yProps(1)}
                                                                disabled={props.autoDetectDataTwo === null}
                                                            />
                                                        </Tabs>
                                                    </Box>
                                                    <CustomTabPanel value={props.tabValue} index={0}>
                                                        <AutoDetectComponent
                                                            autoDetectData={props.autoDetectData}
                                                            outlierIndexes={props.outlierIndexes}
                                                            xDataSet={props.xDataSet}
                                                        />
                                                    </CustomTabPanel>
                                                    <CustomTabPanel value={props.tabValue} index={1}>
                                                        <AutoDetectComponent
                                                            autoDetectData={props.autoDetectDataTwo}
                                                            outlierIndexes={props.outlierIndexesTwo}
                                                            xDataSet={props.xDataSetTwo}
                                                        />
                                                    </CustomTabPanel>
                                                </Paper>
                                            </Grid2>
                                        )}
                                    </>
                                )}
                            </Grid2>
                        }
                    </Grid2>
                </Container>
            </div >
        </ThemeProvider>
    )
}

AutoDetectView.propTypes = {
    title: PropTypes.string.isRequired
}

export default AutoDetectView
