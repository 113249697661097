'use client'
import * as React from 'react';
import { useHistory } from "react-router-dom";
import { useState, useRef } from 'react'
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from '@headlessui/react'
import {
    ArrowPathIcon,
    Bars3Icon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
    XMarkIcon,
    ArrowLeftStartOnRectangleIcon,
    Cog6ToothIcon,
    UserCircleIcon,
    ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { PAGE_AUTO_DETECT, PAGE_CHAT, PAGE_HOME, PAGE_MODEL, PAGE_PLOT, PAGE_PROFILE, ROOT } from "navigation/CONSTANTS";
import logo from '../../assets/moveo_one_logo.png';
import { useProvideAuth } from '../../navigation/Auth/useProvideAuth';
import DialogComponent from './DialogComponent';
import { COACHMARKS } from '../../components/Coachmark/CoachmarkProvider';
import { Coachmark } from '../../components/Coachmark/Coachmark';


export default function Example() {
    const history = useHistory();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [selected, setSelected] = React.useState(ROOT);

    const [askLogout, setAskLogout] = React.useState(false);

    const auth = useProvideAuth();

    const isDarkHeader = true;

    const getEnvironmentBadge = () => {
        const baseUrl = window.location.hostname;
        if (baseUrl.includes('localhost')) {
            return 'local';
        } else if (baseUrl.includes('dev-web-api')) {
            return 'development';
        } else if (baseUrl.includes('demo')) {
            return 'demo';
        }
        return null;
    };

    const getHeaderStyles = () => {
        if (isDarkHeader) {
            return {
                headerBg: "absolute inset-0 bg-gradient-to-r from-indigo-500/90 via-indigo-600/90 to-indigo-500/90",
                patternOverlay: "absolute inset-0 bg-[linear-gradient(45deg,rgba(255,255,255,0.05)_25%,transparent_25%,transparent_50%,rgba(255,255,255,0.05)_50%,rgba(255,255,255,0.05)_75%,transparent_75%,transparent)] bg-[length:5px_5px]",
                blur: "absolute inset-0 shadow-sm backdrop-blur-sm bg-white/5",
                linkActive: "text-white bg-white/10 shadow-sm",
                linkInactive: "text-white/90 hover:bg-white/10 hover:text-white",
                accountBtn: "text-white/90 hover:bg-white/10 hover:text-white",
                accountIcon: "text-white/70"
            };
        }
        return {
            headerBg: "absolute inset-0 bg-white/90",
            patternOverlay: "",
            blur: "absolute inset-0 backdrop-blur-sm border-b border-gray-200/20",
            linkActive: "text-indigo-600 bg-indigo-50/80 shadow-sm",
            linkInactive: "text-gray-600 hover:bg-gray-50/70 hover:text-gray-900",
            accountBtn: "text-gray-600 hover:bg-gray-50/70 hover:text-gray-900",
            accountIcon: "text-gray-400"
        };
    };

    const styles = getHeaderStyles();

    const goTo = (path) => {
        if (!['/settings', '/profile'].includes(path)) {
            sessionStorage.setItem('lastDashboardPath', path);
        }
        setSelected(path)
        history.replace(path || ROOT);
    }

    const logout = () => {
        setAskLogout(true)
    }

    const performLogout = () => {
        console.log('logout')
        let { from } = { from: { pathname: "/" } };
        auth.signout();
        history.replace(from);
        window.location.reload(false);
    }

    const openSlackSupport = () => {
        const slackChannelUrl = 'slack://channel?team=T07TM8GS9PC&id=C07TM8GS9PC';
        const slackWebUrl = 'https://slack.com/app_redirect?channel=C07TM8GS9PC';
        
        // Try to open Slack app first
        window.location.href = slackChannelUrl;
        
        // Fallback to web after a short delay
        setTimeout(() => {
            window.location.href = slackWebUrl;
        }, 1000);
    };

    const products = [
        { name: 'Profile', description: 'Manage your profile', href: '/profile', icon: UserCircleIcon },
        { name: 'Integrations', description: 'Connect with external systems', href: '/integrations', icon: SquaresPlusIcon },
        { name: 'Settings', description: 'Manage organization settings', href: '/settings', icon: Cog6ToothIcon },
    ]
    const callsToAction = [
        { name: 'Support', href: '#', icon: ChatBubbleLeftRightIcon, onClick: openSlackSupport },
        { name: 'Logout', href: '#', icon: ArrowLeftStartOnRectangleIcon, onClick: logout },
    ]

    // Add refs for nav items
    const autoDetectRef = useRef(null);
    const chatRef = useRef(null);
    const plotRef = useRef(null);

    const envBadge = getEnvironmentBadge();

    return (
        <header className="fixed top-0 left-0 right-0 z-50">
            <div className={styles.headerBg}></div>
            {styles.patternOverlay && <div className={styles.patternOverlay}></div>}
            <div className={styles.blur}></div>
            <nav aria-label="Global" className="relative mx-auto flex w-full items-center justify-between p-4 lg:px-8">
                <div className="flex lg:flex-1">
                    <div className="relative">
                        <a href="#" className="-m-1.5 p-1.5 rounded-lg hover:bg-gray-50/70 transition duration-200 flex items-center gap-x-3">
                            <span className="sr-only">moveo one</span>
                            <img
                                alt=""
                                src={logo}
                                className="h-8 w-auto"
                            />
                            <span className="text-lg font-medium text-white">moveo one</span>
                        </a>
                        {envBadge && (
                            <div className="absolute -top-2 -right-2 px-2 py-0.5 text-xs font-medium bg-yellow-400 text-yellow-900 rounded-full">
                                {envBadge}
                            </div>
                        )}
                    </div>
                </div>
                
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-600 hover:bg-gray-50/70 hover:text-gray-900 transition duration-200"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="size-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-8">
                    <a 
                        href="#" 
                        className={`text-sm font-semibold px-3 py-2 rounded-md transition duration-200 ${
                            selected === PAGE_HOME 
                                ? styles.linkActive
                                : styles.linkInactive
                        }`} 
                        onClick={() => { goTo(PAGE_HOME) }}
                    >
                        Home
                    </a>
                    <a 
                        ref={autoDetectRef}
                        href="/auto-detect"
                        className={`text-sm font-semibold px-3 py-2 rounded-md transition duration-200 ${
                            selected === PAGE_AUTO_DETECT 
                                ? styles.linkActive
                                : styles.linkInactive
                        }`} 
                        onClick={() => { goTo(PAGE_AUTO_DETECT) }}
                    >
                        Auto Detect
                    </a>
                    <a 
                        ref={chatRef}
                        href="/chat"
                        className={`text-sm font-semibold px-3 py-2 rounded-md transition duration-200 ${
                            selected === PAGE_CHAT 
                                ? styles.linkActive
                                : styles.linkInactive
                        }`} 
                        onClick={() => { goTo(PAGE_CHAT) }}
                    >
                        Chat
                    </a>
                    <a 
                        ref={plotRef}
                        href="/plot"
                        className={`text-sm font-semibold px-3 py-2 rounded-md transition duration-200 ${
                            selected === PAGE_PLOT 
                                ? styles.linkActive
                                : styles.linkInactive
                        }`} 
                        onClick={() => { goTo(PAGE_PLOT) }}
                    >
                        Plot
                    </a>
                    <a 
                        href="#" 
                        className={`text-sm font-semibold px-3 py-2 rounded-md transition duration-200 ${
                            selected === PAGE_MODEL 
                                ? styles.linkActive
                                : styles.linkInactive
                        }`} 
                        onClick={() => { goTo(PAGE_MODEL) }}
                    >
                        Build
                    </a>

                </div>

                <PopoverGroup className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <Popover className="relative">
                        <PopoverButton className={`flex items-center gap-x-1 text-sm font-semibold rounded-md px-3 py-2 transition duration-200 ${styles.accountBtn}`}>
                            Account
                            <ChevronDownIcon className={`size-5 flex-none ${styles.accountIcon}`} aria-hidden="true" />
                        </PopoverButton>

                        <PopoverPanel className="absolute right-0 top-full z-[100] mt-3 w-screen max-w-md overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5">
                            <div className="p-4">
                                {products.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm hover:bg-gray-50 transition duration-150"
                                    >
                                        <div className="flex size-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white transition duration-150">
                                            <item.icon className="size-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                        </div>
                                        <div className="flex-auto">
                                            <a
                                                href={item.href}
                                                className="block font-semibold text-gray-900"
                                                onClick={item.onClick}
                                            >
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </a>
                                            <p className="mt-1 text-gray-600">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                {callsToAction.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold text-gray-900 hover:bg-gray-100 transition duration-150"
                                        onClick={item.onClick}
                                    >
                                        <item.icon className="size-5 flex-none text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </PopoverPanel>
                    </Popover>
                </PopoverGroup>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                alt=""
                                src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                                className="h-8 w-auto"
                            />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                    onClick={() => { goTo(PAGE_HOME) }}
                                >
                                    Home
                                </a>
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                    onClick={() => { goTo(PAGE_AUTO_DETECT) }}
                                >
                                    Auto Detect
                                </a>
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                    onClick={() => { goTo(PAGE_CHAT) }}
                                >
                                    Chat
                                </a>
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                    onClick={() => { goTo(PAGE_PLOT) }}
                                >
                                    Plot
                                </a>

                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                    onClick={() => { goTo(PAGE_MODEL) }}
                                >
                                    Build
                                </a>

                                <Disclosure as="div" className="-mx-3">
                                    <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                                        Account
                                        <ChevronDownIcon aria-hidden="true" className="size-5 flex-none group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 space-y-2">
                                        {[...products, ...callsToAction].map((item) => (
                                            <DisclosureButton
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50"
                                                onClick={item.onClick ? (e) => {
                                                    e.preventDefault(); // Prevent default if there's an `onClick`
                                                    item.onClick(); // Call the `onClick` handler if defined
                                                } : undefined} // Pass `undefined` if no `onClick` is present
                                            >
                                                {item.name}
                                            </DisclosureButton>
                                        ))}
                                    </DisclosurePanel>
                                </Disclosure>
                            </div>
                            {/* <div className="py-6">
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                >
                                    Log in
                                </a>
                            </div> */}
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
            <DialogComponent
                title='Logout?'
                subtitle='You will lose all unsaved work.'
                buttonText='Logout'
                cancelText='Cancel'
                action={performLogout}
                open={askLogout}
                setOpen={setAskLogout}
            />
            <Coachmark
                id={COACHMARKS.NAV_AUTO_DETECT}
                targetRef={autoDetectRef}
                title="Auto Detection"
                description="Automatically detect patterns and anomalies in your data."
                position="bottom"
            />

            <Coachmark
                id={COACHMARKS.NAV_CHAT}
                targetRef={chatRef}
                title="Interactive Chat"
                description="Chat with our AI to analyze and understand your data better."
                position="bottom"
            />

            <Coachmark
                id={COACHMARKS.NAV_PLOT}
                targetRef={plotRef}
                title="Data Visualization"
                description="Create and customize plots to visualize your data effectively."
                position="bottom"
            />
        </header>
    )
}
