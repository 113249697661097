import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { NotFound } from "navigation/NotFound";
import {
  ROOT,
  PAGE_HOME,
  PAGE_DASHBOARD_HOME,
  PAGE_AUTO_DETECT,
  PAGE_CHAT,
  PAGE_PLOT,
  PAGE_MODEL,
  PAGE_PROFILE,
  PAGE_SETTINGS,
  PAGE_INTEGRATIONS
} from "./CONSTANTS";

import PrivateRoute from "./Auth/PrivateRoute";
import { LoginView } from "./Auth/LoginView";
import Home from "pages/Home"
import AutoDetect from "pages/AutoDetect"
import Chat from "pages/Chat";
import Plot from "pages/Plot";
import Model from "pages/Model";
import Profile from "pages/subpages/Profile";
import Settings from '../pages/subpages/Settings';
import Integrations from '../pages/subpages/Integrations';


export const RouterConfig = () => {
  return (
    <div>
      <Switch>
        {/* Public routes */}
        <Route exact path={ROOT}>
          <Redirect to={PAGE_DASHBOARD_HOME} />
        </Route>

        <Route path="/login">
          <LoginView reSign={true} />
        </Route>

        {/* Private/auth routes */}
        <PrivateRoute exact path={PAGE_HOME}>
          <Redirect to={PAGE_DASHBOARD_HOME} />
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_DASHBOARD_HOME}>
          <Home />
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_AUTO_DETECT}>
          <AutoDetect />
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_CHAT}>
          <Chat />
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_PLOT}>
          <Plot />
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_MODEL}>
          <Model />
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_PROFILE}>
          <Profile />
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_SETTINGS}>
          <Settings />
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_INTEGRATIONS}>
          <Integrations />
        </PrivateRoute>

        {/* 404 Route */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
};
