import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const InlinePaddleCheckout = ({ open }) => {
  useEffect(() => {
    if (!window.Paddle) {
      const script = document.createElement('script');
      script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
      script.async = true;
      
      script.onload = () => {
        window.Paddle.Environment.set("production");
        window.Paddle.Initialize({ 
          token: "live_79fe5832ec23d38b478d3d36a04"
        });

        // Open checkout once Paddle is initialized
        if (open) {
          window.Paddle.Checkout.open({
            items: [{
              priceId: "pri_01jfbb1e56b1eqsnf8796hrk7p",
              quantity: 1
            }],
            settings: {
              displayMode: "inline",
              variant: "one-page", 
              frameTarget: "checkout-container",
              frameInitialHeight: "450",
              frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;"
            }
          });
        }
      };

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [open]);

  if (!open) return null;

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-700 to-gray-900 flex justify-center items-center p-8">
      <div className="bg-white bg-opacity-95 rounded-2xl p-8 w-full max-w-[800px] shadow-xl">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">Complete Your Purchase</h1>
          <p className="text-gray-600">You're just a few steps away from accessing Moveo One</p>
        </div>
        <div id="checkout-container"></div>
      </div>
    </div>
  );
};

InlinePaddleCheckout.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default InlinePaddleCheckout;