import React from "react";
import PropTypes from "prop-types";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LineChart, markElementClasses } from '@mui/x-charts/LineChart';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';
import { formatFeatureContent, formatFeatureTitles } from 'utils/stringMappings';
import Box from '@mui/material/Box';

function AutoDetectComponent(props) {
  const { autoDetectData, outlierIndexes, xDataSet } = props;

  return (
    <>
      {autoDetectData && autoDetectData.detected.length > 0 &&
        <Stack sx={{ mt: 2 }} spacing={5}>
          {autoDetectData.detected.map((row, index) => (
            <>
              {index !== 0 &&
                <Divider />
              }
              <Typography variant="h4"
                sx={{
                  textAlign: 'center', // Center the text inside its own box
                }}>
                {formatFeatureTitles(row.feature_name)}
              </Typography>
              {/* <Divider /> */}
              {row.explanations.map((explanation, i) => (
                <Box key={i} display="flex" alignItems="center" mb={1}>
                  {/* Colored square */}

                  {/* The explanation text */}
                  <Stack>
                    <Typography variant="h5">
                      {/* [{i}]  {row.outliers.x[i]} */}
                      {row.outliers.x[i]}
                    </Typography>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-line', ml: 4 }}>
                      {formatFeatureContent(explanation)}
                    </Typography>
                  </Stack>

                </Box>
              ))}


              <Box sx={{ flexGrow: 1, mb: 2 }}>

                <LineChart
                  xAxis={[
                    {
                      scaleType: 'point',
                      dataKey: 'title'
                    },
                  ]}
                  yAxis={[
                    {
                      tickLabelStyle: { display: 'none' }, // Hides the labels on the y-axis
                      ticks: { show: false },  // Hides the ticks on the y-axis
                    },
                  ]}
                  margin={{ top: 25, right: 20, bottom: 80, left: 20 }}
                  dataset={xDataSet[index]}
                  series={[{
                    data: row.feature_values.y,  // Using row.feature_values.y for y-axis values
                    showMark: ({ index: markIndex }) => outlierIndexes[index].includes(markIndex),  // Highlight at index 2
                    color: '#76b7b2',
                  }]}
                  height={400}
                  bottomAxis={{
                    labelStyle: {
                      fontSize: 14,
                      transform: `translateY(${
                        // Hack that should be added in the lib latter.
                        5 * Math.abs(Math.sin((Math.PI * props.angle) / 180))
                        }px)`
                    },
                    tickLabelStyle: {
                      angle: 90,
                      textAnchor: 'start',
                      fontSize: 12,
                    },
                  }}
                  sx={{
                    [`& .${markElementClasses.root}`]: {
                      stroke: '#EE4B2B',
                      scale: '3.6',
                      fill: 'transparent',
                      strokeWidth: 2,
                    },
                  }}
                />
              </Box>
              {(index !== autoDetectData.detected.length - 1) &&
                <>
                  <br />
                  <br />
                </>
              }
            </>
          ))}
        </Stack>
      }

      {(autoDetectData && autoDetectData.detected.length === 0) &&
        <Stack sx={{ mt: 2 }} spacing={5}>
          <Typography variant="body1" component="h2">
            No Autodetected points of interests in selected range.
          </Typography>
          <Typography variant="body1" component="h2">
            Try changing the range or explore data manually in{" "}
            <Link to="/chat" style={{ textDecoration: 'none', color: 'blue' }}>
              Chat section
            </Link>
          </Typography>
        </Stack>
      }

      {(!autoDetectData) &&
        <Stack sx={{ mt: 2 }} spacing={5}>

        </Stack>
      }
    </>
  );
}

AutoDetectComponent.propTypes = {
  autoDetectData: PropTypes.object.isRequired,
  outlierIndexes: PropTypes.array.isRequired,
  xDataSet: PropTypes.array.isRequired,
};

export default AutoDetectComponent;
