import React from 'react';
import { useCoachmark } from './CoachmarkProvider';
import { COACHMARK_SEQUENCE } from './CoachmarkProvider';

export function Coachmark({ 
    id, 
    targetRef, 
    title, 
    description, 
    position = 'bottom'
}) {
    const { activeCoachmark, dismissCoachmark } = useCoachmark();
    const [targetRect, setTargetRect] = React.useState(null);

    const currentStep = COACHMARK_SEQUENCE.indexOf(id) + 1;
    const totalSteps = COACHMARK_SEQUENCE.length;

    React.useEffect(() => {
        if (activeCoachmark === id && targetRef.current) {
            const rect = targetRef.current.getBoundingClientRect();
            setTargetRect(rect);
        }
    }, [activeCoachmark, id, targetRef]);

    if (activeCoachmark !== id || !targetRect) {
        return null;
    }

    const getPosition = () => {
        const offset = 15;
        const tooltipWidth = 300;
        let left = targetRect.left + (targetRect.width / 2) - (tooltipWidth / 2);
        
        // Screen bounds check
        if (left + tooltipWidth > window.innerWidth - 20) {
            left = window.innerWidth - tooltipWidth - 20;
        }
        if (left < 20) {
            left = 20;
        }

        const arrowOffset = targetRect.left + (targetRect.width / 2) - left - 8;

        switch (position) {
            case 'top':
                return {
                    top: targetRect.top - offset - 80,
                    left,
                    arrow: {
                        bottom: '-8px',
                        left: `${arrowOffset}px`
                    }
                };
            case 'bottom':
            default:
                return {
                    top: targetRect.bottom + offset,
                    left,
                    arrow: {
                        top: '-8px',
                        left: `${arrowOffset}px`
                    }
                };
        }
    };

    const pos = getPosition();

    return (
        <>
            {/* Dark overlay */}
            <div 
                className="fixed inset-0 bg-black/50 z-50"
                onClick={() => dismissCoachmark(id)}
            />
            
            {/* Highlight target element */}
            <div 
                className="fixed bg-white/10 ring-2 ring-white/50 z-50"
                style={{
                    top: targetRect.top - 4,
                    left: targetRect.left - 4,
                    width: targetRect.width + 8,
                    height: targetRect.height + 8,
                    borderRadius: '8px'
                }}
            />
            
            {/* Coachmark tooltip */}
            <div 
                className="fixed z-50 w-[300px] bg-white rounded-lg shadow-xl p-4"
                style={{
                    top: pos.top,
                    left: pos.left
                }}
            >
                <div 
                    className="absolute w-4 h-4 bg-white rotate-45"
                    style={pos.arrow}
                />

                <div className="relative flex flex-col gap-2">
                    <div className="flex items-center justify-between mb-2">
                        <div className="flex gap-1">
                            {Array.from({ length: totalSteps }).map((_, index) => (
                                <div
                                    key={index}
                                    className={`h-1 w-4 rounded-full ${
                                        index < currentStep 
                                            ? 'bg-indigo-600' 
                                            : 'bg-gray-200'
                                    }`}
                                />
                            ))}
                        </div>
                        <span className="text-xs text-gray-400">
                            {currentStep}/{totalSteps}
                        </span>
                    </div>

                    <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
                    <p className="text-sm text-gray-600">{description}</p>
                    <div className="mt-4 flex justify-between items-center">
                        <button
                            onClick={() => dismissCoachmark(id)}
                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                        >
                            Got it
                        </button>
                        <span className="text-xs text-gray-400">
                            Press ESC to skip
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
} 