import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { BASE_URL } from './services/CONSTANTS';

// Set title based on environment
const baseUrl = BASE_URL.toLowerCase();
let prefix = '';

if (baseUrl.includes('localhost') || baseUrl.includes('127.0.0.1')) {
  prefix = '(local) ';
} else if (baseUrl.includes('dev-web')) {
  prefix = '(dev) ';
}

document.title = prefix + 'Moveo - Content Service';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);