// All user related database operations can be defined here.
import { SYSTEM_ERROR } from 'config/CONSTANTS';
import axios from 'axios'
import { AUTO_DETECT_URL } from "./CONSTANTS";
import { handleTokenExpiration } from './tokenExpHandler';

/**
 * Get Content.
 */
export const requestAutoDetect = (data) => {
    return new Promise((resolve, reject) => {

        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };

        try {
            // resolve(dummyResponse);
            axios
                .post(AUTO_DETECT_URL(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in autoDetectService > requestAutoDetect, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


const dummyResponse = {
    "detected": [
        {
            "explanations": [
                "The issue with the screen content seems to be related to the feature \"FirstInteractionTime\" being detected as an outlier. This could be due to several specific reasons based on the provided screen content:\n\n1. **Repetitive Image Scroll Events**: The screen contains two image scroll events (`image_scroll_1` and `image_scroll_2`) with identical event values (`eV: '5'`). If these events are triggered too quickly in succession, it might suggest an unusually fast interaction time, which could be flagged as an outlier.\n\n2. **Complexity of Image Content**: The image scroll events are associated with lists of images that include diverse topics such as arts, civil war, philosophy, and more. If users are expected to spend time engaging with these images, a very short interaction time might indicate that users are not actually viewing the content, leading to the detection of an outlier.\n\n3. **Text Content Engagement**: The text content \"Welcome are you ready to borden your mind\" is intended to engage users. If the interaction time is too short, it might suggest that users are not reading or engaging with the text, which could be another reason for the outlier detection.\n\n4. **Potential Typo in Text**: There is a typo in the text content (\"borden\" instead of \"broaden\"), which might affect user engagement. If users are confused or disengaged due to this typo, it could impact the interaction time.\n\nOverall, the outlier detection for \"FirstInteractionTime\" might be due to users interacting with the screen content much faster than expected, possibly skipping over the images and text without proper engagement.",
                "The screen content provided includes several elements related to a topic about Frida Kahlo, specifically titled \"The Shattered Woman Remade by Art.\" The elements include text views displaying a question about interest in the topic, the name \"Fida Kahlo\" (which appears to be a typo for \"Frida Kahlo\"), a percentage indicating interest or completion (92%), a learner count (22K learners), and an image labeled \"fida.\"\n\nThe issue identified by auto detection is with the feature \"FirstInteractionTime,\" which is considered an outlier. This suggests that the time it takes for users to first interact with this screen is significantly different from expected norms. Possible reasons for this could include:\n\n1. **Typographical Error**: The name \"Fida Kahlo\" is likely a typo for \"Frida Kahlo.\" This could cause confusion or hesitation, leading to delayed interaction as users try to understand the content.\n\n2. **Content Clarity**: The question \"Does this topic interest you?\" might not be clear or engaging enough, causing users to pause before interacting.\n\n3. **Visual Engagement**: The image labeled \"fida\" might not be visually compelling or relevant, affecting user engagement and interaction time.\n\n4. **Information Overload**: The screen presents multiple pieces of information (interest percentage, learner count) that might overwhelm users, causing them to take longer to process the content before interacting.\n\nAddressing these specific issues could help normalize the \"FirstInteractionTime\" by making the screen content clearer, more accurate, and engaging for users.",
                "The issue with the screen content seems to be related to the feature \"FirstInteractionTime\" being detected as an outlier. This suggests that the time it takes for users to first interact with this screen is significantly different from what is expected or from other screens. \n\nLooking at the content of the screen, it includes two text elements and one image element:\n\n1. **Text Element 1**: \"Let's start expanding your mind!\"\n2. **Text Element 2**: \"Become a more interesting person by listening for just 10 min per day\"\n3. **Image Element**: An image with the identifier 'lets_start'\n\nPossible issues could include:\n\n- **Lack of Clear Call-to-Action**: The screen does not seem to have a clear call-to-action (CTA) that prompts the user to interact. Both text elements are informative but do not guide the user on what to do next. This could lead to users spending more time figuring out how to proceed, thus affecting the FirstInteractionTime.\n\n- **Engagement with Content**: The content might not be engaging enough to prompt immediate interaction. If users are not compelled by the text or image, they might hesitate or delay interacting with the screen.\n\n- **Image Relevance**: The image labeled 'lets_start' might not be effectively communicating its purpose or might not be visually engaging enough to prompt interaction.\n\n- **Screen Layout and Design**: If the layout is not intuitive or if the interactive elements are not easily identifiable, users might take longer to interact.\n\nTo address this, consider adding a clear and prominent CTA, ensuring the image and text are engaging and relevant, and reviewing the screen layout for intuitive design.",
                "The screen content suggests that the user is being welcomed and prompted to personalize their experience with Yuno through a series of questions. The outlier detection for the feature \"FirstInteractionTime\" could indicate that users are taking an unusually long or short time to interact with this screen. This could be due to several specific issues:\n\n1. **Complexity or Length of Text**: The text on the screen might be too lengthy or complex, causing users to spend more time reading and understanding it before interacting.\n\n2. **Lack of Clear Call to Action**: The screen does not explicitly instruct the user on what to do next, which might lead to hesitation or confusion, resulting in delayed interaction.\n\n3. **Engagement with Content**: The welcoming message and personalization prompt might not be engaging enough, causing users to either skip through quickly or pause to decide if they want to proceed.\n\n4. **Visual Hierarchy**: The arrangement of text and images might not effectively guide the user's attention to the next step, leading to longer decision times.\n\n5. **Technical Issues**: There could be a technical delay in loading the screen or registering the user's first interaction, affecting the recorded interaction time.\n\nAddressing these specific issues could help normalize the \"FirstInteractionTime\" and improve user experience on this screen."
            ],
            "feature_name": "FirstInteractionTime",
            "feature_values": {
                "x": [
                    "screen_0",
                    "screen_1",
                    "screen_2",
                    "screen_5",
                    "screen_6",
                    "screen_7",
                    "screen_8",
                    "screen_9",
                    "screen_10",
                    "screen_11",
                    "screen_12",
                    "screen_13",
                    "screen_14",
                    "screen_15",
                    "screen_16",
                    "screen_17",
                    "screen_18",
                    "screen_19",
                    "screen_20",
                    "screen_21",
                    "screen_22",
                    "screen_23",
                    "screen_24",
                    "screen_25"
                ],
                "y": [
                    3.6734999418258667,
                    1.8009999990463257,
                    1.0735000371932983,
                    2.272499918937683,
                    1.4605000019073486,
                    1.1705000400543213,
                    1.1735000610351562,
                    1.5814999341964722,
                    1.7934999465942383,
                    1.2029999494552612,
                    1.5490000247955322,
                    1.0064998865127563,
                    1.6770000457763672,
                    1.4735000133514404,
                    1.3144999742507935,
                    1.9160001277923584,
                    1.4189999103546143,
                    1.5735000371932983,
                    1.6720000505447388,
                    1.403999924659729,
                    1.4980000257492065,
                    1.5609999895095825,
                    1.5605000257492065,
                    1.6074998378753662
                ]
            },
            "outliers": {
                "x": [
                    "screen_0",
                    "screen_13",
                    "screen_2",
                    "screen_5"
                ],
                "y": [
                    3.6734999418258667,
                    1.0064998865127563,
                    1.0735000371932983,
                    2.272499918937683
                ]
            }
        },
        {
            "explanations": [
                "The screen content consists of a text view, a selection list, and an image. The outlier detection has flagged the feature \"InteractionsPerElementSelectList_item\" as an outlier, which suggests that there might be an unusual pattern in how users are interacting with the selection list on this screen. \n\nThe selection list contains multiple options: \"To develop new interests,\" \"To get better at smalltalk,\" \"To boost my career,\" \"To better use my free time,\" \"Just for fun,\" and \"Other.\" The issue could be that users are not interacting with this list as expected. Possible reasons for this could include:\n\n1. **Overwhelming Choices**: The list might have too many options, causing users to feel overwhelmed or unsure about which option to select.\n\n2. **Irrelevant Options**: The options provided might not resonate with the users' actual reasons for wanting to improve their general knowledge, leading to fewer interactions.\n\n3. **Unclear Purpose**: The question \"Why do you want to improve your general knowledge?\" might not be clear or engaging enough, resulting in users skipping the selection process.\n\n4. **UI/UX Design Issues**: There might be a design issue that makes the selection list less visible or harder to interact with, such as small font size, poor contrast, or placement on the screen.\n\n5. **Technical Glitch**: There could be a technical issue preventing users from selecting an option, such as a non-responsive list or a bug in the code.\n\nTo address this, it would be beneficial to conduct user testing or gather user feedback to understand the specific reasons behind the low interaction rate with the selection list."
            ],
            "feature_name": "InteractionsPerElementSelectList_item",
            "feature_values": {
                "x": [
                    "screen_6",
                    "screen_7",
                    "screen_8",
                    "screen_9",
                    "screen_10"
                ],
                "y": [
                    1.0,
                    1.0,
                    2.0,
                    3.5,
                    1.0
                ]
            },
            "outliers": {
                "x": [
                    "screen_9"
                ],
                "y": [
                    3.5
                ]
            }
        },
        {
            "explanations": [
                "The screen content indicates that the user interaction involves a tab bar with an event type of \"view\" and an event value of \"4\" on \"screen_1.\" The feature identified as an outlier is \"TimePerCharacter,\" which suggests that the time taken per character input is unusually high or low compared to expected norms. However, the screen content provided does not include any text input fields or character-based interactions, as it only involves a tab bar interaction. This discrepancy could indicate a potential issue with the data collection or labeling process, where the \"TimePerCharacter\" metric is being incorrectly applied or calculated for a screen that does not involve character input. It may be necessary to review the data collection logic to ensure that \"TimePerCharacter\" is only calculated for screens where text input is relevant."
            ],
            "feature_name": "TimePerCharacter",
            "feature_values": {
                "x": [
                    "screen_1",
                    "screen_3",
                    "screen_4"
                ],
                "y": [
                    0.2251249998807907,
                    0.0014999806880950928,
                    0.0013750046491622925
                ]
            },
            "outliers": {
                "x": [
                    "screen_1"
                ],
                "y": [
                    0.2251249998807907
                ]
            }
        }
    ]
}