import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { Cog6ToothIcon, BellIcon, GlobeAltIcon, UsersIcon, MagnifyingGlassIcon, CreditCardIcon, CheckCircleIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { getPaddleInstance, initializePaddle } from '@paddle/paddle-js';
import InlinePaddleCheckout from 'components/payments/InlinePaddleCheckout';
import { DEMO_MODE } from 'services/CONSTANTS';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

const SettingsView = props => {
    const history = useHistory();
    const [settings, setSettings] = useState({
        notifications: {
            email: true,
            push: true,
            desktop: false,
            sound: true,
            frequency: 'immediate'
        },
        localization: {
            language: 'en',
            timezone: 'UTC',
            dateFormat: 'MM/DD/YYYY',
            timeFormat: '24h'
        },
        privacy: {
            shareData: false,
            collectAnalytics: true,
            showProfilePublic: false
        },
        accessibility: {
            highContrast: false,
            largeText: false,
            reduceMotion: false
        }
    });

    // useEffect(() => {
    //     // Initialize Paddle with seller ID and environment
    //     initializePaddle({ 
    //         seller: 25678, // Replace with your actual seller ID
    //         vendor: 25678, // Replace with your actual seller ID
    //         token: 'test_313dcae682e1cb5a4a4f7e706ba',
    //         environment: 'sandbox' // or 'production' for live
    //     });
    // }, []);

    // Create a local state to store Paddle instance
    const [paddle, setPaddle] = useState(null);

    // Download and initialize Paddle instance from CDN
    // useEffect(() => {
    //     initializePaddle({ environment: 'production', token: 'live_79fe5832ec23d38b478d3d36a04' }).then(
    //         (paddleInstance) => {
    //             if (paddleInstance) {
    //                 setPaddle(paddleInstance);
    //             }
    //         },
    //     );
    // }, []);

    const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);

    const [showDemoMessage, setShowDemoMessage] = React.useState(DEMO_MODE() === 'true')

    const handleOpenCheckout = () => {
        setIsCheckoutOpen(true); // Trigger inline Paddle checkout
    };


    useEffect(() => {
        // Load Paddle script dynamically
        const script = document.createElement('script');
        script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
        script.async = true;

        script.onload = () => {
            if (window.Paddle) {
                // Initialize Paddle
                window.Paddle.Environment.set("production"); // or 'sandbox' for testing
                window.Paddle.Initialize({
                    token: "live_79fe5832ec23d38b478d3d36a04", // Replace with your client-side token
                });
            }
        };

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);


    const itemsList = [
        {
            priceId: "pri_01jfbb1e56b1eqsnf8796hrk7p", // Replace with your Paddle price ID
            quantity: 1,
        },
    ];

    // Open Paddle Checkout
    const openCheckoutHybrid = () => {
        if (DEMO_MODE() !== 'true') {
            if (window.Paddle) {
                window.Paddle.Checkout.open({
                    items: itemsList,
                });
            } else {
                console.error('Paddle is not loaded');
            }
        }
    };

    // // Callback to open a checkout
    // const openCheckout = () => {
    //     paddle?.Checkout.open({
    //         items: [{ priceId: 'pri_01jfbb1e56b1eqsnf8796hrk7p', quantity: 1 }],
    //     });
    // };


    // Dummy users data 
    const [users, setUsers] = useState([
        {
            id: 1,
            name: 'John Doe',
            email: 'john@example.com',
            role: 'admin',
            permissions: {
                canManageUsers: true,
                canViewAnalytics: true,
                canModifySettings: true,
                canAccessApi: true
            }
        },
        {
            id: 2,
            name: 'Jane Smith',
            email: 'jane@example.com',
            role: 'manager',
            permissions: {
                canManageUsers: false,
                canViewAnalytics: true,
                canModifySettings: true,
                canAccessApi: false
            }
        },
        {
            id: 3,
            name: 'Bob Wilson',
            email: 'bob@example.com',
            role: 'user',
            permissions: {
                canManageUsers: false,
                canViewAnalytics: true,
                canModifySettings: false,
                canAccessApi: false
            }
        }
    ]);

    const [searchQuery, setSearchQuery] = useState('');

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.role.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleCancel = () => {
        const previousPath = sessionStorage.getItem('lastDashboardPath') || '/home';
        history.push(previousPath);
    };

    const handleSave = async () => {
        try {
            // API call placeholder
            // await updateSettings(settings);
            props.successMessage?.('Settings updated successfully');
        } catch (error) {
            props.errorMessage?.(error.message);
        }
    };

    const handleSettingChange = (category, setting, value) => {
        setSettings(prev => ({
            ...prev,
            [category]: {
                ...prev[category],
                [setting]: value
            }
        }));
    };

    const handleUserPermissionChange = (userId, permission, value) => {
        setUsers(prev => prev.map(user => {
            if (user.id === userId) {
                return {
                    ...user,
                    permissions: {
                        ...user.permissions,
                        [permission]: value
                    }
                };
            }
            return user;
        }));
    };

    // Subscription data structure
    const [subscription, setSubscription] = useState({
        status: 'trial',
        plan: 'demo',
        trialEndsAt: new Date(new Date().setDate(new Date().getDate() + 14)),
        features: {
            used: 150,
            total: 200
        }
    });

    // Pricing plans data
    const pricingPlans = [
        {
            name: 'Starter',
            callsign: 'starter',
            subtitle: 'The perfect plan to try it out and for small teams or individuals.',
            price: '50',
            features: [
                '100 Auto detect requests',
                '500 chat messages',
                '1 user per organization',
                '1 project',
                'self onboarding',
                '14 days free trial'
            ]
        },
        {
            name: 'Startup',
            callsign: 'strtup',
            subtitle: 'The most popular plan for startups and scalups.',
            price: '200',
            features: [
                '2000 Auto detect requests',
                '1000 chat messages',
                'unlimited user per organization',
                'up to 5 projects',
                'dedicated onboarding',
                'Slack access'
            ]
        },
        {
            name: 'Enterprise',
            callsign: 'enterprise',
            price: '600+',
            subtitle: 'If you are one, you know why.',
            features: [
                'nearly unlimited Auto detect requests',
                'nearly unlimited chat messages',
                'unlimited user per organization',
                'unlimited projects',
                'guided setup with experts',
                'optional: self hosted',
                'phone support'
            ]
        }
    ];

    const handleUpgradeClick = (planName) => {
        // Open Paddle checkout with product ID and seller ID
        getPaddleInstance('v1').Checkout.open({
            items: [{
                priceId: 'pri_01jfbee74qnvn27zqjfatv8dks',
                quantity: 1
            }],
            seller: 25678, // Replace with your actual seller ID
            passthrough: JSON.stringify({ userId: '25678' }), // Optional: Custom data
            successCallback: (data) => {
                console.log('Payment successful:', data);
                // Handle successful payment
            },
            closeCallback: () => {
                console.log('Checkout closed');
                // Handle checkout close
            }
        });
    };

    return (
        <div className="space-y-12 max-w-5xl mx-auto">
            {showDemoMessage && (
                <div className="rounded-md bg-blue-50 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                                Demo mode is active. In demo mode, this is only UI preview of the settings.
                            </p>
                            <button
                                type="button"
                                className="ml-3 flex-shrink-0"
                                onClick={() => setShowDemoMessage(false)}
                            >
                                <XMarkIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm/6 font-semibold text-gray-900" onClick={handleCancel}>
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={handleSave}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>

            {/* Notifications Section */}
            <div className="border-b border-gray-900/10 pb-12">
                <div className="flex items-center gap-x-3">
                    <BellIcon className="h-6 w-6 text-gray-600" />
                    <h2 className="text-base/7 font-semibold text-gray-900">Notifications</h2>
                </div>
                <p className="mt-1 text-sm/6 text-gray-600">
                    Manage how you receive notifications.
                </p>

                <div className="mt-10 space-y-6">
                    <div className="flex items-center justify-between">
                        <div>
                            <h4 className="text-sm/6 font-medium text-gray-900">Email Notifications</h4>
                            <p className="text-sm text-gray-500">Receive notifications via email</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => handleSettingChange('notifications', 'email', !settings.notifications.email)}
                            className={`${settings.notifications.email ? 'bg-indigo-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                        >
                            <span className={`${settings.notifications.email ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out mt-1`} />
                        </button>
                    </div>

                    <div>
                        <label htmlFor="notificationFrequency" className="block text-sm/6 font-medium text-gray-900">
                            Notification Frequency
                        </label>
                        <select
                            id="notificationFrequency"
                            value={settings.notifications.frequency}
                            onChange={(e) => handleSettingChange('notifications', 'frequency', e.target.value)}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                        >
                            <option value="immediate">Immediate</option>
                            <option value="hourly">Hourly Digest</option>
                            <option value="daily">Daily Digest</option>
                            <option value="weekly">Weekly Digest</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Localization Section */}
            <div className="border-b border-gray-900/10 pb-12">
                <div className="flex items-center gap-x-3">
                    <GlobeAltIcon className="h-6 w-6 text-gray-600" />
                    <h2 className="text-base/7 font-semibold text-gray-900">Localization</h2>
                </div>
                <p className="mt-1 text-sm/6 text-gray-600">
                    Customize your regional preferences.
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <label htmlFor="language" className="block text-sm/6 font-medium text-gray-900">
                            Language
                        </label>
                        <select
                            id="language"
                            value={settings.localization.language}
                            onChange={(e) => handleSettingChange('localization', 'language', e.target.value)}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                        >
                            <option value="en">English</option>
                            <option value="es">Español</option>
                            <option value="fr">Français</option>
                            <option value="de">Deutsch</option>
                        </select>
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="timeFormat" className="block text-sm/6 font-medium text-gray-900">
                            Time Format
                        </label>
                        <select
                            id="timeFormat"
                            value={settings.localization.timeFormat}
                            onChange={(e) => handleSettingChange('localization', 'timeFormat', e.target.value)}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                        >
                            <option value="12h">12-hour</option>
                            <option value="24h">24-hour</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Accessibility Section */}
            <div className="border-b border-gray-900/10 pb-12">
                <div className="flex items-center gap-x-3">
                    <Cog6ToothIcon className="h-6 w-6 text-gray-600" />
                    <h2 className="text-base/7 font-semibold text-gray-900">Accessibility</h2>
                </div>
                <p className="mt-1 text-sm/6 text-gray-600">
                    Customize your accessibility preferences.
                </p>

                <div className="mt-10 space-y-6">
                    <div className="flex items-center justify-between">
                        <div>
                            <h4 className="text-sm/6 font-medium text-gray-900">High Contrast</h4>
                            <p className="text-sm text-gray-500">Increase contrast for better visibility</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => handleSettingChange('accessibility', 'highContrast', !settings.accessibility.highContrast)}
                            className={`${settings.accessibility.highContrast ? 'bg-indigo-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                        >
                            <span className={`${settings.accessibility.highContrast ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out mt-1`} />
                        </button>
                    </div>

                    <div className="flex items-center justify-between">
                        <div>
                            <h4 className="text-sm/6 font-medium text-gray-900">Reduce Motion</h4>
                            <p className="text-sm text-gray-500">Minimize animations and transitions</p>
                        </div>
                        <button
                            type="button"
                            onClick={() => handleSettingChange('accessibility', 'reduceMotion', !settings.accessibility.reduceMotion)}
                            className={`${settings.accessibility.reduceMotion ? 'bg-indigo-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                        >
                            <span className={`${settings.accessibility.reduceMotion ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out mt-1`} />
                        </button>
                    </div>
                </div>
            </div>

            {/* User Management Section */}
            <div className="pb-12">
                <div className="flex items-center gap-x-3">
                    <UsersIcon className="h-6 w-6 text-gray-600" />
                    <h2 className="text-base/7 font-semibold text-gray-900">User Management</h2>
                </div>
                <p className="mt-1 text-sm/6 text-gray-600">
                    Manage organization users and their permissions.
                </p>

                <div className="mt-10">
                    <div className="mb-6">
                        <div className="relative">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search users by name, email, or role..."
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                        </div>
                    </div>
                    <div className="overflow-x-auto ring-1 ring-gray-300 rounded-lg">
                        <div className="min-w-full">
                            <div className="bg-gray-50">
                                <div className="grid grid-cols-4 divide-x divide-gray-300">
                                    <div className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Name</div>
                                    <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</div>
                                    <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</div>
                                    <div className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Permissions</div>
                                </div>
                            </div>
                            <div className="divide-y divide-gray-200 bg-white h-[400px] overflow-y-auto">
                                {filteredUsers.map(user => (
                                    <div key={user.id} className="grid grid-cols-4 divide-x divide-gray-300">
                                        <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                                            {user.name}
                                        </div>
                                        <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.email}</div>
                                        <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${user.role === 'admin' ? 'bg-red-100 text-red-800' :
                                                user.role === 'manager' ? 'bg-yellow-100 text-yellow-800' :
                                                    'bg-green-100 text-green-800'
                                                }`}>
                                                {user.role}
                                            </span>
                                        </div>
                                        <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <div className="space-y-2">
                                                {Object.entries(user.permissions).map(([permission, value]) => (
                                                    <div key={permission} className="flex items-center gap-x-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={value}
                                                            onChange={(e) => handleUserPermissionChange(user.id, permission, e.target.checked)}
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                        <span className="text-sm text-gray-900">
                                                            {permission.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Subscription Section */}
            <div className="border-b border-gray-900/10 pb-12">
                <div className="flex items-center gap-x-3">
                    <CreditCardIcon className="h-6 w-6 text-gray-600" />
                    <h2 className="text-base/7 font-semibold text-gray-900">Subscription</h2>
                </div>
                <p className="mt-1 text-sm/6 text-gray-600">
                    Manage your subscription and billing
                </p>

                {/* Current Plan Status */}
                <div className="mt-6 bg-gray-50 rounded-lg p-6">
                    <div className="flex items-center justify-between">
                        <div>
                            <h3 className="text-lg font-semibold text-gray-900">
                                Current Plan: {subscription.plan === 'free' ? 'Free Trial' : subscription.plan}
                            </h3>
                            {subscription.status === 'trial' && (
                                <div className="mt-2 flex items-center text-sm text-gray-600">
                                    <SparklesIcon className="h-5 w-5 text-yellow-400 mr-2" />
                                    <span>Trial ends in {Math.ceil((subscription.trialEndsAt - new Date()) / (1000 * 60 * 60 * 24))} days</span>
                                </div>
                            )}
                            {subscription.plan === 'starter' && (
                                <div className="mt-2 flex items-center text-sm text-gray-600">
                                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                                    <span>Basic features enabled</span>
                                </div>
                            )}
                            {subscription.plan === 'startup' && (
                                <div className="mt-2 flex items-center text-sm text-gray-600">
                                    <SparklesIcon className="h-5 w-5 text-purple-500 mr-2" />
                                    <span>Advanced features enabled</span>
                                </div>
                            )}
                            {subscription.plan === 'enterprise' && (
                                <div className="mt-2 flex items-center text-sm text-gray-600">
                                    <CreditCardIcon className="h-5 w-5 text-blue-600 mr-2" />
                                    <span>Full enterprise features</span>
                                </div>
                            )}
                            {subscription.plan === 'demo' && (
                                <div className="mt-2 flex items-center text-sm text-gray-600">
                                    <SparklesIcon className="h-5 w-5 text-orange-500 mr-2" />
                                    <span>Demo features enabled</span>
                                </div>
                            )}
                        </div>
                        <div className="text-right">
                            <div className="text-sm text-gray-600">Usage</div>
                            {subscription.plan !== 'enterprise' ? (
                                <>
                                    <div className="text-2xl font-bold text-gray-900">
                                        {subscription.features.used}/{subscription.features.total}
                                    </div>
                                    <div className="text-sm text-gray-500">videos</div>
                                </>
                            ) : (
                                <div className="text-xl font-semibold text-gray-900">Unlimited</div>
                            )}
                        </div>
                    </div>
                    {subscription.plan !== 'enterprise' && (
                        <div className="mt-4 w-full bg-gray-200 rounded-full h-2.5">
                            <div
                                className={`h-2.5 rounded-full ${subscription.plan === 'free' ? 'bg-indigo-600' :
                                    subscription.plan === 'starter' ? 'bg-green-500' :
                                        subscription.plan === 'demo' ? 'bg-orange-500' :
                                            'bg-purple-500'
                                    }`}
                                style={{ width: `${(subscription.features.used / subscription.features.total) * 100}%` }}
                            ></div>
                        </div>
                    )}
                </div>

                {/* Manage Subscription Button */}
                {/* <div className="mt-6 flex justify-center">
                    <a
                        href="https://www.moveo.one/payments.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
                    >
                        <CreditCardIcon className="h-5 w-5 mr-2" />
                        Manage Subscription Details
                    </a>
                </div> */}

                {/* Pricing Plans */}
                <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-3">
                    {pricingPlans.map((plan) => (
                        <div
                            key={plan.name}
                            className={`rounded-lg border ${subscription.plan === plan.name.toLowerCase()
                                ? 'border-indigo-500 ring-2 ring-indigo-500'
                                : 'border-gray-200'
                                } bg-white p-6 shadow-sm flex flex-col relative`}
                        >
                            {subscription.plan === plan.callsign.toLowerCase() && (
                                <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                                    <span className="bg-indigo-500 text-white text-xs font-semibold px-3 py-1 rounded-full">
                                        Current Plan
                                    </span>
                                </div>
                            )}
                            <h3 className="text-lg font-semibold text-gray-900">{plan.name}</h3>
                            <p className="mt-4">
                                <span className="text-4xl font-bold tracking-tight text-gray-900">${plan.price}</span>
                                {plan.price !== 'Custom' && <span className="text-sm text-gray-500">/month</span>}
                            </p>
                            <p className="mt-2 text-sm text-gray-500">{plan.subtitle}</p>
                            <ul className="mt-6 space-y-4 flex-grow">
                                {plan.features.map((feature) => (
                                    <li key={feature} className="flex items-start">
                                        <CheckCircleIcon className="h-5 w-5 text-green-500 flex-shrink-0" />
                                        <span className="ml-3 text-sm text-gray-500">{feature}</span>
                                    </li>
                                ))}
                            </ul>
                            <button
                                onClick={() => {
                                    if (DEMO_MODE() === 'true') {
                                        window.location.href = 'mailto:sales@moveo.one';
                                    } else {
                                        openCheckoutHybrid();
                                    }
                                }}
                                className={`mt-8 w-full rounded-md px-3.5 py-2 text-sm font-semibold shadow-sm 
                                    ${subscription.plan === plan.name.toLowerCase()
                                        ? 'bg-indigo-100 text-indigo-700 border-2 border-indigo-500'
                                        : 'bg-indigo-600 text-white hover:bg-indigo-500'}`}
                                disabled={subscription.plan === plan.name.toLowerCase()}
                            >
                                {subscription.plan === plan.name.toLowerCase()
                                    ? 'Current Plan'
                                    : DEMO_MODE() === 'true'
                                        ? 'While on demo - Contact Sales'
                                        : plan.name === 'Enterprise'
                                            ? 'Contact Sales'
                                            : 'Upgrade'}
                            </button>
                        </div>
                    ))}
                </div>

                <InlinePaddleCheckout open={isCheckoutOpen} />
                {/* Billing History */}
                <div className="mt-10">
                    <h4 className="text-sm font-medium text-gray-900">Billing history</h4>
                    <div className="mt-4 divide-y divide-gray-100">
                        <p className="text-sm text-gray-500 py-4">No billing history available during trial period.</p>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm/6 font-semibold text-gray-900" onClick={handleCancel}>
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={handleSave}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>
        </div>
    );
};

SettingsView.propTypes = {
    errorMessage: PropTypes.func,
    successMessage: PropTypes.func,
};

export default SettingsView;