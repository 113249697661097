// All user related database operations can be defined here.
import { SYSTEM_ERROR } from 'config/CONSTANTS';
import axios from 'axios'
import { END_CHAT_URL, FETCH_MESSAGES_URL, FETCH_SEMANTIC_GROUP_URL, FETCH_SESSION_METADATA, POST_MESSAGE_URL, START_CHAT_URL } from "./CONSTANTS";
import { handleTokenExpiration } from './tokenExpHandler';

/**
 * Get Content.
 */
export const requestStartChat = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };
        try {
            axios
                .post(START_CHAT_URL(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in chatService > requestStartChat, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


/**
 * Get Content.
 */
export const requestSemanticGroups = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };
        try {
            axios
                .post(FETCH_SEMANTIC_GROUP_URL(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in chatService > requestSemanticGroups, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const requestChatMessages = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };
        try {
            axios
                .post(FETCH_MESSAGES_URL(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in chatService > requestChatMessages, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const postUserMessage = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };
        try {
            axios
                .post(POST_MESSAGE_URL(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in chatService > requestChatMessages, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const requestEndChat = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };
        try {
            axios
                .post(END_CHAT_URL(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in chatService > endChat, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const requestSessionMetadata = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };
        try {
            axios
                .post(FETCH_SESSION_METADATA(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in chatService > requestSessionMetadata, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};
