// All user related database operations can be defined here.
import { SYSTEM_ERROR } from 'config/CONSTANTS';
import axios from 'axios'
import { FETCH_AUTO_DETECT_DATA_STATISTIC, FETCH_AUTO_DETECT_LOG, FETCH_CHAT_LOG, FETCH_EVENT_COUNT, FETCH_METADATA_STATISTIC, FETCH_SESSION_AVG_TIME, FETCH_SESSION_COUNT } from "./CONSTANTS";
import { handleTokenExpiration } from './tokenExpHandler';

/**
 * Get Auto Detect Log.
 */
export const requestAutoDetectLog = () => {
    return new Promise((resolve, reject) => {

        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };

        try {
            axios
                .get(FETCH_AUTO_DETECT_LOG(), config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in homeStatisticService > requestAutoDetectLog, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const requestChatLog = () => {
    return new Promise((resolve, reject) => {

        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };

        try {
            axios
                .get(FETCH_CHAT_LOG(), config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in homeStatisticService > requestChatLog, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const requestMetadataStatistic = () => {
    return new Promise((resolve, reject) => {

        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };

        try {
            axios
                .get(FETCH_METADATA_STATISTIC(), config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in homeStatisticService > requestMetadataStatistic, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const requestAutoDetectDataStatistic = (data) => {
    return new Promise((resolve, reject) => {

        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };

        try {
            axios
                .post(FETCH_AUTO_DETECT_DATA_STATISTIC(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in homeStatisticService > requestAutoDetectDataStatistic, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const requestSessionAvgTime = (data) => {
    return new Promise((resolve, reject) => {

        const config = {
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };

        try {
            axios
                .post(FETCH_SESSION_AVG_TIME(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in homeStatisticService > requestSessionAvgTime, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const requestSessionCount = (data) => {
    return new Promise((resolve, reject) => {
        const config = {        
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };

        try {
            axios
                .post(FETCH_SESSION_COUNT(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in homeStatisticService > requestSessionCount, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};  


export const requestEventCount = (data) => {
    return new Promise((resolve, reject) => {
        const config = {        
            headers: {
                Authorization: sessionStorage.getItem('authToken'),
                'X-User-Token': sessionStorage.getItem('userToken')
            }
        };

        try {
            axios
                .post(FETCH_EVENT_COUNT(), data, config)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    handleTokenExpiration(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in homeStatisticService > requestEventCount, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};      