'use client'

import React, { useEffect, useState, useRef } from 'react';
import { Datepicker } from "flowbite-react";
import { useLocation } from 'react-router-dom';

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { FunnelIcon, MinusIcon, PlusIcon, ShareIcon } from '@heroicons/react/20/solid'

import PropTypes from 'prop-types'

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale for DD/MM/YYYY format
import { postUserMessage, requestChatMessages, requestEndChat, requestSemanticGroups, requestSessionMetadata, requestStartChat } from "services/chatService";
import ChatView from "./ChatView";
import SelectComponentTailwind from 'components/tailwind/SelectComponent';
import DialogComponent from 'components/tailwind/DialogComponent';
import { mockFilters, mockFiltersChat } from 'mock/moceker';
import { DEMO_MODE } from 'services/CONSTANTS';
import { ArrowLeftIcon } from '@mui/x-date-pickers';
dayjs.locale('en-gb'); // Set Day.js locale to 'en-gb'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ChatWrapper = props => {
    const location = useLocation();
    const chatData = location.state?.chatData;

    const [sessionsMetadata, setSessionsMetadata] = React.useState({})
    const [filters, setFilters] = React.useState([])

    const [mobileFiltersOpen, setMobileFiltersOpen] = React.useState(false)

    const today = new Date();
    const todayAnother = new Date();
    const sevenDaysAgo = new Date(todayAnother.setDate(todayAnother.getDate() - 7));
    const [fromValue, setFromValue] = React.useState(sevenDaysAgo);
    const [toValue, setToValue] = React.useState(today);


    const [chatId, setChatId] = React.useState("");
    const [messages, setMessages] = React.useState([]);
    const [lastMessage, setLastMessage] = React.useState(0);
    const [selectedSemanticGroup, setSelectedSemanticGroup] = React.useState("");
    const [intervalId, setIntervalId] = useState(null); // New state for managing interval ID
    const [semanticGroups, setSemanticGroups] = useState([]);

    const [userMessage, setUserMessage] = useState("");

    const [avoidFetch, setAvoidFetch] = useState(false);

    const [showProgress, setShowProgress] = React.useState(false);


    const [askRestartChat, setAskRestartChat] = React.useState(false);

    const [showDemoMessage, setShowDemoMessage] = React.useState(DEMO_MODE() === 'true')


    const chatContainerRef = useRef(null); // Ref for the chat container

    const chatIdRef = useRef(chatId); // Create a ref to hold the current value of chatId
    const lastMessageRef = useRef(lastMessage); // Create a ref to hold the current value of chatId
    const avoidFetchRef = useRef(avoidFetch);

    const [historyLoaded, setHistoryLoaded] = React.useState(false);
    const [loadedFromDate, setLoadedFromDate] = React.useState(null);
    const [loadedToDate, setLoadedToDate] = React.useState(null);
    const [loadedFilters, setLoadedFilters] = React.useState(null);

    useEffect(() => {
        chatIdRef.current = chatId; // Update the ref whenever chatId changes
    }, [chatId]);

    useEffect(() => {
        lastMessageRef.current = lastMessage;
    }, [lastMessage]);

    useEffect(() => {
        avoidFetchRef.current = avoidFetch;
    }, [avoidFetch]);

    useEffect(() => {
        // Scroll to the bottom whenever messages change
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        fetchMetadata(props.errorMessage, props.successMessage, props.showError)
        fetchSemanticGroups(props.errorMessage, props.successMessage, props.showError)
        // Function to simulate fetching messages from an API
        const fetchMessages = () => {
            console.log("want to fetch messages", chatIdRef.current); // For debugging, remove later
            if (chatIdRef.current === "" || avoidFetchRef.current) return;
            console.log("Fetching messages..."); // For debugging, remove later
            fetchChat(props.errorMessage, props.successMessage, props.showError);
        };

        // Function to start recurrent fetching
        const startMessageFetching = () => {
            // Set up an interval to fetch messages every 5 seconds
            const id = setInterval(fetchMessages, 2000);
            return id; // Return interval ID for cleanup
        };

        // Start fetching messages when the component mounts
        // const intervalId = startMessageFetching();

        // Cleanup interval when the component unmounts
        return () => {
            clearInterval(intervalId);
            endChat(props.errorMessage, props.successMessage, props.showError)
        };
    }, []); // Empty dependency array ensures this runs only on mount and cleanup on unmount


    useEffect(() => {
        // If we have data from HomeView, initialize the component with it
        if (location.state?.fromHome && chatData) {
            // Thu Dec 05 2024 00:00:00 GMT+0100 (Central European Standard Time)
            setLoadedFromDate(new Date(chatData.request.start_date).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }))
            setLoadedToDate(new Date(chatData.request.end_date).toLocaleDateString('en-US', {
                month: 'long', 
                day: 'numeric',
                year: 'numeric'
            }))
            setLoadedFilters(chatData.request.session_metadata_filters)
            setMessages(chatData.response.messages)
            setHistoryLoaded(true)
            // ... set other states as needed
        }
    }, [location]);

    const fetchMetadata = (show, hide, showError) => {
        show()
        requestSessionMetadata({})
            .then((data) => {
                let newFilters = []
                let chat = { id: 'chat-primary', name: 'Filters', options: [] }

                Object.entries(data).forEach(([key, value]) => {
                    let option = { id: key, name: key, selection: [] }
                    value.forEach(item => {
                        option.selection.push({ value: item, label: item, checked: false })
                    });
                    chat.options.push(option)
                });

                newFilters.push(chat)
                setFilters(DEMO_MODE() === 'true' ? mockFiltersChat : newFilters)
                setSessionsMetadata(data)
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }


    // Handle key press and send on Enter key
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent newline in the text field
            handleSendMessageClick();
        }
    };

    const startChat = (show, hide, showError) => {
        const request = {
            start_date: convertToDateTime(fromValue),
            end_date: convertToDateTime(toValue),
            semantic_group: selectedSemanticGroup
        }
        // show()
        setShowProgress(true)
        requestStartChat(request)
            .then((data) => {
                console.log(data)
                setChatId(data.chat_id)
                setShowProgress(false)
                // hide()
                // startMessageFetching(); // Start fetching messages when chat starts

            }
            )
            .catch((err) => {
                props.errorMessage(err);
                setShowProgress(false)
                // hide()
                showError(err)
            });
    }

    const endChat = (show, hide, showError) => {
        console.log("wanted to end chat: ", chatIdRef.current)
        const request = {
            chat_id: chatIdRef.current
        }
        if (chatIdRef.current === "") return;
        show()
        requestEndChat(request)
            .then((data) => {
                console.log(data)
                setChatId("")
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }

    const fetchChat = (show, hide, showError) => {
        const request = {
            chat_id: chatIdRef.current,
            last_seen_message: lastMessageRef.current
        }
        // show()
        requestChatMessages(request)
            .then((data) => {
                if (avoidFetchRef.current) return;
                console.log(data)
                setMessages(prevMessages => [...prevMessages, ...data.messages]);
                if (data.messages.length > 0) {
                    setLastMessage(data.messages[data.messages.length - 1].ordinal);
                }

            }
            )
            .catch((err) => {
                props.errorMessage(err);
                // hide()
                showError(err)
            });
    }


    const fetchSemanticGroups = (show, hide, showError) => {
        const request = {
            start_date: convertToDateTime(fromValue),
            end_date: convertToDateTime(toValue),
        }
        show()
        requestSemanticGroups(request)
            .then((data) => {
                setSemanticGroups(data)
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }

    const sendUserMessage = (show, hide, showError) => {
        setShowProgress(true)
        setAvoidFetch(true)
        const request = {
            chat_id: chatId,
            user_message: userMessage
        }
        setUserMessage("")
        // show()
        postUserMessage(request)
            .then((data) => {
                // hide()
                setAvoidFetch(false)
                fetchChat(props.errorMessage, props.successMessage, props.showError);
                setShowProgress(false)
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                // hide()
                showError(err)
                setAvoidFetch(false)
                setShowProgress(false)
            });
    }

    const handleRunClick = () => {
        if (chatId === "") {
            performStartChat()
        } else {
            setAskRestartChat(true)
        }
    }

    const performStartChat = () => {
        setMessages([])
        setLastMessage(0)
        startChat(props.errorMessage, props.successMessage, props.showError)
    }




    function convertToDateTime(dateValue) {
        if (!dateValue) return null;

        // Assuming the new dateValue is a Date object or has a `date` property
        const date = dateValue.date || dateValue;

        if (typeof date.format === 'function') {
            // If date is a Day.js or similar object
            return date.format('YYYY-MM-DD') + ' 00:00:00';
        } else if (date instanceof Date) {
            // If it's a JavaScript Date object
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} 00:00:00`;
        }

        // If date is a string, split and format
        const [day, month, year] = date.split('/');
        const parsedDate = new Date(year, month - 1, day);
        return `${parsedDate.getFullYear()}-${String(parsedDate.getMonth() + 1).padStart(2, '0')}-${String(parsedDate.getDate()).padStart(2, '0')} 00:00:00`;
    }

    const handleFetchClick = () => {
        fetchChat(props.errorMessage, props.successMessage, props.showError)
    }

    const handleSendMessageClick = () => {
        sendUserMessage(props.errorMessage, props.successMessage, props.showError)
    }

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
    };

    const handleSelectTailwindChange = (value) => {
        setSelectedSemanticGroup(value);
    };

    const handleUserMessageChange = (event) => {
        setUserMessage(event.target.value);
    };


    const buttonMap = new Map([
        ['chat-primary', { enableHandle: false, onClick: handleRunClick, name: `${chatId === "" ? "Start Chat" : "Restart Chat"}` }],
    ]);

    const calendarMap = new Map([
        ['chat-primary', { fromValue: fromValue, toValue: toValue, fromValueSetter: setFromValue, toValueSetter: setToValue }],
    ]);

    const semanticGroupMap = new Map([
        ['chat-primary', { list: semanticGroups, selectedItem: selectedSemanticGroup, onChange: handleSelectTailwindChange }],
    ]);


    return (
        <div className="bg-white">
            {showDemoMessage && (
                <div className="rounded-md bg-blue-50 p-4 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                                Demo mode is active. Demo data is collected mostly between October 10th, 2024 and November 1st, 2024.
                            </p>
                            <button
                                type="button"
                                className="ml-3 flex-shrink-0"
                                onClick={() => setShowDemoMessage(false)}
                            >
                                <XMarkIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div>
                {/* Mobile filter dialog */}
                <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-40 lg:hidden">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-black/25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 z-40 flex">
                        <DialogPanel
                            transition
                            className="relative ml-auto flex size-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
                        >
                            <div className="flex items-center justify-between px-4">
                                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                <button
                                    type="button"
                                    onClick={() => setMobileFiltersOpen(false)}
                                    className="-mr-2 flex size-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon aria-hidden="true" className="size-6" />
                                </button>
                            </div>

                            {/* Filters */}
                            {historyLoaded ? (
                                <div className="px-4 py-6">
                                    <button
                                        type="button"
                                        onClick={() => setHistoryLoaded(false)}
                                        className="mb-6 inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                        <ArrowLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                        Back to new chat
                                    </button>

                                    <div className="space-y-6">
                                        <div>
                                            <h4 className="font-medium text-gray-900">Date Range</h4>
                                            <div className="mt-2 space-y-2">
                                                <div className="flex justify-between text-sm text-gray-500">
                                                    <span>From:</span>
                                                    <span>{loadedFromDate}</span>
                                                </div>
                                                <div className="flex justify-between text-sm text-gray-500">
                                                    <span>To:</span>
                                                    <span>{loadedToDate}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h4 className="font-medium text-gray-900">Applied Filters</h4>
                                            <div className="mt-2 space-y-2">
                                                {Object.entries(loadedFilters).map(([key, values]) => (
                                                    <div key={key} className="rounded-md bg-gray-50 px-3 py-2">
                                                        <div className="text-sm font-medium text-gray-900">{key}</div>
                                                        <div className="text-sm text-gray-500">
                                                            {values.join(', ')}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <form className="mt-4 border-t border-gray-200">
                                {filters.map((section) => (
                                    <Disclosure key={section.id} as="div" className="border-t border-gray-200 px-4 py-6">
                                        <h3 className="-mx-2 -my-3 flow-root">
                                            <DisclosureButton className="group flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                                <span className="font-medium text-gray-900">{section.name}</span>
                                                <span className="ml-6 flex items-center">
                                                    <PlusIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                                    <MinusIcon aria-hidden="true" className="size-5 [.group:not([data-open])_&]:hidden" />
                                                </span>
                                            </DisclosureButton>
                                        </h3>
                                        <DisclosurePanel className="pt-6">
                                            <div className="space-y-6">
                                                <label htmlFor="from-date" className="block text-sm font-medium text-gray-700 text-right">
                                                    From Date
                                                </label>
                                                <Datepicker
                                                    id="from-date"
                                                    className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                    showClearButton={false}
                                                    value={calendarMap.get(section.id).fromValue}
                                                    onChange={(newValue) => calendarMap.get(section.id).fromValueSetter(newValue)}
                                                />
                                                <label htmlFor="to-date" className="block text-sm font-medium text-gray-700 text-right">
                                                    To Date
                                                </label>
                                                <Datepicker
                                                    id="to-date"
                                                    className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                    showClearButton={false}
                                                    value={calendarMap.get(section.id).toValue}
                                                    onChange={(newValue) => calendarMap.get(section.id).toValueSetter(newValue)}
                                                />

                                                <SelectComponentTailwind
                                                    list={semanticGroupMap.get(section.id).list}
                                                    selectedItem={semanticGroupMap.get(section.id).selectedItem}
                                                    hint='Select semantic group'
                                                    onChange={semanticGroupMap.get(section.id).onChange}
                                                />

                                                {section.options.map((option, optionIdx) => (

                                                    <div>

                                                        <div key={option.id} className="flex items-center text-gray-500">
                                                            {option.name}
                                                        </div>
                                                        {option.selection.map((selectionOption, soIdx) => (
                                                            <div key={selectionOption.value} className="flex items-center">
                                                                <input
                                                                    defaultValue={selectionOption.value}
                                                                    defaultChecked={selectionOption.checked}
                                                                    id={`filter-mobile-${option.id}-${soIdx}`}
                                                                    name={`${option.id}[]`}
                                                                    type="radio"
                                                                    className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                />
                                                                <label
                                                                    htmlFor={`filter-mobile-${option.id}-${soIdx}`}
                                                                    className="ml-3 min-w-0 flex-1 text-gray-500"
                                                                >
                                                                    {selectionOption.label}
                                                                </label>
                                                                <div class="border-t border-gray-300 my-4"></div>
                                                            </div>

                                                        ))
                                                        }

                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    className={`flex items-center justify-center px-5 py-2.5 text-sm font-medium rounded-lg ${buttonMap.get(section.id).enableHandle
                                                        ? 'text-gray-400 bg-gray-300 cursor-not-allowed'
                                                        : 'text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-700'
                                                        }`}
                                                    disabled={buttonMap.get(section.id).enableHandle}
                                                    onClick={buttonMap.get(section.id).enableHandle ? undefined : buttonMap.get(section.id).onClick}
                                                >
                                                    {buttonMap.get(section.id).name}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-5 h-5 ml-2"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </DisclosurePanel>
                                    </Disclosure>
                                ))}
                            </form>
                            )}
                        </DialogPanel>
                    </div>
                </Dialog>

                <main className="mx-auto w-full px-4 sm:px-6 lg:px-8">
                    <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-6">
                        <h3 className="text-xl font-bold tracking-tight text-gray-900">Chat</h3>

                        <div className="flex items-center">

                            <button
                                type="button"
                                onClick={() => setMobileFiltersOpen(true)}
                                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                            >
                                <span className="sr-only">Filters</span>
                                <FunnelIcon aria-hidden="true" className="size-5" />
                            </button>
                        </div>
                    </div>

                    <section aria-labelledby="products-heading" className="pb-24 pt-6">
                        <h2 id="products-heading" className="sr-only">
                            Products
                        </h2>

                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                            {/* Filters */}

                            {historyLoaded ? (
                                <div className="px-4 py-6">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setHistoryLoaded(false);
                                            setMessages([]);
                                            setLastMessage(0);
                                        }
                                        }
                                        className="mb-6 inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                        <ArrowLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                        Back to new chat
                                    </button>

                                    <div className="space-y-6">
                                        <div>
                                            <h4 className="font-medium text-gray-900">Date Range</h4>
                                            <div className="mt-2 space-y-2">
                                                <div className="flex justify-between text-sm text-gray-500">
                                                    <span>From:</span>
                                                    <span>{loadedFromDate}</span>
                                                </div>
                                                <div className="flex justify-between text-sm text-gray-500">
                                                    <span>To:</span>
                                                    <span>{loadedToDate}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h4 className="font-medium text-gray-900">Applied Filters</h4>
                                            <div className="mt-2 space-y-2">
                                                {Object.entries(loadedFilters).map(([key, values]) => (
                                                    <div key={key} className="rounded-md bg-gray-50 px-3 py-2">
                                                        <div className="text-sm font-medium text-gray-900">{key}</div>
                                                        <div className="text-sm text-gray-500">
                                                            {values.join(', ')}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <form className="hidden lg:block">
                                {filters.map((section, index) => (
                                    <Disclosure
                                        key={section.id}
                                        as="div"
                                        className="border-b border-gray-200 py-6"
                                        {...(index === 0 && { defaultOpen: true })}
                                    >
                                        <h3 className="-my-3 flow-root">
                                            <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                                <span className="font-medium text-gray-900">{section.name}</span>
                                                <span className="ml-6 flex items-center">
                                                    <PlusIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                                    <MinusIcon aria-hidden="true" className="size-5 [.group:not([data-open])_&]:hidden" />
                                                </span>
                                            </DisclosureButton>
                                        </h3>
                                        <DisclosurePanel className="pt-6 ml-6">
                                            <div className="space-y-4">
                                                <label htmlFor="from-date" className="block text-sm font-medium text-gray-700 text-right">
                                                    From Date
                                                </label>
                                                <Datepicker
                                                    id="from-date"
                                                    className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                    showClearButton={false}
                                                    value={calendarMap.get(section.id).fromValue}
                                                    onChange={(newValue) => calendarMap.get(section.id).fromValueSetter(newValue)}
                                                />
                                                <label htmlFor="to-date" className="block text-sm font-medium text-gray-700 text-right">
                                                    To Date
                                                </label>
                                                <Datepicker
                                                    id="to-date"
                                                    className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                    showClearButton={false}
                                                    value={calendarMap.get(section.id).toValue}
                                                    onChange={(newValue) => calendarMap.get(section.id).toValueSetter(newValue)}
                                                />

                                                <SelectComponentTailwind
                                                    list={semanticGroupMap.get(section.id).list}
                                                    selectedItem={semanticGroupMap.get(section.id).selectedItem}
                                                    hint='Select semantic group'
                                                    onChange={semanticGroupMap.get(section.id).onChange}
                                                />

                                                {section.options.map((option, optionIdx) => (
                                                    <div>

                                                        <div key={option.id} className="flex items-center text-gray-500">
                                                            {option.name}
                                                        </div>
                                                        {option.selection.map((selectionOption, soIdx) => (
                                                            <div key={selectionOption.value} className="flex items-center">
                                                                <input
                                                                    defaultValue={selectionOption.value}
                                                                    defaultChecked={selectionOption.checked}
                                                                    id={`filter-mobile-${option.id}-${soIdx}`}
                                                                    name={`${option.id}[]`}
                                                                    type="radio"
                                                                    className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                />
                                                                <label
                                                                    htmlFor={`filter-mobile-${option.id}-${soIdx}`}
                                                                    className="ml-3 min-w-0 flex-1 text-gray-500"
                                                                >
                                                                    {selectionOption.label}
                                                                </label>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    className={`flex items-center justify-center px-5 py-2.5 text-sm font-medium rounded-lg ${buttonMap.get(section.id).enableHandle
                                                        ? 'text-gray-400 bg-gray-300 cursor-not-allowed'
                                                        : 'text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-700'
                                                        }`}
                                                    disabled={buttonMap.get(section.id).enableHandle}
                                                    onClick={buttonMap.get(section.id).enableHandle ? undefined : buttonMap.get(section.id).onClick}
                                                >
                                                    {buttonMap.get(section.id).name}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-5 h-5 ml-2"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </DisclosurePanel>
                                    </Disclosure>
                                ))}
                            </form>
                            )}
                            
                            {/* Product grid */}
                            <div className="lg:col-span-3">
                                <ChatView
                                    title="Chat"
                                    chatContainerRef={chatContainerRef}
                                    messages={messages}
                                    showProgress={showProgress}
                                    userMessage={userMessage}
                                    handleUserMessageChange={handleUserMessageChange}
                                    handleKeyPress={handleKeyPress}
                                    historyLoaded={historyLoaded}
                                    errorMessage={props.errorMessage} successMessage={props.successMessage} showError={props.showError} />
                            </div>

                            <DialogComponent
                                title='Restart chat?'
                                subtitle='This will cause chat history to be overriden.'
                                buttonText='Restart chat'
                                cancelText='Cancel'
                                action={performStartChat}
                                open={askRestartChat}
                                setOpen={setAskRestartChat}
                            />
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

ChatWrapper.propTypes = {
    title: PropTypes.string.isRequired
}

export default ChatWrapper
