import * as React from 'react';
import { LoginRouterConfig } from 'navigation/LoginRouterConfig';
import moveo_one_logo from 'assets/moveo_one_logo.png';
import competition from 'assets/competition.png';

export function LoginPage() {
  return (
    <div className="min-h-screen bg-white relative overflow-hidden">
      {/* Background blurred shapes */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Yellow blur */}
        <div className="absolute -top-[40%] -left-[10%] w-[500px] h-[500px] bg-[#FFC100] rounded-full opacity-20 blur-[150px]" />
        
        {/* Light gray blur */}
        <div className="absolute top-[60%] right-[5%] w-[400px] h-[400px] bg-gray-200 rounded-full opacity-40 blur-[100px]" />
        
        {/* Darker yellow blur */}
        <div className="absolute top-[20%] right-[-20%] w-[600px] h-[600px] bg-[#FFC100] rounded-full opacity-10 blur-[120px]" />

        {/* Purple blur */}
        <div className="absolute top-[30%] left-[20%] w-[450px] h-[450px] bg-[#8B5CF6] rounded-full opacity-15 blur-[130px]" />
        
        {/* Light purple blur */}
        <div className="absolute -bottom-[20%] left-[40%] w-[550px] h-[550px] bg-[#A78BFA] rounded-full opacity-20 blur-[140px]" />
      </div>

      {/* Content */}
      <div className="relative min-h-screen">
        {/* Top branding - only shown on mobile */}
        <div className="lg:hidden w-full p-8 text-center">
          <img
            src={moveo_one_logo}
            alt="Moveo One"
            className="h-10 mx-auto mb-4"
          />
        </div>

        {/* Main content area */}
        <div className="flex flex-col lg:flex-row">
          {/* Left side - Branding (desktop only) */}
          <div className="hidden lg:flex lg:w-1/3 p-8 items-center">
            <div className="fixed w-1/3 max-w-md top-[var(--content-y,50%)] translate-y-1/2">
              <img
                src={moveo_one_logo}
                alt="Moveo One"
                className="h-12 mb-8"
              />
              
              {/* Background illustration */}
              <div className="absolute -left-8 -top-[43vh] right-0 overflow-visible">
                <img
                  src={competition}
                  alt=""
                  className="w-[1500px] opacity-90" 
                />
              </div>

              <div className="relative">
                <h1 className="text-4xl font-bold mb-4 text-[#1a1a1a]">
                  Welcome to moveo one
                </h1>

                {/* Dynamic text based on route */}
                <p className="text-lg text-gray-600">
                  {window.location.pathname.includes('signup') ? (
                    "Join our platform to unlock our powerful production analysis tools."
                  ) : window.location.pathname.includes('forgot-pass') ? (
                    "No worries - we'll help you get back using our platform."
                  ) : (
                    "Analyze, understand, and optimize your user interaction data with our powerful platform."
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* Right side - Auth forms */}
          <div className="flex-1 p-4 lg:p-8">
            <div className="w-full max-w-6xl mx-auto">
              <LoginRouterConfig />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}