import React from 'react';

export function OverlayList({ 
    title, 
    isOpen, 
    onClose, 
    items, 
    renderItem,
    emptyMessage = "No items to display" 
}) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            {/* Overlay background */}
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />

            {/* Content */}
            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative w-full max-w-4xl bg-white rounded-lg shadow-xl">
                    {/* Header */}
                    <div className="flex items-center justify-between p-6 border-b border-gray-200">
                        <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500 transition-colors"
                        >
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    {/* Content */}
                    <div className="p-6 max-h-[70vh] overflow-y-auto">
                        {items.length > 0 ? (
                            <div className="grid grid-cols-1 gap-4">
                                {items.map((item, index) => renderItem(item, index))}
                            </div>
                        ) : (
                            <div className="text-center text-gray-500 py-12">
                                {emptyMessage}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
} 