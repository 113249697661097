import React from 'react';
import SettingsView from './SettingsView';
import { useToast } from '../../../hooks/useToast'; // Assuming you have this hook

const SettingsContainer = () => {
    const { showSuccessToast, showErrorToast } = useToast();

    return (
        <SettingsView
            successMessage={showSuccessToast}
            errorMessage={showErrorToast}
        />
    );
};

export default SettingsContainer; 