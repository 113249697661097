import * as React from 'react';
import { BASE_URL, DEMO_MODE } from 'services/CONSTANTS';
import moveo_one_logo from 'assets/moveo_one_logo.png';
import { AuthBackground } from '../../../components/auth/AuthBackground';
import { presignIn } from 'services/userService';

export default function SignIn(props) {
  const [organizations, setOrganizations] = React.useState([]);
  const [showOrgModal, setShowOrgModal] = React.useState(false);
  const [selectedOrgId, setSelectedOrgId] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handlePreSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userEmail = data.get('email');
    const userPassword = data.get('password');
    
    setEmail(userEmail);
    setPassword(userPassword);

    try {
      const response = await presignIn(userEmail, userPassword);
      setOrganizations(response.orgs);
      
      // If only one organization, select it automatically
      if (response.orgs.length === 1) {
        handleOrgSelect(response.orgs[0].id, userEmail, userPassword);
      } else {
        setShowOrgModal(true);
      }
    } catch (error) {
      props.handleErrMessage(error || 'Failed to sign in');
    }
  };

  const handleOrgSelect = (orgId) => {
    setSelectedOrgId(orgId);
    setShowOrgModal(false);
    props.signin(email, password, orgId);
  };

  return (
    <>
      <div className="relative isolate px-6 pt-8 lg:px-8">
        <AuthBackground />

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Moveo One Logo"
              src={moveo_one_logo}
              className="mx-auto h-10 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
              {props.reSign
                ? "Session expired, please sign in"
                : `Sign in to Moveo One ${BASE_URL.includes("127.0.0.1") ? "(LOCALHOST)" : ""
                }`}
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handlePreSubmit} method="POST" className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    defaultValue={props.reSign ? sessionStorage.getItem('email') : ''}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm/6 font-medium text-gray-900"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    <a 
                      href={DEMO_MODE() === 'true' ? '#' : '/forgot-pass'} 
                      onClick={(e) => DEMO_MODE() === 'true' && e.preventDefault()}
                      tabIndex="-1" 
                      className={`font-semibold ${DEMO_MODE() === 'true' ? 'text-gray-400 cursor-not-allowed' : 'text-indigo-600 hover:text-indigo-500'}`}
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign In
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm/6 text-gray-500">
              Don't have an account?{' '}
              <a
                href={DEMO_MODE() === 'true' ? '#' : '/signup'}
                onClick={(e) => DEMO_MODE() === 'true' && e.preventDefault()}
                className={`font-semibold ${DEMO_MODE() === 'true' ? 'text-gray-400 cursor-not-allowed' : 'text-indigo-600 hover:text-indigo-500'}`}
              >
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Organization Selection Modal */}
      {showOrgModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Select Organization</h3>
            <div className="space-y-2">
              {organizations.map((org) => (
                <button
                  key={org.id}
                  onClick={() => handleOrgSelect(org.id)}
                  className="w-full text-left px-4 py-2 hover:bg-gray-100 rounded-md"
                >
                  {org.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}