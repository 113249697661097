export const mockFilters = [
    {
        id: 'first-graph',
        name: 'First',
        options: [
            {
                "id": "age-group",
                "name": "Age Group",
                "selection": [
                    {
                        "value": "18-24",
                        "label": "18-24",
                        "checked": false
                    },
                    {
                        "value": "25-34",
                        "label": "25-34",
                        "checked": false
                    },
                    {
                        "value": "35-44",
                        "label": "35-44",
                        "checked": false
                    },
                    {
                        "value": "45+",
                        "label": "45+",
                        "checked": false
                    }
                ]
            },
            {
                "id": "locale",
                "name": "Locale",
                "selection": [
                    {
                        "value": "en-US",
                        "label": "English (US)",
                        "checked": false
                    },
                    {
                        "value": "es-ES",
                        "label": "Spanish (Spain)",
                        "checked": false
                    },
                    {
                        "value": "fr-FR",
                        "label": "French (France)",
                        "checked": false
                    },
                    {
                        "value": "de-DE",
                        "label": "German (Germany)",
                        "checked": false
                    }
                ]
            },
            {
                "id": "activity-level",
                "name": "Activity Level",
                "selection": [
                    {
                        "value": "low",
                        "label": "Low Activity",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "Medium Activity",
                        "checked": false
                    },
                    {
                        "value": "high",
                        "label": "High Activity",
                        "checked": false
                    }
                ]
            },
            {
                "id": "session-duration",
                "name": "Session Duration",
                "selection": [
                    {
                        "value": "short",
                        "label": "0-5 Minutes",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "5-15 Minutes",
                        "checked": false
                    },
                    {
                        "value": "long",
                        "label": "15+ Minutes",
                        "checked": false
                    }
                ]
            },
            {
                "id": "engagement-score",
                "name": "Engagement Score",
                "selection": [
                    {
                        "value": "low",
                        "label": "Low Engagement",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "Medium Engagement",
                        "checked": false
                    },
                    {
                        "value": "high",
                        "label": "High Engagement",
                        "checked": false
                    }
                ]
            }
        ]
    },
    {
        id: 'second-graph',
        name: 'Second',
        options: [
            {
                "id": "age-group",
                "name": "Age Group",
                "selection": [
                    {
                        "value": "18-24",
                        "label": "18-24",
                        "checked": false
                    },
                    {
                        "value": "25-34",
                        "label": "25-34",
                        "checked": false
                    },
                    {
                        "value": "35-44",
                        "label": "35-44",
                        "checked": false
                    },
                    {
                        "value": "45+",
                        "label": "45+",
                        "checked": false
                    }
                ]
            },
            {
                "id": "locale",
                "name": "Locale",
                "selection": [
                    {
                        "value": "en-US",
                        "label": "English (US)",
                        "checked": false
                    },
                    {
                        "value": "es-ES",
                        "label": "Spanish (Spain)",
                        "checked": false
                    },
                    {
                        "value": "fr-FR",
                        "label": "French (France)",
                        "checked": false
                    },
                    {
                        "value": "de-DE",
                        "label": "German (Germany)",
                        "checked": false
                    }
                ]
            },
            {
                "id": "activity-level",
                "name": "Activity Level",
                "selection": [
                    {
                        "value": "low",
                        "label": "Low Activity",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "Medium Activity",
                        "checked": false
                    },
                    {
                        "value": "high",
                        "label": "High Activity",
                        "checked": false
                    }
                ]
            },
            {
                "id": "session-duration",
                "name": "Session Duration",
                "selection": [
                    {
                        "value": "short",
                        "label": "0-5 Minutes",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "5-15 Minutes",
                        "checked": false
                    },
                    {
                        "value": "long",
                        "label": "15+ Minutes",
                        "checked": false
                    }
                ]
            },
            {
                "id": "engagement-score",
                "name": "Engagement Score",
                "selection": [
                    {
                        "value": "low",
                        "label": "Low Engagement",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "Medium Engagement",
                        "checked": false
                    },
                    {
                        "value": "high",
                        "label": "High Engagement",
                        "checked": false
                    }
                ]
            }
        ]
    }
];


export const mockFiltersChat = [
    {
        id: 'chat-primary',
        name: 'Filters',
        options: [
            {
                "id": "age-group",
                "name": "Age Group",
                "selection": [
                    {
                        "value": "18-24",
                        "label": "18-24",
                        "checked": false
                    },
                    {
                        "value": "25-34",
                        "label": "25-34",
                        "checked": false
                    },
                    {
                        "value": "35-44",
                        "label": "35-44",
                        "checked": false
                    },
                    {
                        "value": "45+",
                        "label": "45+",
                        "checked": false
                    }
                ]
            },
            {
                "id": "locale",
                "name": "Locale",
                "selection": [
                    {
                        "value": "en-US",
                        "label": "English (US)",
                        "checked": false
                    },
                    {
                        "value": "es-ES",
                        "label": "Spanish (Spain)",
                        "checked": false
                    },
                    {
                        "value": "fr-FR",
                        "label": "French (France)",
                        "checked": false
                    },
                    {
                        "value": "de-DE",
                        "label": "German (Germany)",
                        "checked": false
                    }
                ]
            },
            {
                "id": "activity-level",
                "name": "Activity Level",
                "selection": [
                    {
                        "value": "low",
                        "label": "Low Activity",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "Medium Activity",
                        "checked": false
                    },
                    {
                        "value": "high",
                        "label": "High Activity",
                        "checked": false
                    }
                ]
            },
            {
                "id": "session-duration",
                "name": "Session Duration",
                "selection": [
                    {
                        "value": "short",
                        "label": "0-5 Minutes",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "5-15 Minutes",
                        "checked": false
                    },
                    {
                        "value": "long",
                        "label": "15+ Minutes",
                        "checked": false
                    }
                ]
            },
            {
                "id": "engagement-score",
                "name": "Engagement Score",
                "selection": [
                    {
                        "value": "low",
                        "label": "Low Engagement",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "Medium Engagement",
                        "checked": false
                    },
                    {
                        "value": "high",
                        "label": "High Engagement",
                        "checked": false
                    }
                ]
            }
        ]
    }
];


export const mockFiltersPlot = [
    {
        id: 'plot-primary',
        name: 'Filters',
        options: [
            {
                "id": "age-group",
                "name": "Age Group",
                "selection": [
                    {
                        "value": "18-24",
                        "label": "18-24",
                        "checked": false
                    },
                    {
                        "value": "25-34",
                        "label": "25-34",
                        "checked": false
                    },
                    {
                        "value": "35-44",
                        "label": "35-44",
                        "checked": false
                    },
                    {
                        "value": "45+",
                        "label": "45+",
                        "checked": false
                    }
                ]
            },
            {
                "id": "locale",
                "name": "Locale",
                "selection": [
                    {
                        "value": "en-US",
                        "label": "English (US)",
                        "checked": false
                    },
                    {
                        "value": "es-ES",
                        "label": "Spanish (Spain)",
                        "checked": false
                    },
                    {
                        "value": "fr-FR",
                        "label": "French (France)",
                        "checked": false
                    },
                    {
                        "value": "de-DE",
                        "label": "German (Germany)",
                        "checked": false
                    }
                ]
            },
            {
                "id": "activity-level",
                "name": "Activity Level",
                "selection": [
                    {
                        "value": "low",
                        "label": "Low Activity",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "Medium Activity",
                        "checked": false
                    },
                    {
                        "value": "high",
                        "label": "High Activity",
                        "checked": false
                    }
                ]
            },
            {
                "id": "session-duration",
                "name": "Session Duration",
                "selection": [
                    {
                        "value": "short",
                        "label": "0-5 Minutes",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "5-15 Minutes",
                        "checked": false
                    },
                    {
                        "value": "long",
                        "label": "15+ Minutes",
                        "checked": false
                    }
                ]
            },
            {
                "id": "engagement-score",
                "name": "Engagement Score",
                "selection": [
                    {
                        "value": "low",
                        "label": "Low Engagement",
                        "checked": false
                    },
                    {
                        "value": "medium",
                        "label": "Medium Engagement",
                        "checked": false
                    },
                    {
                        "value": "high",
                        "label": "High Engagement",
                        "checked": false
                    }
                ]
            }
        ]
    }
];
